import styled from '@emotion/styled'

export const Container = styled.div` 
    display: flex;
    align-items: center;
    input{
        display: none;
    }
    label {
        color: #494F4D;
        font-size: 14px;
        border: 1px solid #494F4D;
        border-radius: 4px;
        padding: 6px 14px;
        cursor: pointer;
        display: inline-block;
    }
    input:checked + label{
        background-color: #139074;
        color: white;
    }
`