import { useQuery } from 'react-query';
import { API } from '../../api';

export enum MainQueryKey {
	uploadQuery = 'upload-query'
}

export const useGetUpload = (id?: string | number) => {
	const { data, isError } = useQuery([MainQueryKey.uploadQuery, id], () => API.getUpload(id), {
		enabled: !!id,
		staleTime: 600000,
		select: data => {
			return data?.data?.upload;
		}
	});

	return { upload: data, isError };
};
