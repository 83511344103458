import styled from '@emotion/styled';

export const ModalWrapper = styled.div<{ isActive: boolean }>`
	display: ${props => (props.isActive ? 'block' : 'none')};
	z-index: 99;
	position: absolute;
	width: 100%;
	height: 100%;
	position: fixed;
`;

export const ModalContainer = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 4px;
`;
