import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import authStore from '../../../stores/authStore';

interface SuperAdminRouteProps {
	path: any;
	exact?: boolean;
	component: any;
}

const SuperAdminRoute = (props: SuperAdminRouteProps) => {
	const path = props.path;
	const component = props.component;

	return authStore.adminRole === 'superadmin' ? (
		<Route path={path} exact={props.exact ? props.exact : false} component={component} />
	) : (
		<Redirect to={{ pathname: '/uploads' }} />
	);
};

export default SuperAdminRoute;
