import React, { useEffect, useState } from 'react';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import * as Yup from 'yup';
import { API } from '../../../api';
import { Countries } from '../../../models/general';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Button, InputLabel, Select, TextField, Typography, MenuItem, Autocomplete } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import ErrorMessage from '../../../components/UI/error-message/ErrorMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './Notifications.module.scss';
import { ProjectsData } from '../../../models/projects';
import { DevTool } from '@hookform/devtools';
import messageStore from '../../../stores/messageStore';

interface Props {}
interface FormValues {
	Country: number;
	Mission: number;
	SelectedUsers: string[];
	Title: string;
	Message: string;
}

const schemaNotification = Yup.object().shape({
	Country: Yup.number(),
	Mission: Yup.number(),
	SelectedUsers: Yup.array().nullable(),
	Title: Yup.string().required('This field is required'),
	Message: Yup.string().required('This field is required')
});

const Notifications = (props: Props) => {
	const { t } = useTranslation();
	const [countries, setCountries] = useState<Countries[]>([]);
	const [users, setUsers] = useState<any>([]);
	const [projects, setProjects] = useState<ProjectsData[]>([]);
	const [filterFlag, setFilterFlag] = useState<boolean>(false);
	const [selectedUsers, setSelectedUsers] = useState<any[]>([]);

	useEffect(() => {
		fetchCountries();
	}, []);

	const fetchProjects = async (countryId: number) => {
		const res = await API.getProjectList({ fields: 'id,name', countryId: countryId });
		setProjects(res.data.projects);
	};

	const fetchCountries = async () => {
		const res = await API.getCountries();
		setCountries(res.data.countries);
	};

	const {
		control,
		handleSubmit,
		register,
		formState: { errors },
		getValues,
		setValue
	} = useForm<any>({
		resolver: yupResolver(schemaNotification),
		mode: 'all'
	});

	const formValues = getValues();

	useEffect(() => {
		fetchProjects(formValues.Country);
		fetchUsers(formValues.Country, formValues.Mission);
	}, [filterFlag, formValues.Country, formValues.Mission]);

	const fetchUsers = async (countryId: number, projectId: number) => {
		const res = await API.getAllUsers({ countryId, projectId });

		if (projectId) {
			setSelectedUsers(res.data.users.map((user: any) => user.id + '- ' + user.username));
		}

		setUsers(res.data.users);
	};

	const resetForm = () => {
		setSelectedUsers([]);
		setValue('Country', undefined);
		setValue('Mission', undefined);
		setValue('Title', '');
		setValue('Message', '');
	};

	const _onSubmit: SubmitHandler<FormValues> = async data => {
		const { Country, Mission, SelectedUsers, Title, Message } = data;
		const formatUserIds = selectedUsers?.map((user: any) => {
			if (typeof user === 'string') {
				const id = user.split('-')[0];
				return +id;
			}
			return user;
		});

		await API.sendNotifications({
			countryID: Country,
			projectID: Mission,
			userIDs: formatUserIds,
			title: Title,
			message: Message
		})
			.then(() => {
				messageStore.snackbar({
					message: 'Notification sent',
					type: 'success'
				});

				console.info('Notification sent', {
					countryID: Country,
					projectID: Mission,
					userIDs: formatUserIds,
					title: Title,
					message: Message
				});

				resetForm();
			})
			.catch(err =>
				messageStore.snackbar({
					message: err.data.errors[0].message,
					type: 'error'
				})
			);
	};

	return (
		<SimpleLayout passedStyles={styles.root}>
			<CarchupaPaper type="column" parentStyle={`${styles.paper} ${styles.messagebox}`} childStyle={styles.generalInfo} removeBoxShadow>
				<div className={styles.createMissionHeader}>
					<Typography variant="h1">{t('Notifications')}</Typography>
				</div>
				<form onSubmit={handleSubmit(_onSubmit)}>
					<div className={styles.twoInRow}>
						<Controller
							name="Country"
							control={control}
							render={({ field }) => {
								return (
									<div>
										<InputLabel sx={{ marginTop: '20px', color: '#494F4D' }}>{t('Countries')}</InputLabel>
										<Select
											{...field}
											value={field.value ?? ' '}
											onChange={e => {
												// Reset the mission selected when the country changes.
												setValue('Mission', undefined);
												setSelectedUsers([]);
												return field.onChange(e.target.value), setFilterFlag(!filterFlag);
											}}
											sx={{ width: '312px', border: 'none' }}
											id="Countries"
											error={errors.Country?.message}>
											{countries.map(country => (
												<MenuItem key={country.id} value={country.id}>
													{country.name}
												</MenuItem>
											))}
										</Select>
									</div>
								);
							}}
						/>
						<Controller
							name="Mission"
							control={control}
							render={({ field }) => {
								return (
									<div>
										<InputLabel sx={{ marginTop: '20px', color: '#494F4D' }}>{t('mission')}</InputLabel>
										<Select
											{...field}
											value={field.value ?? ' '}
											sx={{ width: '312px', border: 'none' }}
											id="Countries"
											onChange={e => {
												setSelectedUsers([]);
												return field.onChange(e.target.value), setFilterFlag(!filterFlag);
											}}>
											{projects.map(project => (
												<MenuItem key={project.id} value={project.id}>
													{project.name}
												</MenuItem>
											))}
										</Select>
									</div>
								);
							}}
						/>
					</div>
					<Controller
						name="SelectedUsers"
						control={control}
						render={({ field }) => {
							return (
								<div>
									<InputLabel sx={{ marginTop: '20px', color: '#494F4D' }}>{t('Users')}</InputLabel>
									<Autocomplete
										{...field}
										value={selectedUsers}
										multiple
										onChange={(e: any, value) => {
											setSelectedUsers(value);
										}}
										autoComplete
										options={users.map((user: any) => user.id + '- ' + user.username)}
										sx={{ width: '648px' }}
										renderInput={params => (
											<TextField
												value={field.value}
												sx={{
													'.MuiOutlinedInput-root': {
														paddingTop: '0px',
														paddingBottom: '0px',
														minHeight: '46px',
														height: '100%',
														maxHeight: 'unset'
													}
												}}
												{...params}
											/>
										)}
									/>
								</div>
							);
						}}
					/>
					<Controller
						name="Title"
						control={control}
						render={({ field }) => {
							return (
								<>
									<InputLabel sx={{ marginTop: '20px', color: '#494F4D' }}>{t('TitleLabel')}</InputLabel>
									<TextField
										placeholder={t('titleTextField')}
										value={field.value}
										onChange={e => field.onChange(e.target.value)}
										onBlur={field.onBlur}
										sx={{
											'.MuiOutlinedInput-root': {
												width: '648px',
												border: 'none'
											}
										}}
										id="title"
										rows={1}
										error={false}
									/>
									<ErrorMessage>{''}</ErrorMessage>
								</>
							);
						}}
					/>

					<Controller
						name="Message"
						control={control}
						render={({ field }) => {
							return (
								<>
									<InputLabel sx={{ marginTop: '20px', color: '#494F4D' }}>{t('message')}</InputLabel>
									<TextField
										{...field}
										placeholder={t('messageTextField')}
										value={field.value}
										sx={{
											'.MuiOutlinedInput-root': {
												width: '648px',
												border: 'none',
												maxHeight: 'unset'
											}
										}}
										id="message"
										multiline
										rows={5}
										error={false}
									/>
									<ErrorMessage>{''}</ErrorMessage>
								</>
							);
						}}
					/>

					<Button sx={{ marginTop: '20px', height: '55px', width: '180px' }} color="primary" variant="contained" type="submit">
						<h3>{t('sendNotification')}</h3>
					</Button>
				</form>
			</CarchupaPaper>
			<DevTool control={control} placement="top-right" />
		</SimpleLayout>
	);
};

export default inject('settingsStore', 'userStore', 'messageStore')(observer(Notifications));
