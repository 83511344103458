import { useQuery } from 'react-query';
import { API } from '../../api';
import { useTranslation } from 'react-i18next';
import messageStore from '../../stores/messageStore';

enum MainQueryKey {
	countriesListQuery = 'countries-list-query'
}

export const useGetCountriesQuery = () => {
	const { data, isError } = useQuery(MainQueryKey.countriesListQuery, () => API.getCountries(), {
		staleTime: 600000,
		select: data => {
			return data?.data;
		}
	});
	const { t } = useTranslation();

	if (isError) {
		messageStore.snackbar({
			message: t('Failed fetching countries'),
			type: 'error'
		});
	}

	return { data };
};
