import { Box, FormControlLabel, Checkbox, TextField } from "@mui/material";
import { t } from "i18next";
import { ColorPicker } from "material-ui-color";
import { observer } from "mobx-react";
import missionEditorStore, { Step } from "../../../stores/missionEditorStore";

export const SummaryStep = observer((props: { step: Step }) => {
    const { step } = props;
    const { setStepProperty } = missionEditorStore;

    return (
        <Box sx={{ paddingTop: '20px', display: 'flex', gap: "20px", flexWrap: "wrap", flexDirection: "column", justifyContent: "flex-start"  }}>
           <div>
                <FormControlLabel
                    label={`${t("missionEditor.summaryStep.mapEnabled")}`}
                    value={step.summaryParams?.mapEnabled}
                    control={
                        <Checkbox 
                            onChange={(e) => setStepProperty(step, "mapEnabled", e.target.checked)}
                            checked={step.summaryParams?.mapEnabled}
                        />
                    }
                 />
            </div>
        </Box> 
    )
})