import React from 'react'
import TickerCell from '../ticker-cell/TickerCell';
import {useTicker} from '../../../hooks/use-ticker';

import styles from './Ticker.module.scss';

const Ticker = ({ futureDate }: any) => {
    const [ days, hours, minutes, seconds ] = useTicker(futureDate);
   

    return (
        <div className={ styles.tickerShell }>
            <TickerCell value={days} label="Days" />
            <div className={ styles.separator }>:</div>
            <TickerCell value={hours} label="Hours" />
            <div className={ styles.separator }>:</div>
            <TickerCell value={minutes} label="Minutes" />
            <div className={ styles.separator }>:</div>
            <TickerCell value={seconds} label="Seconds" />
        </div>                      
    );
}

export default Ticker