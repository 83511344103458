import { Paper, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import styles from './CarchupaPaper.module.scss';
import { Grid } from '@mui/material';

interface CarchupaPaperProps {
	title?: string;
	parentStyle?: string;
	childStyle?: string;
	type?: 'row' | 'row-reverse' | 'column' | 'column-reverse' | undefined;
	justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly' | undefined;
	alignItems?: 'center' | 'flex-start' | 'flex-end' | 'stretch' | 'baseline' | undefined;
	removeBoxShadow?: boolean;
}

const CarchupaPaper = (props: PropsWithChildren<CarchupaPaperProps>) => {
	const { children, title, childStyle, parentStyle, type, justify, alignItems, removeBoxShadow } = props;
	return (
		<Paper
			sx={!removeBoxShadow ? { boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.07)' } : {}}
			className={`${styles.paper} ${parentStyle}`}
			elevation={removeBoxShadow ? 0 : 1}>
			{!!title ? <Typography variant="h6">{title}</Typography> : null}

			{!!children ? (
				<Grid
					container
					direction={!!type ? type : 'row'}
					justifyContent={!!justify ? justify : undefined}
					alignItems={!!alignItems ? alignItems : undefined}
					className={`${styles.content} ${childStyle}`}
					spacing={2}>
					{children}
				</Grid>
			) : null}
		</Paper>
	);
};

export default CarchupaPaper;
