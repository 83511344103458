import { DatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TextField } from '@mui/material';
import moment from 'moment';
import React, { Fragment, useState } from 'react';

interface YearMonthPickerProps {
	parentCallback: any;
	limit: Date;
}

function YearMonthPicker(props: YearMonthPickerProps) {
	const { parentCallback, limit } = props;
	const [selectedDate, handleDateChange] = useState(moment(limit));

	const handleChange = (date: any) => {
		handleDateChange(date);
		parentCallback(date);
	};

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<Fragment>
				<DatePicker
					openTo="year"
					views={['year', 'month']}
					label="Year and Month"
					value={selectedDate.toDate()}
					maxDate={limit}
					onChange={handleChange}
					renderInput={props => <TextField {...props} helperText="invalid mask" />}
				/>
			</Fragment>
		</LocalizationProvider>
	);
}

export default YearMonthPicker;
