import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './services/locales/en/translation.json';
import translationFI from './services/locales/fi/translation.json';
import translationES from './services/locales/es/translation.json';

i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: translationEN
		},
		fi: {
			translation: translationFI
		},
		es: {
			translation: translationES
		}
	},
	lng: localStorage.getItem('lng') || 'en',
	fallbackLng: 'en',

	interpolation: {
		escapeValue: false
	}
});

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
