import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from './SelectRejectOption.module.scss';
import ThumbsDown from '../../../assets/gray-thumbsdown-icon.svg';
import CorruptFile from '../../../assets/corrupt-file-icon.svg';

interface Props {
	handleClick: (rejectWithPayment: boolean, changedPaymentAmount?: number) => void;
	setOpenRejectModal: (bool: boolean) => void;
	setRejectionWithPayment: (bool: boolean) => void;
	openRejectModal: boolean;
}

const SelectRejectOption = ({ handleClick, setOpenRejectModal, setRejectionWithPayment, openRejectModal }: Props) => {
	const handleRejectWithPayment2 = (
		event: any,
		prompt: string = 'Are you sure you want to reject with payment? If so, you may add a new value for the upload.'
	) => {
		const newValue = window.prompt(prompt);

		// Check if the user cancelled the prompt
		if (newValue === null) {
			return;
		}

		// If the user pressed OK without entering a value, treat it as an optional input
		if (newValue.trim() === '') {
			handleClick(true);
			return;
		}

		const numericValue = parseFloat(newValue);

		// If the user entered a value, check if it's numeric
		if (!isNaN(numericValue)) {
			handleClick(true, numericValue);
		} else {
			handleRejectWithPayment2(undefined, 'Please enter a valid number.');
		}
	};

	return (
		<Box component="div" className={styles.container}>
			<Box
				onClick={() => {
					setOpenRejectModal(true);
				}}
				component="div"
				className={styles.item}>
				<Box component="img" src={ThumbsDown} alt="Download Icon" />
				<Box component="span">Reject</Box>
			</Box>
			<Box onClick={handleRejectWithPayment2} component="div" className={styles.item}>
				<Box component="img" src={CorruptFile} alt="Download Icon" />
				<Box component="span">Reject with payment</Box>
			</Box>
		</Box>
	);
};

export default SelectRejectOption;
