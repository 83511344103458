import { Box, InputLabel, Select, MenuItem, Button, FormHelperText } from "@mui/material";
import { t } from "i18next";
import { observer } from "mobx-react";
import missionEditorStore, { StepType, Step } from "../../../stores/missionEditorStore";

import { ChoiceStep } from "./ChoiceStep";
import { LobbyStep } from "./LobbyStep";
import { PreviewStep } from "./PreviewStep";
import { RecordingStep } from "./RecordingStep";
import { SummaryStep } from "./SummaryStep";
import { EmptyStep } from './EmptyStep';

export const MissionStep = observer((props: { step: Step }) => {
    const { setStepType, deleteStep, errors, mission } = missionEditorStore;
    const { step } = props;
    const stepIndex = step.order - 1;

    const onChange = (e: any) =>  {
        setStepType(e.target.value, step);
    }

    return (
        <Box sx={{ paddingTop: '20px' }}>
            <InputLabel id="step-type-label">Step Type</InputLabel>
            <Select 
                error={errors[`[${stepIndex}].type`]}
                disabled={step.type === StepType.LOBBY_STEP} 
                fullWidth 
                onChange={onChange} 
                value={step.type} 
                labelId="step-type-label" 
                label="Step Type" 
                id="step-type-select"
            >
                {/* Only show lobby option in step 1 */}
                {step.type === StepType.LOBBY_STEP && (
                    <MenuItem disabled value={StepType.LOBBY_STEP}>{t("missionEditor.lobbyStep.name")}</MenuItem>
                )}
                <MenuItem value={StepType.PREVIEW_STEP}>{t("missionEditor.previewStep.name")}</MenuItem>
                <MenuItem value={StepType.RECORDING_STEP}>{t("missionEditor.recordingStep.name")}</MenuItem>
                <MenuItem value={StepType.CHOICE_STEP}>{t("missionEditor.choiceStep.name")}</MenuItem>
                <MenuItem value={StepType.SUMMARY_STEP}>{t("missionEditor.summaryStep.name")}</MenuItem>
            </Select>
            <FormHelperText sx={{ color: 'red' }}>
                {errors[`[${stepIndex}].type`]}
            </FormHelperText>


        <div style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', paddingTop: '12px' }} />
            {step.type == StepType.EMPTY_STEP && (<EmptyStep />)}
            {step.type == StepType.LOBBY_STEP && (<LobbyStep step={step} />)}
            {step.type == StepType.PREVIEW_STEP && (<PreviewStep step={step} />)}
            {step.type == StepType.RECORDING_STEP && (<RecordingStep step={step} />)}
            {step.type == StepType.CHOICE_STEP && (<ChoiceStep step={step} />)}
            {step.type == StepType.SUMMARY_STEP && (<SummaryStep step={step} />)}

            {step.order !== 1 && (
                <Box sx={{ paddingTop: "20px", paddingBottom: '20px' }}>
                    <Button disabled={step.type == StepType.CHOICE_STEP && mission.id > 0} onClick={() => deleteStep(step)} color="error" variant="outlined">{t("missionEditor.deleteStep")}</Button>
                </Box>
            )}
        </Box>
    )
})