import { Box, FormControl, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRangePickerInput } from '../../../components/UX/date-range-picker/DateRangePickerInput';
import CarchupaDialog from '../../../components/UX/dialog/CarchupaDialog';
import SelectList from '../../../components/UX/select-list/SelectList';
import { ModalContext } from '../../../context/modalContext';
import { useGetPaymentsByUserId } from '../../../queries/data-queries/useGetPaymentsByUserId';
import { useGetProjectListQuery } from '../../../queries/data-queries/useGetProjectListQuery';
import { DatePickerMainQueryKey, DatePickerQueryKey, useDatePickerQuery } from '../../../queries/view-queries/useDatePickerQuery';
import { MainQueryKey, QueryKey, useSelectListQuery } from '../../../queries/view-queries/useSelectListQuery';
import { formatDateToUnixDate } from '../user/helpers';
import { UserPaymentsTable } from '../user/UserPaymentsTable';

interface Props {
	handleSubmit: () => void;
	setOpen: (open: boolean) => void;
	open: boolean;
	userId: number;
	userName: string;
	dialogStylesFromProp?: Record<string, string>;
}

export const UserPaymentsModal = ({ handleSubmit, setOpen, open, userId, userName, dialogStylesFromProp }: Props) => {
	const { handleModal } = useContext(ModalContext);
	const { t } = useTranslation();
	const [startDate, setStartDate] = useState<number>();
	const [endDate, setEndDate] = useState<number>();

	/////////////////////////////////////////////// REACT-QUERY ////////////////////////////////////////////////////////
	const { dates } = useDatePickerQuery(DatePickerMainQueryKey.dateRangePickerInput, DatePickerQueryKey.paymentsModal);

	const { data: projectListData } = useGetProjectListQuery({ fields: 'id,name' });
	const { getSelectedOption: getSelectedOptionForMission } = useSelectListQuery(MainQueryKey.mission, QueryKey.userPaymentModal);
	const mission = getSelectedOptionForMission(MainQueryKey.mission, QueryKey.userPaymentModal);

	const { data: paymentsTableData } = useGetPaymentsByUserId({
		userId: `${userId}`,
		projectId: mission,
		startDate,
		endDate
	});
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (dates[0]) {
			const unixDateFrom = formatDateToUnixDate(dates[0]);
			setStartDate(unixDateFrom);
		}

		if (dates[1]) {
			const unixDateTo = formatDateToUnixDate(dates[1]);
			setEndDate(unixDateTo);
		}
	}, [dates]);

	return (
		<CarchupaDialog setOpen={handleModal} open={open} dialogStylesFromProp={dialogStylesFromProp}>
			<Box sx={{ padding: '24px' }}>
				<Typography variant="h2">
					{userName} {t('Payments')}
				</Typography>
				<Box sx={{ display: 'flex', gap: '40px', padding: '32px 0' }}>
					<FormControl sx={{ width: '50%' }}>
						<SelectList
							queryKey={QueryKey.userPaymentModal}
							mainQueryKey={MainQueryKey.mission}
							labelId="mission"
							id="mission-select"
							htmlFor="mission"
							labelText="Mission"
							data={projectListData?.projects}
						/>
					</FormControl>
					<FormControl sx={{ width: '50%' }}>
						<label htmlFor="mission">{t('dateRange')}</label>
						<DateRangePickerInput dates={dates} queryKey={DatePickerQueryKey.paymentsModal} />
					</FormControl>
				</Box>
				<Box style={{ overflowY: 'scroll', maxHeight: '500px' }}>
					<UserPaymentsTable paymentsTableData={paymentsTableData?.payments || []} />
				</Box>
			</Box>
		</CarchupaDialog>
	);
};
