import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import styles from './Video.module.scss';
import DownloadIcon from '@mui/icons-material/Download';
import ExploreIcon from '@mui/icons-material/Explore';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { API } from '../../../api';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Chip, IconButton, DialogContent, Dialog, DialogActions } from '@mui/material';
import VideoPlayer from '../../../components/UX/player/Player';
import RouteIcon from '@mui/icons-material/Route';
import { CollectablesUploadData, UploadData } from '../../../models/video';
import { saveAs } from 'file-saver';
import moment from 'moment';
import settingsStore from '../../../stores/settingsStore';
import { inject, observer } from 'mobx-react';
import VideoMap from '../../../components/UX/video-map/VideoMap';
import { getVideoGeojson } from '../../../logic/get-video-geojson';
import WrongLocationIcon from '@mui/icons-material/WrongLocation';
import authStore from '../../../stores/authStore';
import blueberry from '../../../assets/blueberry.svg';
import grape from '../../../assets/grape.svg';
import strawberry from '../../../assets/strawberry.svg';
import peach from '../../../assets/peach.svg';
import banana from '../../../assets/banana.svg';
import watermelon from '../../../assets/watermelon.svg';
import arrowLeft from '../../../assets/arrow-left.svg';
import CarchupaDialog from '../../../components/UX/dialog/CarchupaDialog';
import VideoModalReject from './VideoModalReject';
import VideoModalApprove from './VideoModalApprove';
import { ApproveRejectBtn } from '../../../components/UI/approve-reject-btn';
import { CacheUploadsParamsMainQuery, useCacheUploadsParamsQuery } from '../../../queries/view-queries/useCacheUploadsParamsQuery';
import { usePatchUploadsQuery } from '../../../queries/data-queries/usePatchUploadsQuery';
import { UploadsDataMainQueryKey } from '../../../queries/data-queries/useGetUploadsData';
import { Box } from '@mui/system';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import checkedTagIcon from '../../../assets/checked-tag-icon.svg';
import xRedIcon from '../../../assets/x-red-icon.svg';
import SelectRejectOption from '../../../components/UX/select-reject-option/SelectRejectOption';
interface Props {}

const Video = (props: Props) => {
	const { t } = useTranslation();
	const match = useRouteMatch<{ videoId: string }>();
	const [video, setVideo] = useState<UploadData>();
	const [collectables, setCollectables] = useState<CollectablesUploadData[]>();
	const [open, setOpen] = useState(false);
	const [openApproveModal, setOpenApproveModal] = useState(false);
	const [data, setData] = useState({ message: '' });
	const { adminRole } = authStore;
	const [rejectionReason, setRejectionReason] = useState<number>(0);
	const [rejectionWithPayment, setRejectionWithPayment] = useState(false);
	const [isReturnObjectToMapDialogOpen, setIsReturnObjectToMapDialogOpen] = useState<boolean>(false);
	const [openSelectPaymentStatus, setOpenSelectPaymentStatus] = useState(false);

	/////////////////////////////////////////////// REACT-QUERY ////////////////////////////////////////////////////////
	const { data: uploadsParams } = useCacheUploadsParamsQuery(CacheUploadsParamsMainQuery.uploadPageParamsQueryKey);
	const { mutate } = usePatchUploadsQuery(UploadsDataMainQueryKey.uploadsDataQuery, uploadsParams);
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const loadVideo = async () => {
		const response = await API.getUpload(match.params.videoId);
		try {
			if (response) {
				setVideo(response.data.upload);
			} else {
				return toast.error(t('failedFetchingVideos'));
			}
		} catch {
			console.log('server error');
		}
	};
	const loadCollectables = async () => {
		const response = await API.getUploadCollectables(match.params.videoId);
		try {
			if (response.data.collectedSummary.length > 0) {
				setCollectables(response.data.collectedSummary);
			} else {
				return toast.info(t('noCollectablesOnThisVideo'));
			}
		} catch {
			console.log('server error');
		}
	};

	useEffect(() => {
		loadVideo();
		loadCollectables();
	}, []);

	const showGPX = useCallback(async () => {
		if (!!video) {
			const response = await API.getGPX(video.id);
			if (response) {
				let blob = new Blob([response], { type: 'text/xml' });
				saveAs(blob, `GPX-${video.id}.gpx`);
				/*  let url = URL.createObjectURL(blob);
      window.open(url);
      URL.revokeObjectURL(url); */

				/* var win = window.open();
      if (win) {
        win.document.write('<iframe src="data:text/xml,'+encodeURIComponent(response) + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
      } */
			}
		}
	}, [video]);

	const showGEOJSON = useCallback(() => {
		if (!!video) {
			getVideoGeojson([Number(video.id)]);
		}
	}, [video]);
	const downloadVideo = useCallback(async () => {
		settingsStore.updateGlobalLoading(true);
		if (video) {
			let blob = new Blob([video.url], { type: 'video/mp4' });
			saveAs(blob, 'Crowdsorsa ' + video.fileName);
		}
		settingsStore.updateGlobalLoading(false);
	}, [video]);

	const handleDataChange = useCallback(({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
		setData((d: any) => ({ ...d, [currentTarget.id]: currentTarget.value }));
	}, []);

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const customRejectionReason = data.message;
		const rejectionReasonId = rejectionReason;

		if (video) {
			if (data.message !== t('bad_response') && data.message) {
				mutate({
					id: video.id,
					status: rejectionWithPayment ? 'rejectWithPayment' : 'reject',
					bodyParams: { customRejectionReason: customRejectionReason }
				});
			} else {
				mutate({ id: video.id, status: 'reject', bodyParams: { rejectionReasonId: rejectionReasonId } });
			}
		}
		setOpenSelectPaymentStatus(false);
	};

	const handleReturnObjectsToMap = async () => {
		if (video) {
			try {
				await API.returnObjectsToMap(video.id);
				toast.success(t('Objects returned'));
			} catch (error) {
				toast.error(t('Error returning objects'));
			}
		}
	};

	const handleApproveSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		video?.id && mutate({ id: video.id, status: 'approve' });
		setOpenApproveModal(false);
	};

	const handleRejectOptionClick = async (rejectWithPayment: boolean, changedPaymentAmount?: number) => {
		if (video) {
			if (changedPaymentAmount) {
				mutate({
					id: video.id,
					status: rejectWithPayment ? 'rejectWithPayment' : 'reject',
					bodyParams: { customRejectionReason: 'rejected with payment', changedPaymentAmount: changedPaymentAmount }
				});
			} else {
				mutate({
					id: video.id,
					status: rejectWithPayment ? 'rejectWithPayment' : 'reject',
					bodyParams: { customRejectionReason: 'rejected with payment' }
				});
			}
		}
		setOpenSelectPaymentStatus(false);
	};

	const search = useLocation().search;
	const urlPar = new URLSearchParams(search).get('prev');
	const history = useHistory();
	const [copied, setCopied] = useState(false);

	const handleCopyClick = () => {
		// Create a temporary input element
		const input = document.createElement('input');
		input.setAttribute('value', video.id);
		document.body.appendChild(input);

		// Select the text in the input element
		input.select();
		document.execCommand('copy');
		document.body.removeChild(input);

		// Set the copied state to true
		setCopied(true);

		// Reset the copied state after a short delay
		setTimeout(() => {
			setCopied(false);
		}, 2000);
	};

	const getPreposition = () => {
		switch (video?.status) {
			case 'approved':
				return (
					<Typography variant="body2">
						Approved by:{' '}
						<b>
							{video.updatedByFirstName} {video.updatedByLastName}
						</b>
					</Typography>
				);
			case 'rejected':
				return (
					<Typography variant="body2">
						Rejected by:{' '}
						<b>
							{video.updatedByFirstName} {video.updatedByLastName}
						</b>
					</Typography>
				);

			case 'rejectedWithPayment':
				return (
					<Typography variant="body2">
						Rejected with payment by:{' '}
						<b>
							{video.updatedByFirstName} {video.updatedByLastName}
						</b>
					</Typography>
				);

			default:
				'';
		}

		if (!video?.updateByUserId) return 'N/A';
	};

	const ConfirmDialog = (props: { isOpen: boolean; handleCallback: () => void; onClose: () => void }) => {
		const { isOpen, handleCallback, onClose } = props;

		const _handleCallback = () => {
			handleCallback();
			onClose();
		};

		return (
			<Dialog open={isOpen} maxWidth={'xs'} fullWidth>
				<DialogContent>{t('Are you sure?')}</DialogContent>
				<DialogActions>
					<Button color="error" onClick={() => onClose()}>
						{t('No')}
					</Button>
					<Button variant="contained" onClick={() => _handleCallback()}>
						{t('Yes')}
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	return (
		<SimpleLayout passedStyles={styles.root}>
			{!!video ? (
				<>
					<Box className={styles.mainContainer}>
						<Box className={styles.backButton}>
							<Button onClick={history.goBack} sx={{ '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' } }} variant="text" color="primary">
								<img src={arrowLeft} alt={'arrow left'} />
								{urlPar?.includes('project') && 'Back to Project'}
								{urlPar?.includes('uploads') && 'Back to Uploads'}
								{urlPar?.includes('profile') && 'Back to Profile'}
							</Button>
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Typography variant="h1">{t('videoDetails')}</Typography>
							<Box className={styles.tags}>
								{video.status === 'pending' && <Chip sx={{ backgroundColor: '#FFA41D', color: 'white' }} label={'Pending'}></Chip>}
								{video.status === 'approved' && <Chip sx={{ backgroundColor: '#39A88F', color: 'white' }} label={'Approved'}></Chip>}
								{video.status === 'rejectedWithPayment' && (
									<Chip sx={{ backgroundColor: '#39A88F', color: 'white' }} label={'Rejected With Payment'}></Chip>
								)}
								{video.status === 'errored' && <Chip sx={{ backgroundColor: '#FE5F55', color: 'white' }} label={'Errored'}></Chip>}
								{video.status === 'rejected' && <Chip sx={{ backgroundColor: '#e74c3c', color: 'white' }} label={'Rejected'}></Chip>}
								{video.status === 'notUploaded' && <Chip sx={{ backgroundColor: '#cccbcb', color: 'white' }} label={'Not Uploaded'}></Chip>}
								{video?.gps === 'ok' && (
									<Chip variant="outlined" color="primary" label={'GPS'} icon={<img src={checkedTagIcon} alt="checked icon" />} />
								)}
								{video?.gps === 'fail' && <Chip variant="outlined" color="error" label={'GPS'} icon={<img src={xRedIcon} alt="x red icon" />} />}
							</Box>
						</Box>
						<Box
							sx={{
								backgroundColor: 'secondary.100',
								maxWidth: '800px', // Adjust the width to 'auto' to fit the content
								borderRadius: '24px',
								padding: '6px',
								boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.07)',
								marginY: '24px'
							}}>
							<Box
								display="flex"
								sx={{
									'@media (max-width: 719px)': {
										flexDirection: 'column'
									}
								}}>
								<Box flex="1" p={2}>
									<Typography variant="h6">Upload data</Typography>
									<Typography variant="body2" onClick={handleCopyClick} style={{ cursor: 'pointer' }}>
										Video ID: <b>{video.id}</b>
										{copied && <span style={{ marginLeft: '8px' }}>Copied!</span>}
									</Typography>

									<Typography variant="body2">
										Project Name:{' '}
										<Link style={{ textDecoration: 'none' }} to={`${process.env.PUBLIC_URL}/project/${video.projectId}`}>
											<Button variant="text" size="small">
												{video.projectName}
											</Button>
										</Link>
									</Typography>
									<Typography variant="body2">{getPreposition()}</Typography>
									<Typography variant="body2">
										Uploaded by:{' '}
										<b>
											<Link style={{ textDecoration: 'none' }} to={`${process.env.PUBLIC_URL}/profile/${video.uploadedByUserId}`}>
												<Button variant="text" size="small">
													{video.uploadedByUsername}
												</Button>
											</Link>
										</b>
									</Typography>

									<Typography variant="body2">
										Uploaded: <b>{moment.unix(+video?.createdAt).format('Do MMM YYYY, HH:mm:ss')} </b>
									</Typography>
									<Typography variant="body2">
										Filename: <b>{video.fileName}</b>
									</Typography>
								</Box>

								<Box flex="1" p={2}>
									<Typography variant="h6">Device data</Typography>

									{video.videoMetadata && (
										<>
											<Typography variant="body2">
												Device name:<b> {video.videoMetadata ? video.videoMetadata.deviceName : ''} </b>
											</Typography>
											<Typography variant="body2">
												Device OS:<b> {video.videoMetadata ? video.videoMetadata.deviceOS : ''} </b>
											</Typography>
											<Typography variant="body2">
												App version:<b> {video.videoMetadata ? video.videoMetadata.appVersion : ''} </b>
											</Typography>
											<Typography variant="body2">
												Environment:<b> {video.videoMetadata ? video.videoMetadata.environment : ''} </b>
											</Typography>
										</>
									)}
									{!video.videoMetadata && 'No device data found'}
								</Box>
							</Box>
						</Box>

						<CarchupaPaper type="column" parentStyle={styles.content}>
							<Box className={styles.videoMapSection}>
								<Box className={styles.videoPlayer}>
									{video.url ? <VideoPlayer height="80%" videoSrc={video.url} bigPlayer={true} /> : <Box component="span">{t('noVideoURL')}</Box>}
									<VideoMap mapHeight="85%" />
								</Box>
							</Box>
							<Box className={styles.videoActions}>
								{(adminRole === 'superadmin' || adminRole === 'admin') && (video.status === 'pending' || video.status === 'notUploaded') && (
									<Box className={styles.actionButtons}>
										<ApproveRejectBtn
											status={video?.status}
											onClick={() => {
												setOpenSelectPaymentStatus(!openSelectPaymentStatus);
											}}
											kind="reject"
										/>
										<ApproveRejectBtn
											status={video?.status}
											onClick={() => {
												setOpenApproveModal(true);
												setData({ message: t('ok_response') });
											}}
											kind="approve"
										/>
										{openSelectPaymentStatus && (
											<SelectRejectOption
												handleClick={handleRejectOptionClick}
												setOpenRejectModal={setOpen}
												openRejectModal={open}
												setRejectionWithPayment={setRejectionWithPayment}
											/>
										)}
									</Box>
								)}
								<Button variant="contained" color="primary" startIcon={<RouteIcon />} onClick={() => showGPX()}>
									{t('GPX')}
								</Button>
								{adminRole === 'superadmin' && (
									<>
										<Button variant="contained" color="primary" startIcon={<ExploreIcon />} onClick={() => showGEOJSON()}>
											{t('GEOJSON')}
										</Button>
										<Button
											disabled
											variant="contained"
											color="primary"
											startIcon={<WrongLocationIcon />}
											onClick={() => setIsReturnObjectToMapDialogOpen(true)}>
											{t('Return objects to map (Temporarily disabled)')}
										</Button>
									</>
								)}
								<Button variant="contained" color="primary" startIcon={<DownloadIcon />} onClick={() => downloadVideo()}>
									{t('DOWNLOADButton')}
								</Button>
							</Box>
							<CarchupaPaper type="row" parentStyle={''} childStyle={styles.collectablesData} removeBoxShadow>
								{collectables &&
									collectables
										.sort((a, b) => a.markerId - b.markerId)
										.map(collectable => {
											const fruitImages: Record<string, string> = { blueberry, grape, strawberry, peach, banana, watermelon };
											return (
												<>
													<Box className={styles.collectable}>
														<img className={styles.fruitImg} src={fruitImages[collectable?.name]} alt={collectable.name} />
														{collectable.count}
													</Box>

													{collectable !== collectables[collectables.length - 1] && <Box className={styles.line}></Box>}
												</>
											);
										})}
							</CarchupaPaper>
						</CarchupaPaper>
					</Box>
				</>
			) : null}
			<CarchupaDialog setOpen={setOpenApproveModal} open={openApproveModal}>
				<VideoModalApprove setOpen={setOpen} handleSubmit={handleApproveSubmit} />
			</CarchupaDialog>
			<CarchupaDialog setOpen={setOpen} open={open}>
				<VideoModalReject
					data={data}
					setData={setData}
					setOpen={setOpen}
					handleSubmit={handleSubmit}
					handleDataChange={handleDataChange}
					setRejectionReasonState={setRejectionReason}
					rejectionReasonState={rejectionReason}
				/>
			</CarchupaDialog>

			<ConfirmDialog
				isOpen={isReturnObjectToMapDialogOpen}
				onClose={() => setIsReturnObjectToMapDialogOpen(false)}
				handleCallback={() => handleReturnObjectsToMap()}
			/>
		</SimpleLayout>
	);
};

export default inject('authStore', 'settingsStore')(observer(Video));
