import styles from './MissionEditor.module.scss';

import { DateTimePicker, LoadingButton, TabContext } from '@mui/lab';
import TabPanel from '@mui/lab/TabPanel';
import { Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Typography } from '@mui/material';

import { t } from 'i18next';
import React, { useState, useEffect } from 'react';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import { inject, observer } from 'mobx-react';
import missionEditorStore, { StepType, Step } from '../../../stores/missionEditorStore';
import { API } from '../../../api';
import { Countries, Tags, userFromAllList } from '../../../models/general';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { toast } from 'react-toastify';
import SaveIcon from '@mui/icons-material/Save';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SatelliteIcon from '@mui/icons-material/Satellite';
//import { PhonePreview } from '../../../components/UI/phone-preview/PhonePreview';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';

import { MissionStep } from './MissionStep';
import { useHistory } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { uploadPolygon } from '../../../api/mapApi';

import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';

const CSVDropzone = observer(() => {
	const { setStepProperty, mission } = missionEditorStore;

	const onDrop = (file: any) => {
		mission.csvFile = file[0];
	};

	const onFileDelete = (e: React.SyntheticEvent) => {
		e.stopPropagation();
		mission.csvFile = undefined;
	};

	const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false, accept: '.csv' });

	return (
		<div>
			<Box>
				<label>Collectables CSV uploader</label>
				<a
					style={{ textDecoration: 'none', color: '#139074', paddingTop: '10px' }}
					download="example.csv"
					href="data:text/csv;base64,bGF0aXR1ZGUsbG9uZ2l0dWRlLHZhbHVlLG1hcmtlcklkCjYxLjQ5NjE2NCwgMjMuNzc3MDQwLDMsMQo=">
					{` (${t('editProject.sampleCSVLinkText')})`}
				</a>
			</Box>
			{!mission.csvFile && (
				<div>
					<div
						{...getRootProps()}
						style={{
							background: '#F9F8F2',
							border: '1px dashed #A4A4A4',
							boxSizing: 'border-box',
							borderRadius: '8px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							padding: '18px',
							cursor: 'pointer'
						}}>
						<input {...getInputProps()} />
						<SummarizeOutlinedIcon color="primary" fontSize="large" />
						<span>Click to upload, or drag and drop CSV files here</span>
					</div>
				</div>
			)}

			{mission.csvFile && (
				<div>
					<div
						{...getRootProps()}
						style={{
							background: '#F9F8F2',
							border: '1px dashed #A4A4A4',
							boxSizing: 'border-box',
							borderRadius: '8px',
							display: 'flex',

							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							padding: '18px',
							cursor: 'pointer'
						}}>
						<input {...getInputProps()} />
						<Box sx={{ display: 'flex', padding: '12px', alignItems: 'center', gap: '15px' }}>
							<Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
								<SummarizeOutlinedIcon color="primary" />
								{mission.csvFile.name}
							</Box>
							<Button onClick={onFileDelete} color="error" startIcon={<DeleteOutlineIcon />}>
								Delete
							</Button>
						</Box>
					</div>
				</div>
			)}
		</div>
	);
});

function GeoJsonDropzone(props: { setGeoJsonFile: React.Dispatch<File | undefined>; geoJsonFile: File | undefined; projectId: number }) {
	const { setGeoJsonFile, geoJsonFile, projectId } = props;
	const [isGeoUploading, setIsGeoUploading] = useState<boolean>(false);

	const onDrop = (file: File[]) => {
		setGeoJsonFile(file[0]);
	};

	const onFileDelete = (e: React.SyntheticEvent) => {
		e.stopPropagation();
		setGeoJsonFile(undefined);
	};

	const onFileUpload = async (e: React.SyntheticEvent) => {
		if (!geoJsonFile) return;
		setIsGeoUploading(true);
		e.stopPropagation();

		try {
			const res = await uploadPolygon(projectId, geoJsonFile);
			if (res.status === 201) toast.success('Polygon uploaded');
		} catch (err) {
			console.error('Error uploading polygon', err);
			toast.error('Error uploading polygon');
		} finally {
			setIsGeoUploading(false);
		}
	};

	const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false, accept: '.json, .geojson' });

	return (
		<div>
			{!geoJsonFile && (
				<div>
					<label>GEOJson Polygon</label>
					<div
						{...getRootProps()}
						style={{
							background: projectId > 0 ? '#F9F8F2' : 'rgba(0, 0, 0, 0.1)',
							border: '1px dashed #A4A4A4',
							boxSizing: 'border-box',
							borderRadius: '8px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							padding: '24px',
							cursor: projectId > 0 ? 'pointer' : 'not-allowed'
						}}>
						<input {...getInputProps()} disabled />
						<SatelliteIcon color={projectId > 0 ? 'primary' : 'disabled'} fontSize="large" />
						{projectId > 0 && <span>Click to upload or drag and drop GeoJSON file here.</span>}
						{!projectId && (
							<span>
								<i>Save mission before uploading Polygons</i>
							</span>
						)}
					</div>
				</div>
			)}

			{geoJsonFile ? (
				<div>
					<div
						{...getRootProps()}
						style={{
							background: '#F9F8F2',
							border: '1px dashed #A4A4A4',
							boxSizing: 'border-box',
							borderRadius: '8px',
							display: 'flex',

							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							padding: '24px',
							cursor: 'pointer'
						}}>
						<input {...getInputProps()} />
						{geoJsonFile.name}
						<Box sx={{ display: 'flex', padding: '12px' }}>
							<Button color="warning" disabled={isGeoUploading} onClick={onFileDelete} startIcon={<DeleteOutlineIcon />}>
								Delete
							</Button>
							<LoadingButton
								color="primary"
								loading={isGeoUploading}
								onClick={onFileUpload}
								startIcon={<DriveFolderUploadIcon />}
								variant="contained">
								Upload to map server
							</LoadingButton>
						</Box>
					</div>
				</div>
			) : null}
		</div>
	);
}

function MissionEditor() {
	const {
		mission,
		createStep,
		selectedTab,
		setSelectedTab,
		setUsers,
		setClientUsers,
		setMissionId,
		setMission,
		setSteps,
		reset,
		steps,
		setSelectedTags,
		stepsWithErrors,
		save,
		validateMission,
		errors
	} = missionEditorStore;

	const history = useHistory();
	const [userList, setUserList] = useState<userFromAllList[]>([]);
	const [tagOptions, setTagOptions] = useState<Tags[]>([]);
	const [countryOptions, setCountryOptions] = useState<Countries[]>([]);
	const [geoJsonFile, setGeoJsonFile] = useState<File | undefined>(undefined);
	const [isSaveMissionLoading, setIsSaveMissionLoading] = useState<boolean>(false);

	const fetchCountryOptions = async () => {
		const res = await API.getCountries();
		setCountryOptions(res.data.countries);
	};

	const fetchUsers = async () => {
		const res = await API.getAllUsers(undefined);
		setUserList(res.data.users);
	};

	const fetchTagOptions = async () => {
		const res = await API.getTags();
		setTagOptions(res.data.tags);
	};

	// Tries to save the mission. If the mission saves successfully it will return the projectId
	const saveMission = async () => {
		setIsSaveMissionLoading(true);
		try {
			const projectId = await save();
			toast.success('Mission saved');
			// Set the ID of the project when saved successfully.
			setMissionId(projectId);
			// Update URL to have created projectId
			history.push(`/mission-editor/${projectId}`);
			await fetchMission();
		} catch (err) {
			toast.error(err.message);
			console.error(err);
		} finally {
			setIsSaveMissionLoading(false);
		}
	};

	const fetchMission = async () => {
		const url = window.location.pathname;
		const projectId = Number(url.substring(url.lastIndexOf('/') + 1, url.length));

		// If a projectId is passed through url, try to load it.
		if (projectId > 0) {
			API.getProject(Number(projectId), 'en')
				.then(res => {
					// Something wrong with the steps here
					// @ts-ignore
					const { countryId, dateStart, lat, lon, name, steps, tags, users, id, projectTypeId, references, clientUsers } = res.data.project;

					// Source is not required but is always loaded atm.
					delete steps[0].lobbyParams.source;

					setMission({
						id,
						name: name || '',
						countryId,
						lat,
						lon,
						users: users || [],
						projectTypeId,
						dateStart: dateStart * 1000,
						// @ts-ignore
						references: [],
						// @ts-ignore
						oldReferences: references,
						tags: tags || [],
						csvFile: undefined,
						clientUsers: clientUsers || []
					});
					setSteps(steps);
					history.push(`/mission-editor/${id}`);
				})
				.catch(err => {
					toast.error('Error loading mission');
					console.error(err);
				});
		} else {
			// If a url paramater is not passed or it's 0, start a new project.
			reset();
			history.push(`/mission-editor/0`);
		}
	};

	useEffect(() => {
		fetchMission();
		fetchUsers();
		fetchCountryOptions();
		fetchTagOptions();
	}, []);

	const privateUsersOnChange = (_e: React.SyntheticEvent, values: userFromAllList[]) => setUsers(values.map(v => v.id));
	const clientUsersOnChange = (_e: React.SyntheticEvent, values: userFromAllList[]) => setClientUsers(values.map(v => v.id));
	const tabOnChange = (_e: React.SyntheticEvent, newTab: string) => setSelectedTab(newTab);
	const tagsOnchange = (_e: React.SyntheticEvent, values: Tags[]) => setSelectedTags(values);
	const createNewStep = () => createStep();

	return (
		<SimpleLayout passedStyles={styles.root}>
			<Typography variant="h1">{t('missionEditor.title')}</Typography>
			<Box sx={{ display: 'flex', flexDirection: 'column', paddingRight: '100px', maxWidth: '1000px', flex: '1 1 100%' }}>
				{/* Mission setting, description etc */}
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', paddingBottom: '50px', justifySelf: 'center' }}>
					<TextField
						error={errors.name}
						helperText={errors.name}
						label="Mission Name"
						onChange={e => missionEditorStore.setMissionName(e.target.value)}
						value={mission.name}
					/>
					<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
						<TextField
							error={errors.lat}
							helperText={errors.lat}
							label="Latitude"
							onChange={e => (mission.lat = e.target.value)}
							sx={{ flex: '1 1 190px' }}
							value={mission.lat}
						/>
						<TextField
							error={errors.lon}
							helperText={errors.lon}
							label="Longitude"
							onChange={e => (mission.lon = e.target.value)}
							sx={{ flex: '1 1 190px' }}
							value={mission.lon}
						/>
					</Box>
					<DateTimePicker
						ampm={false}
						onChange={date => missionEditorStore.setMissionStartDate(date!)}
						renderInput={params => <TextField {...params} label={t('missionEditor.startDate')} />}
						value={mission.dateStart}
					/>
					<FormControl>
						<InputLabel id="country-label">Country</InputLabel>
						<Select
							onChange={e => (missionEditorStore.mission.countryId = e.target.value)}
							value={mission.countryId}
							id="country"
							label="country"
							labelId="country-label">
							{countryOptions.map(country => (
								<MenuItem key={country.id} value={country.id}>
									{country.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					<Autocomplete
						getOptionLabel={(option: any) => {
							if (!option) return '';
							return option.id + '-' + option.username;
						}}
						id="tags-standard"
						multiple
						onChange={privateUsersOnChange}
						options={userList}
						renderInput={params => (
							<TextField
								{...params}
								label={t('missionEditor.privateMissionUsers')}
								sx={{
									'.MuiOutlinedInput-root': {
										paddingTop: '0px',
										paddingBottom: '0px',
										minHeight: '46px',
										height: '100%',
										maxHeight: 'unset'
									}
								}}
							/>
						)}
						value={mission.users?.map(userId => {
							if (!userList || !mission.users) return [];
							const match = userList.find(u => u.id === userId);

							if (match) return match;
						})}
					/>
					<Autocomplete
						getOptionLabel={(option: any) => {
							if (!option) return '';
							return option.id + '-' + option.username;
						}}
						id="tags-standard"
						multiple
						onChange={clientUsersOnChange}
						options={userList}
						renderInput={params => (
							<TextField
								{...params}
								label={t('missionEditor.clientUsers')}
								sx={{
									'.MuiOutlinedInput-root': {
										paddingTop: '0px',
										paddingBottom: '0px',
										minHeight: '46px',
										height: '100%',
										maxHeight: 'unset'
									}
								}}
							/>
						)}
						value={mission.clientUsers.map(userId => {
							if (!userList || !mission.clientUsers) return [];
							const match = userList.find(u => u.id === userId);

							if (match) return match;
						})}
					/>

					<Autocomplete
						getOptionLabel={option => {
							return option.name;
						}}
						id="tags-standard"
						isOptionEqualToValue={(option, value) => {
							return option.id == value.id;
						}}
						multiple
						onChange={tagsOnchange}
						options={tagOptions}
						renderInput={params => (
							<TextField
								error={errors.tags}
								helperText={errors.tags}
								label={t('missionEditor.selectTags')}
								sx={{
									'.MuiOutlinedInput-root': {
										paddingTop: '0px',
										paddingBottom: '0px',
										minHeight: '46px',
										height: '100%',
										maxHeight: 'unset'
									}
								}}
								{...params}
							/>
						)}
						value={mission.tags}
					/>

					<Box sx={{ display: 'flex', gap: '12px', flexDirection: 'column' }}>
						<GeoJsonDropzone geoJsonFile={geoJsonFile} projectId={mission.id} setGeoJsonFile={setGeoJsonFile} />
						<CSVDropzone />
					</Box>
				</Box>

				<Typography variant="h6">{t('missionEditor.steps')}</Typography>
				<TabContext value={selectedTab}>
					{/* Tabs */}
					<Box>
						<Tabs onChange={tabOnChange} scrollButtons="auto" value={selectedTab} variant="scrollable">
							{steps.map((step: any, index: any) => (
								<Tab
									draggable="true"
									icon={
										<span>
											{step.order}
											{stepsWithErrors.includes(index) && <ErrorOutlineIcon color="error" fontSize="small" />}
										</span>
									}
									key={index}
									value={step.order.toString()}
								/>
							))}
							<Tab label="+" onClick={createNewStep} value="new" />
						</Tabs>
						{steps.map((step: any) => (
							<TabPanel key={step.order} value={step.order.toString()}>
								<MissionStep step={step} />
							</TabPanel>
						))}
					</Box>
					<Box sx={{ display: 'flex', gap: '12px' }}>
						<Button color="error" disabled={isSaveMissionLoading} onClick={fetchMission} startIcon={<RestartAltIcon />} variant="outlined">
							{t('Cancel changes')}
						</Button>
						<LoadingButton loading={isSaveMissionLoading} onClick={saveMission} startIcon={<SaveIcon />} variant="contained">
							{t('missionEditor.saveMission')}
						</LoadingButton>
					</Box>
				</TabContext>
			</Box>
		</SimpleLayout>
	);
}

export default inject('missionEditorStore')(observer(MissionEditor));
