import { FormControl, Select, MenuItem } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from '../../../api';
import { ProjectsData } from '../../../models/projects';
import authStore from '../../../stores/authStore';
import messageStore from '../../../stores/messageStore';
import settingsStore from '../../../stores/settingsStore';

interface Props {}

const ProjectSelect = (props: Props) => {
	const { selectedProject, selectedState } = settingsStore;
	const { globalProject } = authStore;

	const { t } = useTranslation();
	const [rows, setRows] = useState<ProjectsData[]>([]);

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		settingsStore.updateSelectedProject(+e.target.value);
	};

	const loadProjectList = useCallback(async () => {
		const response = await API.getProjectList({ fields: 'id,name' });
		if (response) {
			setRows(
				response.data.projects.filter(project => {
					return !!selectedState ? project?.countryId === selectedState : true;
				})
			);
			// messageStore.snackbar({message: `${t('Projects loaded')}`, type: 'info'});
		} else {
			messageStore.snackbar({
				message: `${t('Failed to fetch project list')}`,
				type: 'error'
			});
		}
	}, [selectedState, t]);

	useEffect(() => {
		loadProjectList();
	}, []);

	useEffect(() => {
		loadProjectList();
		if (globalProject) {
			settingsStore.updateSelectedProject(globalProject);
		}
	}, [globalProject, loadProjectList]);

	return (
		<FormControl>
			<label style={{ color: '#494F4D' }}>Missions</label>
			<Select id="mission-select" value={!!selectedProject ? selectedProject : 'all'} onChange={(e: any) => handleChange(e)} displayEmpty>
				<MenuItem value="all">
					<em>All</em>
				</MenuItem>
				{rows.map((item: ProjectsData) => (
					<MenuItem key={item.id} value={item.id}>
						{item.name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default inject('settingsStore', 'messageStore', 'authStore')(observer(ProjectSelect));
