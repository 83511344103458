// @ts-ignore
import mapboxgl, { Map, Marker } from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { FeatureCollection } from 'geojson';
import { Autocomplete, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import Tooltip from '@mui/material/Tooltip';
import RefreshIcon from '@mui/icons-material/Refresh';
import IconButton from '@mui/material/IconButton';

import styles from './mapboxMap.module.scss';
import { API } from '../../../api';
import { MissionType } from '../videos/Uploads';
import { ItemDialog } from './ItemDialog/ItemDialog';
import { PhotoMarkers, VideoWaypoints } from '../../../models/general';

import manholeCover from '../../../assets/manhole-cover.svg';
import distributionCabinet from '../../../assets/distribution-cabinet.svg';
import drainage from '../../../assets/drainage.svg';
import bench from '../../../assets/bench-object.svg';
import roadblock from '../../../assets/roadblock.svg';
import transformer from '../../../assets/transformer.svg';
import trashcan from '../../../assets/trashcan.svg';
import slipperyPath from '../../../assets/slippery-path.svg';
import snowPlowed from '../../../assets/snow-plowed.svg';
import unplowedPath from '../../../assets/unplowed-path.svg';
import roadDefect from '../../../assets/road-defect.svg';
import himalayanBalsam from '../../../assets/himalayan-balsam.svg';
import giantHogweed from '../../../assets/giant-hogweed.svg';
import lupine from '../../../assets/lupine.svg';
import japaneseRose from '../../../assets/japanese-rose.svg';
import brokenSoundBeacon from '../../../assets/broken_sound_beacon.svg';
import roughPath from '../../../assets/rough-path.svg';
import workingSoundBeacon from '../../../assets/working-sound-beacon.svg';
import pedestrianPath from '../../../assets/pedestrian-path.svg';
import droppedCurb from '../../../assets/dropped-curb.svg';
import japaneseKnotweed from '../../../assets/japanese-knotweed.svg';
import drainCover from '../../../assets/drain-cover.svg';
import curbsideDrainCover from '../../../assets/curbside-drain-cover.svg';
import waterValveCover from '../../../assets/water-valve-cover.svg';

import canadaGoldenrod from '../../../assets/canada_goldenrod.svg';
import falseSpiraea from '../../../assets/false_spiraea.svg';
import culvert from '../../../assets/culvert.svg';
import spanishSlug from '../../../assets/spanish_slug.svg';
import { ProjectsData } from '../../../models/projects';
import MapIcon from '@mui/icons-material/Map';
import { getPhotoProjectStyle, getPhotoSatProjectStyle, getProjectGameAreaPolygon } from '../../../api/mapApi';
import authStore from '../../../stores/authStore';

import lesserPeriwinkle from '../../../assets/lesser_periwinkle.svg';
import buddleia from '../../../assets/buddleia.svg';
import parrotsFeather from '../../../assets/parrots_feather.svg';
import goatsRue from '../../../assets/goats_rue.svg';
import cherryLaurel from '../../../assets/cherry_laurel.svg';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string;

enum SourceLayerNames {
	GAMEAREA = 'gameAreaPolygon',
	VIDEO_ROUTES = 'videoRoutes',
	PHOTO_MARKERS = 'photoMarkers',
	GAMEAREA_OUTLINE = 'gameAreaOutline'
}

// Leaving this here for now.
export enum ObjectTypes {
	MANHOLE_COVER = 1,
	BENCH = 2,
	DISTRIBUTION_CABINET = 3,
	ROADBLOCK = 4,
	TRASHCAN = 5,
	DRAINAGE = 6,
	TRANSFORMER = 7,
	SLIPPERY_PATH = 8,
	SNOW_PLOWED = 9,
	UNPLOWED_PATH = 10,
	ROAD_DEFECT = 11,
	HIMALAYAN_BALSAM = 12,
	GIANT_HOGWEED = 13,
	LUPINE = 14,
	JAPANESE_ROSE = 15,
	ROUGH_PATH = 16,
	DRAIN_COVER = 17,
	CURBSIDE_DRAIN_COVER = 18,
	WATER_VALVE_COVER = 19,
	JAPANESE_KNOTWEED = 20,
	BROKEN_SOUND_BEACON = 21,
	WORKING_SOUND_BEACON = 22,
	PEDESTRIAN_PATH = 23,
	DROPPED_CURB = 24,
	CANADA_GOLDENROD = 25,
	FALSE_SPIRAEA = 26,
	CULVERT = 27,
	SPANISH_SLUG = 28,
	LESSER_PERIWINKLE = 29,
	BUDDLEIA = 30,
	PARROTS_FEATHER = 31,
	GOATS_RUE = 32,
	CHERRY_LAUREL = 33
}

const getImgSrc = (objectTypeId: number) => {
	switch (objectTypeId) {
		case ObjectTypes.MANHOLE_COVER:
			return `${manholeCover}`;
		case ObjectTypes.BENCH:
			return `${bench}`;
		case ObjectTypes.DISTRIBUTION_CABINET:
			return `${distributionCabinet}`;
		case ObjectTypes.ROADBLOCK:
			return `${roadblock}`;
		case ObjectTypes.TRASHCAN:
			return `${trashcan}`;
		case ObjectTypes.DRAINAGE:
			return `${drainage}`;
		case ObjectTypes.TRANSFORMER:
			return `${transformer}`;
		case ObjectTypes.SLIPPERY_PATH:
			return `${slipperyPath}`;
		case ObjectTypes.SNOW_PLOWED:
			return `${snowPlowed}`;
		case ObjectTypes.UNPLOWED_PATH:
			return `${unplowedPath}`;
		case ObjectTypes.ROAD_DEFECT:
			return `${roadDefect}`;
		case ObjectTypes.HIMALAYAN_BALSAM:
			return `${himalayanBalsam}`;
		case ObjectTypes.GIANT_HOGWEED:
			return `${giantHogweed}`;
		case ObjectTypes.LUPINE:
			return `${lupine}`;
		case ObjectTypes.JAPANESE_ROSE:
			return `${japaneseRose}`;
		case ObjectTypes.ROUGH_PATH:
			return `${roughPath}`;
		case ObjectTypes.DRAIN_COVER:
			return `${drainCover}`;
		case ObjectTypes.CURBSIDE_DRAIN_COVER:
			return `${curbsideDrainCover}`;
		case ObjectTypes.WATER_VALVE_COVER:
			return `${waterValveCover}`;
		case ObjectTypes.JAPANESE_KNOTWEED:
			return `${japaneseKnotweed}`;
		case ObjectTypes.BROKEN_SOUND_BEACON:
			return `${brokenSoundBeacon}`;
		case ObjectTypes.WORKING_SOUND_BEACON:
			return `${workingSoundBeacon}`;
		case ObjectTypes.PEDESTRIAN_PATH:
			return `${pedestrianPath}`;
		case ObjectTypes.DROPPED_CURB:
			return `${droppedCurb}`;
		case ObjectTypes.CANADA_GOLDENROD:
			return `${canadaGoldenrod}`;
		case ObjectTypes.FALSE_SPIRAEA:
			return `${falseSpiraea}`;
		case ObjectTypes.CULVERT:
			return `${culvert}`;
		case ObjectTypes.SPANISH_SLUG:
			return `${spanishSlug}`;
		case ObjectTypes.LESSER_PERIWINKLE:
			return `${lesserPeriwinkle}`;
		case ObjectTypes.BUDDLEIA:
			return `${buddleia}`;
		case ObjectTypes.PARROTS_FEATHER:
			return `${parrotsFeather}`;
		case ObjectTypes.GOATS_RUE:
			return `${goatsRue}`;
		case ObjectTypes.CHERRY_LAUREL:
			return `${cherryLaurel}`;
		default:
			// Handle the default case if needed
			return 'Unknown object type';
	}
};

export function MapboxMap() {
	const mapContainer = useRef(null);
	const map = useRef<Map | null>(null);
	const [lng, setLng] = useState(23.76);
	const [lat, setLat] = useState(61.49);
	const [zoom, setZoom] = useState(15);
	const [projects, setProjects] = useState<ProjectsData[]>([]);
	const [selectedProject, setSelectedProject] = useState<{ id: number; projectTypeId: number } | undefined>(undefined);
	const [isItemDialogOpen, setIsItemDialogOpen] = useState<boolean>(false);
	const [clickedItem, setClickedItem] = useState<number | undefined>();
	const [projectStatus, setProjectStatus] = useState<string>('all');
	const [markers, setMarkers] = useState<Marker[]>([]);
	const [projectResponses, setProjectResponses] = useState<PhotoMarkers[] | VideoWaypoints[]>([]);
	const [isProjectLoading, setIsProjectLoading] = useState<boolean>(false);
	const [isGameAreaLoading, setIsGameAreaLoading] = useState<boolean>(false);
	const isLoading = isProjectLoading || isGameAreaLoading;
	const [isFetchSatellite, setIsFetchSatellite] = useState<boolean>(false);

	// Initial useEffect
	useEffect(() => {
		if (map.current) return; // initialize map only once

		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/mapbox/streets-v12',
			transformRequest: (url: string, resourceType: string) => {
				const gisApiUrl = process.env.REACT_APP_GIS_API || '';

				if (url.includes(gisApiUrl)) {
					return {
						url,
						headers: {
							authorization: `Bearer ${authStore.token}`
						}
					};
				}
			},
			center: [lng, lat],
			zoom: zoom
		});

		map.current.on('click', SourceLayerNames.VIDEO_ROUTES, e => {
			if (e.features && e.features.length > 0 && e.features[0].properties) {
				setClickedItem(e.features[0].properties.id);
				setIsItemDialogOpen(true);
			}
		});

		map.current.on('mouseenter', SourceLayerNames.VIDEO_ROUTES, function () {
			map.current!.getCanvas().style.cursor = 'pointer';
		});

		// Change it back to a pointer when it leaves.
		map.current.on('mouseleave', SourceLayerNames.VIDEO_ROUTES, function () {
			map.current!.getCanvas().style.cursor = '';
		});

		API.getProjectList({
			countryId: undefined,
			fields: 'lat,lon,name,id,projectTypeId',
			hideFinished: true,
			language: 'en'
		})
			.then(res => {
				const projects = res.data.projects;

				setProjects(projects);
			})
			.catch(err => {
				console.error(err);
				toast.error('Failed to fetch missions', { autoClose: 1500 });
			});
	}, []);

	const addMarkerToMap = (p: any) => {
		const img = new Image(48, 48);

		img.src = getImgSrc(p.objectId);
		img.dataset.id = p.id;
		img.dataset.status = p.status;
		img.dataset.objectId = p.objectId;
		img.dataset.lon = p.lon;
		img.dataset.lat = p.lat;

		img.onclick = (e: any) => {
			setClickedItem(e.target.dataset.id);
			setIsItemDialogOpen(true);
		};

		const marker = new mapboxgl.Marker(img).setLngLat([p.lon, p.lat]).addTo(map.current!);
		setMarkers(oldMarkers => [...oldMarkers, marker]);
	};

	const removeExistingSourcesAndLayers = () => {
		if (!map.current) return;
		if (!selectedProject) return;

		if (map.current.getLayer(SourceLayerNames.VIDEO_ROUTES)) map.current?.removeLayer(SourceLayerNames.VIDEO_ROUTES);
		if (map.current.getSource(SourceLayerNames.VIDEO_ROUTES)) map.current?.removeSource(SourceLayerNames.VIDEO_ROUTES);

		if (map.current.getLayer(SourceLayerNames.GAMEAREA)) map.current?.removeLayer(SourceLayerNames.GAMEAREA);
		if (map.current.getSource(SourceLayerNames.GAMEAREA)) map.current?.removeSource(SourceLayerNames.GAMEAREA);

		if (map.current.getLayer(SourceLayerNames.GAMEAREA_OUTLINE)) map.current?.removeLayer(SourceLayerNames.GAMEAREA_OUTLINE);

		markers.forEach(m => {
			m.remove();
		});

		setMarkers([]);
	};

	const changeProject = (project: { id: number; projectTypeId: number } | undefined) => {
		removeExistingSourcesAndLayers();

		if (project == undefined) {
			setSelectedProject(undefined);
		} else {
			const { id, projectTypeId } = project;
			setSelectedProject({ id, projectTypeId });
		}
	};

	const addRoutesToMap = (videos: any) => {
		if (!map.current) return;

		const sourceData: FeatureCollection = {
			type: 'FeatureCollection',
			features: videos.map((v: any) => {
				return {
					type: 'Feature',
					properties: {
						id: v.id,
						status: v.status
					},
					geometry: {
						type: 'LineString',
						coordinates: v.coordinates
					}
				};
			})
		};

		map.current?.addSource(SourceLayerNames.VIDEO_ROUTES, {
			type: 'geojson',
			data: sourceData
		});

		map.current?.addLayer({
			id: SourceLayerNames.VIDEO_ROUTES,
			type: 'line',
			source: SourceLayerNames.VIDEO_ROUTES,
			layout: {
				'line-join': 'round',
				'line-cap': 'round'
			},
			paint: {
				'line-width': 4,
				'line-color': [
					'match',
					['get', 'status'],
					'approved',
					'#0A775F',
					'pending',
					'#F0CD14',
					'rejected',
					'#FE5F55',
					'#0A775F' // fallback color
				]
			}
		});
	};

	// Loads project wayspoints
	const loadProjectWaypoints = async () => {
		setIsProjectLoading(true);
		API.getProjectWaypoints(selectedProject!.id).then(res => {
			if (!map.current) return;
			if (!res) return;
			if (!selectedProject) return;

			API.getProjectWaypoints(selectedProject.id)
				.then(res => {
					if (!map.current) return;
					if (!res.data) {
						toast.warning('No waypoints found for this project', { autoClose: 1500 });
					}

					const { projectTypeId, id, lon, lat } = res.data;
					map.current.flyTo({ center: [lon, lat], zoom });

					if (projectTypeId == MissionType.video) {
						if (!res.data.videos) return;
						const { videos } = res.data;

						setProjectResponses(videos);
					} else if (projectTypeId == MissionType.photo) {
						const { photoMarkers } = res.data;
						if (!photoMarkers) return;

						setProjectResponses(photoMarkers!);
					} else if (projectTypeId == MissionType.custom) {
						if (!res.data) return;
						const { customData } = res.data;

						setProjectResponses(customData!);
					}
					toast.success('Project waypoints loaded', { autoClose: 1500 });
				})
				.catch(err => {
					console.error(err);
					toast.error(err);
				})
				.finally(() => {
					setIsProjectLoading(false);
				});
		});
	};

	const loadProjectGameArea = async () => {
		if (!map.current) return;
		if (!selectedProject) return;

		setIsGameAreaLoading(true);
		getProjectGameAreaPolygon(selectedProject!.id)
			.then(res => {
				if (!res.data) return;
				const { data } = res;
				const flatData = [].concat(...data);

				// @ts-ignore
				const geoJSONObjects = flatData.map(item => JSON.parse(item.geojson));

				const featureCollection = {
					type: 'FeatureCollection',
					features: geoJSONObjects.map(item => {
						return {
							type: 'Feature',
							geometry: item
						};
					})
				};

				map.current.addSource(SourceLayerNames.GAMEAREA, {
					type: 'geojson',
					data: featureCollection
				});

				map.current.addLayer({
					id: SourceLayerNames.GAMEAREA,
					type: 'fill',
					source: SourceLayerNames.GAMEAREA,
					layout: {},
					paint: {
						'fill-color': '#19bd98',
						'fill-opacity': 0.2
					}
				});

				// Api at some point returned an empty result. This is a temporary fix.
				if (flatData.length > 0) toast.success('Project game area loaded', { autoClose: 1500 });
			})
			.catch(err => {
				console.error(err);
				toast.error(err);
			})
			.finally(() => {
				setIsGameAreaLoading(false);
			});
	};

	// Update responses when selected project changes
	useEffect(() => {
		if (selectedProject == undefined) return;
		if (!map.current) return;

		if (selectedProject.projectTypeId === MissionType.photo) {
			if (isFetchSatellite) {
				getPhotoSatProjectStyle(selectedProject.id)
					.then(res => {
						const { data } = res;
						map.current.setStyle(data);
					})
					.catch(err => {
						console.error(err);
						toast.error('Failed to load project');
					});
			} else {
				getPhotoProjectStyle(selectedProject.id)
					.then(res => {
						const { data } = res;
						map.current.setStyle(data);
					})
					.catch(err => {
						console.error(err);
						toast.error('Failed to load project');
					});
			}
		} else {
			loadProjectWaypoints();
			loadProjectGameArea();
		}
	}, [selectedProject, isFetchSatellite]);

	// Render the responses when the project is finished loading
	useEffect(() => {
		renderResponses();
	}, [projectResponses]);

	const updateResponseStatusAndReRender = (uploadId: number, newStatus: string) => {
		const index = projectResponses.findIndex(v => v.id == uploadId);
		if (index == -1) return;

		let response = projectResponses[index];
		response.status = newStatus;
		const newProjectResponses = [...projectResponses.slice(0, index), response, ...projectResponses.slice(index + 1)];

		// @ts-ignore
		setProjectResponses(newProjectResponses);
		renderResponses();
	};

	const renderResponses = () => {
		removeExistingSourcesAndLayers();
		if (selectedProject?.projectTypeId == MissionType.video) {
			const source = map.current?.getSource(SourceLayerNames.VIDEO_ROUTES);

			if (!source) {
				addRoutesToMap(projectResponses);
			} else {
				const sourceData: FeatureCollection = {
					type: 'FeatureCollection',
					features: projectResponses
						.filter((v: any) => projectStatus === 'all' || v.status === projectStatus)
						.map((v: any) => ({
							type: 'Feature',
							properties: {
								id: v.id,
								status: v.status
							},
							geometry: {
								type: 'LineString',
								coordinates: v.coordinates
							}
						}))
				};

				// @ts-ignore
				source.setData(sourceData);
			}
		} else if (selectedProject?.projectTypeId == MissionType.photo) {
			for (const response of projectResponses) {
				if (projectStatus === 'all' || response.status === projectStatus) {
					addMarkerToMap(response);
				}
			}
		} else if (selectedProject?.projectTypeId == MissionType.custom) {
			const source = map.current?.getSource(SourceLayerNames.VIDEO_ROUTES);

			if (!source) {
				addRoutesToMap(projectResponses);
			} else {
				const sourceData: FeatureCollection = {
					type: 'FeatureCollection',
					features: projectResponses
						.filter((v: any) => projectStatus === 'all' || v.status === projectStatus)
						.map((v: any) => ({
							type: 'Feature',
							properties: {
								id: v.id,
								status: v.status
							},
							geometry: {
								type: 'LineString',
								coordinates: v.coordinates
							}
						}))
				};

				// @ts-ignore
				source.setData(sourceData);
			}
		}
	};

	useEffect(() => {
		renderResponses();
	}, [projectStatus]);

	return (
		<div>
			<div className={styles.sidebar}>
				<div style={{ color: 'black' }}>
					<Autocomplete
						onChange={(o, v: any) => {
							if (v == null) {
								changeProject(undefined);
							} else {
								changeProject(v);
							}
						}}
						renderOption={(props, option) => {
							return (
								<li {...props} key={option.id} id={option.id}>
									{option.name}
								</li>
							);
						}}
						getOptionLabel={option => option.name}
						options={projects.map((p: { name: string; id: number; projectTypeId: number }, index: number) => {
							return { name: p.name, id: p.id, key: index, projectTypeId: p.projectTypeId };
						})}
						renderInput={params => <TextField placeholder="Select mission..." sx={{ display: 'flex', alignContent: 'center' }} {...params} />}
						sx={{ display: 'flex', alignContent: 'center', width: '400px' }}
					/>
				</div>
				<div>
					<Select value={projectStatus} onChange={(e: SelectChangeEvent) => setProjectStatus(e.target.value)}>
						<MenuItem value={'approved'}>Approved</MenuItem>
						<MenuItem value={'rejected'}>Rejected</MenuItem>
						<MenuItem value={'pending'}>Pending</MenuItem>
						<MenuItem value={'rejectedWithPayment'}>Rejected with payment</MenuItem>
						<MenuItem value={'notUploaded'}>Not uploaded</MenuItem>
						<MenuItem value={'all'}>All</MenuItem>
					</Select>
				</div>
				<Tooltip title="Refresh responses" placement="bottom">
					<IconButton onClick={loadProjectWaypoints}>
						<RefreshIcon />
					</IconButton>
				</Tooltip>
				<Tooltip title="Fetch Satellite" placement="bottom">
					<IconButton color={isFetchSatellite ? 'primary' : 'default'} onClick={() => setIsFetchSatellite(!isFetchSatellite)}>
						<MapIcon />
					</IconButton>
				</Tooltip>
			</div>

			<div className={styles.indevbanner}>In Development</div>

			{isLoading ? (
				<div className={styles.overlay}>
					<CircularProgress />
				</div>
			) : null}

			<div ref={mapContainer} style={{ width: '100%', height: '100vh' }} />
			<ItemDialog
				isOpen={isItemDialogOpen}
				onClose={() => {
					setIsItemDialogOpen(false);
					setClickedItem(undefined);
				}}
				sessionId={clickedItem}
				marker={markers.find(m => m.getElement().dataset.id == clickedItem)}
				updateResponse={updateResponseStatusAndReRender}
			/>
		</div>
	);
}
