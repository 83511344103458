import { Box, Checkbox, TableCell, TableHead, TableRow } from '@mui/material';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import React from 'react';
import { Order } from './DataTable';

export interface HeadCell<T> {
	disablePadding: boolean;
	id: keyof T;
	label: string;
	numeric: boolean;
}

interface EnhancedTableProps<T> {
	numSelected: number;
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
	headCells: readonly HeadCell<T>[];
	withToolbar?: boolean;
}

export const DataTableHead = <T,>({
	onSelectAllClick,
	order,
	orderBy,
	numSelected,
	rowCount,
	onRequestSort,
	headCells,
	withToolbar
}: EnhancedTableProps<T>) => {
	const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property);
	};
	return (
		<TableHead
			sx={{
				backgroundColor: '#f9f8f2'
			}}>
			<TableRow>
				{withToolbar && (
					<TableCell padding="checkbox">
						<Checkbox
							color="primary"
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={rowCount > 0 && numSelected === rowCount}
							onChange={onSelectAllClick}
							inputProps={{
								'aria-label': 'select all'
							}}
						/>
					</TableCell>
				)}
				{headCells.map((headCell, index) => (
					<TableCell
						sx={{
							fontWeight: 700,
							fontSize: '16px'
						}}
						key={index}
						align={'left'}
						padding={'normal'}
						sortDirection={orderBy === headCell.id ? order : false}>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};
