import { Box, InputAdornment, OutlinedInput } from '@mui/material';
import { t } from 'i18next';
import React from 'react';
import searchIcon from '../../../assets/search.svg';
import { PaginationMainQueryKey, usePaginationQueryClient } from '../../../queries/view-queries/usePaginationQuery';
import { InputSearchQueryKey, useSearchInputQuery } from '../../../queries/view-queries/useSearchInputQuery';

interface SearchInputI {
	htmlFor: string;
	id: string;
	label: string;
	placeholder: string;
	queryKey: InputSearchQueryKey;
	paginationMainQueryKey?: PaginationMainQueryKey;
}

export const SearchInput: React.FC<SearchInputI> = ({ htmlFor, id, label, placeholder, queryKey, paginationMainQueryKey }): JSX.Element => {
	/////////////////////////////////////////////// REACT-QUERY ////////////////////////////////////////////////////////
	const { setSearchTerm, searchTerm } = useSearchInputQuery(queryKey);
	const { setPaginationData } = usePaginationQueryClient(1);
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	return (
		<>
			<Box component="label" htmlFor={htmlFor}>
				{t(label)}
			</Box>
			<OutlinedInput
				id={id}
				onChange={e => {
					paginationMainQueryKey && setPaginationData(paginationMainQueryKey, 1);
					setSearchTerm(queryKey, e.target.value === '' ? undefined : e.target.value);
				}}
				placeholder={placeholder}
				startAdornment={
					<InputAdornment position="start">
						<img src={searchIcon} alt="search" />
					</InputAdornment>
				}
				inputProps={{
					'aria-label': 'search'
				}}
				value={searchTerm}
			/>
		</>
	);
};
