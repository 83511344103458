import { Box, Button, FormControl, MenuItem, Select, Typography } from '@mui/material';
import saveAs from 'file-saver';
import JSZip from 'jszip';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { API } from '../../../api';
import CarchupaDialog from '../../../components/UX/dialog/CarchupaDialog';
import { getVideoGeojson } from '../../../logic/get-video-geojson';
import settingsStore from '../../../stores/settingsStore';
import videoStore from '../../../stores/videoStore';
import { MissionType } from './Uploads';
import { Upload } from '../../../models/general';

interface Props {
	setOpen: (open: boolean) => void;
	open: boolean;
	missionType: MissionType;
	selectedUploads: number[];
	allUploads: number[]; // download all files of a mission
	uploads: Upload[];
}
interface OptionProps {
	value: string;
	label: string;
}
  
export const BulkOptionsModal = ({ setOpen, open, missionType, selectedUploads, allUploads, uploads }: Props) => {
	const { t } = useTranslation();

	const [bulkOption, setBulkOption] = useState<string>('download');

	const videoOptions: Array<OptionProps> = [
		{ value: 'download', label: t('Download') },
		{ value: 'exportGpx', label: t('exportGpx') },
		{ value: 'exportAllApprovedGpx', label: t('exportAllApprovedGpx') },
		{ value: 'exportGeoJson', label: t('exportGeoJson') },
		{ value: 'uploadToVaisala', label: t('uploadToVaisala') }
	];

	const photoOptions: Array<OptionProps> = [
		{ value: 'downloadPhotos', label: t('Download') },
		{ value: 'exportCoordinates', label: t('exportCoordinates') }
	];

	// const showGPX = () => {
	// 		const response = await API.getGPX(video.id);
	// 		if (response) {
	// 			let blob = new Blob([response], { type: 'text/xml' });
	// 			saveAs(blob, `GPX-${video.id}.gpx`);
	//     }
	// 	}
	// }  

	const downloadCoordinates = useCallback(async photoIds => {
		settingsStore.updateGlobalLoading(true);
		const response = await API.getExportedCoordinates(photoIds);
		settingsStore.updateGlobalLoading(false);
		return response;
	}, []);

	const downloadPhotos = useCallback(async photoIds => {
		settingsStore.updateGlobalLoading(true);
		const response = await API.getExportedPhotos(photoIds);
		settingsStore.updateGlobalLoading(false);
		return response;
	}, []);

	const downloadVideos = useCallback(async videoIds => {
		settingsStore.updateGlobalLoading(true);
		const response = await API.getExportedVideos(videoIds);
		settingsStore.updateGlobalLoading(false);
		return response;
	}, []);

	const showGPX = useCallback(async (videoId: number) => {
		const response = await API.getGPX(videoId);
		if (response) {
			let blob = new Blob([response], { type: 'text/xml' });
			saveAs(blob, `GPX-${videoId}.gpx`);
			/*  let url = URL.createObjectURL(blob);
      window.open(url);
      URL.revokeObjectURL(url); */

			/* var win = window.open();
      if (win) {
        win.document.write('<iframe src="data:text/xml,'+encodeURIComponent(response) + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
      } */
		}
	}, []);

	const getGPX = useCallback(async (videoId: number): Promise<Blob | null> => {
		const response = await API.getGPX(videoId);
		if (response) {
			let blob = new Blob([response], { type: 'text/xml' });
			return blob;
		} else {
			return null;
		}
	}, []);

	const uploadVideosToVaisala = useCallback(
		async (videoIds: number[]) => {
			const response = await API.uploadVideosToVendor(videoIds);
			if (response.code === 200) {
				toast.success(t('Video(s) set to upload'));
			} else {
				toast.error(t('Videos failed to upload;&nbsp;'));
			}
			videoStore.updateVideos([]);
			setOpen(false);
		},
		[t]
	);

	const handleButtonSubmit = async () => {
		switch (bulkOption) {
			case 'download':
				if (selectedUploads.length > 0) {
					const zipFolder = await downloadVideos(selectedUploads);
					const blob = new Blob([zipFolder], { type: 'application/zip' });
					saveAs(blob, `Videos-${moment().format('YYYY-MM-DD, h:mm:ss')}.zip`);
					// loadedUrls.data.URLs.map(async (video: any) => {
					// 	const response = await API.getVideoFile(video.URL);
					// 	saveAs(response, `Crowdsorsa-Video-${moment().format('YYYY-MM-DD')}-${video.ID}`);
					// });
				}
				// else if (!!selectedUploads.length) {
				// 	const loadedUrl = await downloadVideo(selectedUploads);
				// 	const response = await API.getVideoFile(loadedUrl.data.URLs[0].URL);
				// 	saveAs(response, `Crowdsorsa-Video-${moment().format('YYYY-MM-DD')}-${loadedUrl.data.URLs[0].ID}`);
				// }
				break;
			case 'downloadPhotos':
				if (selectedUploads.length > 0) {
					const zipFolder = await downloadPhotos(selectedUploads);
					const blob = new Blob([zipFolder], { type: 'application/zip' });
					saveAs(blob, `Photos-${moment().format('YYYY-MM-DD, h:mm:ss')}.zip`);

					// zipFolder.data.URLs.map(async (photo: any) => {
					// 	const response = await API.getVideoFile(photo.URL);
					// 	saveAs(response, `Crowdsorsa-Video-${moment().format('YYYY-MM-DD')}-${photo.ID}`);
					// });
				}
				// else if (!!selectedUploads.length) {
				// 	const loadedUrl = await downloadVideo(selectedUploads);
				// 	const response = await API.getVideoFile(loadedUrl.data.URLs[0].URL);
				// 	saveAs(response, `Crowdsorsa-Video-${moment().format('YYYY-MM-DD')}-${loadedUrl.data.URLs[0].ID}`);
				// }
				break;
			case 'exportGpx':
				if (selectedUploads.length > 1) {
					var zip = new JSZip();
					for (const videoId of selectedUploads) {
						const file = await getGPX(videoId);
						if (file) {
							zip.file(`GPX-${moment().format('YYYY-MM-DD')}-${videoId}.gpx`, file);
						}
					}
					zip.generateAsync({ type: 'blob' }).then(function (content: any) {
						saveAs(content, `GPX-${moment().format('YYYY-MM-DD')}.zip`);
					});
				} else if (!!selectedUploads.length) {
					showGPX(selectedUploads[0]);
				}
				break;
			case 'exportAllApprovedGpx':
				const gpxZip = new JSZip();
				const approvedUploads = allUploads.filter(uploadId => {
					for (const upload of uploads) {
						if (upload.id === uploadId && upload.status === 'approved') {
							return true;
						}
					}
					return false;
				});
				for (const fileId of approvedUploads) {
					const file = await getGPX(fileId);
					if (file) {
						gpxZip.file(`GPX-${moment().format('YYYY-MM-DD')}-${fileId}.gpx`, file);
					}
				}
				gpxZip.generateAsync({ type: 'blob' }).then(function (content: any) {
					saveAs(content, `GPX-${moment().format('YYYY-MM-DD')}.zip`);
				});
				break;
			case 'exportGeoJson':
				await getVideoGeojson(selectedUploads);
				break;
			case 'uploadToVaisala':
				uploadVideosToVaisala(selectedUploads);
				break;
			case 'exportCoordinates':
				const zipFolder = await downloadCoordinates(selectedUploads);
				const blob = new Blob([zipFolder], { type: 'application/zip' });
				saveAs(blob, `PhotosMetadata-${moment().format('YYYY-MM-DD, h:mm:ss')}.zip`);
				break;
			default:
				break;
		}
	};

	return (
		<CarchupaDialog setOpen={setOpen} open={open}>
			<Box sx={{ padding: '24px' }}>
				<Typography variant="h2">{t('bulkOptions')}</Typography>
				<FormControl sx={{ padding: '24px 0 0', width: '343px' }}>
					<label htmlFor="options">{t('chooseFormat')}</label>
					<Select labelId="options" defaultValue="download">
						{missionType === MissionType.video
							? videoOptions.map((option, index) => (
									<MenuItem key={option.value} onClick={() => setBulkOption(option.value)} value={option.value} selected={index === 0}>
										{option.label}
									</MenuItem>
							  ))
							: photoOptions.map((option, index) => (
									<MenuItem key={option.value} onClick={() => setBulkOption(option.value)} value={option.value} selected={index === 0}>
										{option.label}
									</MenuItem>
							  ))}
					</Select>
					<Button onClick={handleButtonSubmit} sx={{ marginTop: '24px' }} color="primary" variant="contained" type="submit">
						{t('Execute')}
					</Button>
				</FormControl>
			</Box>
		</CarchupaDialog>
	);
};
