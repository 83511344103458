import { useEffect, useMemo, useState } from 'react';
import styles from './projectMapItemDialog.module.scss';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';

import { MissionType } from '../../videos/Uploads';
import { API } from '../../../../api';
import { UploadData } from '../../../../models/video';
import VideoPlayer from '../../../../components/UX/player/Player';
import authStore from '../../../../stores/authStore';
import { RejectionDialog } from '../RejectionDialog';

interface ProjectMapItemDialogProps {
	isOpen: boolean;
	onClose: () => void;
	// If the type is a photo, the marker has the session associated with it
	// We need to find the upload id of the session
	sessionIdOrSessionUploadId: number;
	projectTypeId: number;
}

const PhotoDialog = () => {};

export const ProjectMapItemDialog = (props: ProjectMapItemDialogProps) => {
	const { isOpen, onClose, sessionIdOrSessionUploadId, projectTypeId } = props;
	const [item, setItem] = useState<UploadData>();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const [isApproveDialogOpen, setIsApproveDialogOpen] = useState<boolean>(false);
	const [isRejectDialogOpen, setIsRejectDialogOpen] = useState<boolean>(false);
	const [isRejectWithPaymentDialogOpen, setIsRejectWithPaymentDialogOpen] = useState<boolean>(false);

	const VideoDialogContent = useMemo(() => {
		return (
			<div className={styles.videoDialogContent}>
				<VideoPlayer videoSrc={item?.url as string} hideVideoOverlay />
			</div>
		);
	}, [sessionIdOrSessionUploadId, isLoading]);

	useEffect(() => {
		if (projectTypeId === MissionType.photo) {
			// Find the upload id of the session
			// then load the picture
		} else {
			setIsLoading(true);
			API.getUpload(sessionIdOrSessionUploadId)
				.then(res => {
					console.log(res.data.upload);
					setItem(res.data.upload);
					console.log(res.data.upload);
				})
				.catch(err => {
					console.error(err);
				})
				.finally(() => {
					setIsLoading(false);
					console.log('hello');
				});
		}
	}, [sessionIdOrSessionUploadId]);

	return (
		<Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
			<DialogContent>
				<div style={{ maxHeight: '562px', maxWidth: '1000px' }}>{item && VideoDialogContent}</div>
			</DialogContent>
			<DialogActions>
				<Button>Accept</Button>
				<Button>Reject with payment</Button>
				<Button>Reject</Button>
				<Button>Close</Button>
			</DialogActions>

			<RejectionDialog isOpen={isRejectDialogOpen} onClose={() => setIsRejectDialogOpen(false)} uploadId={item?.id as number} />
		</Dialog>
	);
};
