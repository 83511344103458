import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Chip, DialogContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { t } from 'i18next';

interface LeaderboardItem {
	userId: number;
	username: string;
	total: number;
}

interface UserListDialogProps {
	open: boolean;
	onClose: () => void;
	projectLeaderboard: LeaderboardItem[];
}

export const LeaderboardDialog: React.FC<any> = ({ open, onClose, projectLeaderboard }) => {
	const visibleRowCount = 10;
	const rowHeight = 40;
	const tableContainerHeight = `${visibleRowCount * rowHeight}px`;

	return (
		<Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
			<DialogTitle>{t('projectLeaderboard.title')}</DialogTitle>
			<DialogContent>
				<TableContainer style={{ height: tableContainerHeight, overflowY: 'auto' }}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>{t('projectLeaderboard.rank')}</TableCell>
								<TableCell>{t('projectLeaderboard.username')}</TableCell>
								<TableCell>{t('projectLeaderboard.total')}</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{projectLeaderboard.map((item: any, index: number) => (
								<TableRow key={item.userId} sx={{ height: `${rowHeight}px` }}>
									<TableCell component="th" scope="row">
										{index + 1}
									</TableCell>
									<TableCell>{item.username}</TableCell>
									<TableCell align="right">
										<Chip sx={{ backgroundColor: '#39A88F', color: 'white', paddingX: '10px' }} label={item.total.toFixed(2)} />
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
		</Dialog>
	);
};
