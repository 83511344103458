import { Box, Divider, Typography, TextField, FormControl, Select, MenuItem, FormHelperText } from '@mui/material';
import { t } from 'i18next';
import { observer } from 'mobx-react';
import { useState, useEffect } from 'react';
import { API } from '../../../api';
import { Countries } from '../../../models/general';
import projectEditorStore from '../../../stores/projectEditorStore';

import { ProjectEditorSection } from './ProjectEditorComponents';

export const ProjectLocationEditor = observer(() => {
	const { project, errors } = projectEditorStore;

	const [countryOptions, setCountryOptions] = useState<Countries[]>([]);

	const onCountryChange = (event: any) => {
		project.countryId = event.target.value;
	};

	const loadCountries = async () => {
		const res = await API.getCountries();
		setCountryOptions(res.data.countries);
	};

	useEffect(() => {
		loadCountries();
	}, []);

	return (
		<ProjectEditorSection title={t('projectEditor.projectLocation.title')} tooltip={t('projectEditor.projectLocation.tooltip')}>
			<Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', alignContent: 'space-between' }}>
				<TextField
					sx={{ flex: '1 1 auto' }}
					label={t('projectEditor.projectLocation.latitude')}
					error={errors['lat']}
					helperText={errors.lat}
					placeholder="54.23"
					value={project.lat}
					onChange={e => (project.lat = e.target.value)}
				/>
				<TextField
					sx={{ flex: '1 1 auto' }}
					label={t('projectEditor.projectLocation.longitude')}
					placeholder="10.31"
					error={errors.lon}
					helperText={errors.lon}
					value={project.lon}
					onChange={e => (project.lon = e.target.value)}
				/>
				<FormControl>
					<Select sx={{ flex: '1 1 auto' }} value={project.countryId ? project.countryId : -1} onChange={onCountryChange} error={errors.countryId}>
						<MenuItem value={-1} disabled>
							Country
						</MenuItem>
						{countryOptions.map(country => (
							<MenuItem value={country.id}>{country.name}</MenuItem>
						))}
					</Select>
					<FormHelperText error={errors.countryId}>{errors.countryId ? t('projectEditor.projectLocation.countryError') : ''}</FormHelperText>
				</FormControl>
			</Box>
		</ProjectEditorSection>
	);
});
