import { Box, Button, Chip, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Statuses } from '../../../models/general';
import { API } from '../../../api';
import videoThumb from '../../../assets/Video.png';
import { useHistory } from 'react-router-dom';

interface InvasiveSpeciesItemProps {
	session: any;
}

export const SessionStatusChip = (props: { sessionStatus: Statuses }) => {
	const { sessionStatus } = props;
	const { t } = useTranslation();

	const statusColorMapping = {
		[Statuses.APPROVED]: { backgroundColor: '#39A88F', label: t('Approved') },
		[Statuses.REVIEW]: { backgroundColor: '#FFA41D', label: t('Pending') },
		[Statuses.PENDING]: { backgroundColor: '#FFA41D', label: t('Pending') },
		[Statuses.ERRORED]: { backgroundColor: '#FE5F55', label: t('Errored') },
		[Statuses.IN_VAISALA]: { backgroundColor: '#2196F3', label: t('In Vaisala') },
		[Statuses.NOT_UPLOADED]: { backgroundColor: '#cccbcb', label: t('Not Uploaded') },
		[Statuses.NOTUPLOADED]: { backgroundColor: '#cccbcb', label: t('Not Uploaded') },
		[Statuses.INCOMPLETE]: { backgroundColor: '#cccbcb', label: t('Incomplete') },
		[Statuses.MIXED]: { backgroundColor: '#cccbcb', label: t('Mixed') },
		[Statuses.REJECTED]: { backgroundColor: '#e74c3c', label: t('Rejected') },
		[Statuses.REJECTED_WITH_PAYMENT]: { backgroundColor: '#39A88F', label: t('Rejected with Payment') },
		['rejectedWithPayment']: { backgroundColor: '#39A88F', label: t('Rejected with Payment') }
	};

	return (
		<div style={{ display: 'flex' }}>
			{Object.keys(Statuses).map(key => {
				const status = Statuses[key as keyof typeof Statuses];
				if (sessionStatus === status) {
					const { backgroundColor, label } = statusColorMapping[status];
					return <Chip key={status} sx={{ backgroundColor, color: 'white' }} label={label} />;
				}
				return null;
			})}
		</div>
	);
};

export const InvasiveSpeciesItem = (props: InvasiveSpeciesItemProps) => {
	const { session } = props;
	const [videoUrl, setVideoUrl] = useState<string>('');
	const [videoId, setVideoId] = useState<number | undefined>(5);
	const history = useHistory();

	useEffect(() => {
		if (session.uploads) {
			API.getUpload(session.uploads[0].id).then(response => {
				if (response.data.upload.url == undefined) {
					setVideoUrl('');
				} else {
					setVideoUrl(response.data.upload.url);
				}
				setVideoId(response.data.upload.id);
			});
		}
	}, [session]);

	const loadVideo = async (videoId: number) => {
		return false;
	};

	return (
		<Paper
			sx={{
				display: 'flex',
				borderRadius: '24px 24px 0px 0px',
				boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
				flexDirection: 'column',
				width: '100%',
				minWidth: '292px',
				position: 'relative',
				overflow: 'hidden'
			}}>
			<Box sx={{ borderRadius: '24px 24px 0px 0px', height: '220px', width: '100%', marginBottom: '15px' }}>
				{videoUrl == '' && <img src={videoThumb} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />}
				{videoUrl != '' && <video src={videoUrl} height={'230px'} controls width={'100%'} poster={videoThumb} style={{ objectFit: 'cover' }} />}
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: '5px', padding: '30px' }}>
				<Box sx={{ paddingBottom: '10px' }}>
					<SessionStatusChip sessionStatus={session.sessionStatus} />
				</Box>

				<span>SessionId: {session.sessionId}</span>
				<span>Project name: {session.projectName}</span>
				<span style={{ paddingBottom: '20px' }}>User: {session.username}</span>

				<Button variant="contained" onClick={() => history.push(`/invasive-session-viewer/${session.sessionId}`)}>
					View session data
				</Button>
			</Box>
		</Paper>
	);
};
