import { observer } from 'mobx-react';
import React from 'react';

import { ProjectEditorSection } from './ProjectEditorComponents';
import { ProjectInvasiveObjectEditor } from './ProjectInvasiveObjectEditor';

export const ProjectInvasiveSpeciesEditor = observer(() => {
	return (
		<ProjectEditorSection title="Invasive species content">
			<ProjectInvasiveObjectEditor />
		</ProjectEditorSection>
	);
});
