import { Box, Button, Checkbox, Chip, Paper, Typography, Grid } from '@mui/material';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import checkedIcon from '../../../assets/checkbox-checked.svg';
import uncheckedIcon from '../../../assets/checkbox-unchecked.svg';
import { Upload } from '../../../models/general';
import VideoModalReject from '../../../routes/core/video/VideoModalReject';
import videoStore from '../../../stores/videoStore';
import CarchupaDialog from '../../UX/dialog/CarchupaDialog';
import VideoPlayer from '../../UX/player/Player';
import { LocationStateProps } from '../back-button/BackButton';
import styles from './VideoItem.module.scss';
import { ApproveRejectBtn } from '../approve-reject-btn';
import VideoModalApprove from '../../../routes/core/video/VideoModalApprove';
import { usePatchUploadsQuery } from '../../../queries/data-queries/usePatchUploadsQuery';
import { useGetUpload } from '../../../queries/data-queries/useGetUploadQuery';
import PhotoThumbnail from '../../../assets/photo-thumbnail.svg';
import checkedTagIcon from '../../../assets/checked-tag-icon.svg';
import xRedIcon from '../../../assets/x-red-icon.svg';
import { ROUTES } from '../../../parameters/routes';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UploadsDataMainQueryKey } from '../../../queries/data-queries/useGetUploadsData';
import SelectRejectOption from '../../UX/select-reject-option/SelectRejectOption';
import authStore from '../../../stores/authStore';
import { MissionType } from '../../../routes/core/videos/Uploads';

interface VideoItemProps {
	title?: string;
	parentStyle?: string;
	childStyle?: string;
	video?: Upload;
	backLink?: LocationStateProps;
	selectMode?: boolean;
	handleSelected?: (isChecked: boolean, id: number) => void;
	allSelected?: boolean;
	isPhotoMission?: boolean;
	uploadsParams?: any;
	photoUrl?: string;
}

const VideoItem = ({
	title,
	childStyle,
	video,
	backLink,
	selectMode,
	handleSelected,
	allSelected,
	isPhotoMission,
	uploadsParams,
	photoUrl
}: VideoItemProps) => {
	const { t } = useTranslation();
	const { selectedVideos } = videoStore;
	const [data, setData] = useState({ message: '' });
	const [open, setOpen] = useState(false);
	const [openApproveModal, setOpenApproveModal] = useState(false);
	const [checked, setChecked] = useState(false);
	const [rejectionReason, setRejectionReason] = useState<number>(0);
	const [rejectionWithPayment, setRejectionWithPayment] = useState(false);
	const [openSelectPaymentStatus, setOpenSelectPaymentStatus] = useState(false);
	const { adminRole } = authStore;

	const projectTypeId = video?.projectTypeId;

	/////////////////////////////////////////////// REACT-QUERY ////////////////////////////////////////////////////////
	const { mutate } = usePatchUploadsQuery(UploadsDataMainQueryKey.uploadsDataQuery, uploadsParams);
	const { upload: uploadedVideo, isError } = useGetUpload(video?.id || video?.uploads![0]?.id!);
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const videoUrl = uploadedVideo?.url;

	useEffect(() => {
		allSelected ? setChecked(true) : setChecked(false);
	}, [allSelected]);

	const videoStatusColors: Record<string, string> = {
		rejected: '#e74c3c',
		pending: '#FFA41D',
		approved: '#39A88F',
		rejectedWithPayment: '#39A88F',
		errored: '#FE5F55',
		inVaisala: '#398DA8'
	};

	const loadVideo = async (videoId: number) => {
		if (isError) {
			toast.error(t('failedFetchingVideos'));
		}
	};
	const locationURL = useLocation();

	// ROUTES.image

	const videoStatus = video?.status;

	const handleDataChange = useCallback(({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
		setData(d => ({ ...d, [currentTarget.id]: currentTarget.value }));
	}, []);

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const customRejectionReason = data.message;
		const rejectionReasonId = rejectionReason;

		if (video) {
			if (data.message !== t('bad_response') && data.message) {
				mutate({ id: video.id, status: 'reject', bodyParams: { customRejectionReason: customRejectionReason } });
			} else {
				mutate({ id: video.id, status: 'reject', bodyParams: { rejectionReasonId: rejectionReasonId } });
			}
		}
		setOpenSelectPaymentStatus(false);
	};

	const handleApproveSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (video) {
			mutate({ id: video.id, status: 'approve' });
		}
	};

	const handleRejectOptionClick = async (rejectWithPayment: boolean, changedPaymentAmount?: number) => {
		if (video) {
			if (changedPaymentAmount) {
				mutate({
					id: video.id,
					status: rejectWithPayment ? 'rejectWithPayment' : 'reject',
					bodyParams: { customRejectionReason: 'rejected with payment', changedPaymentAmount: changedPaymentAmount }
				});
			} else {
				mutate({
					id: video.id,
					status: rejectWithPayment ? 'rejectWithPayment' : 'reject',
					bodyParams: { customRejectionReason: 'rejected with payment' }
				});
			}
		}
		setOpenSelectPaymentStatus(false);
	};

	const getLinkTo = () => {
		if (isPhotoMission || projectTypeId == MissionType.photo) return `${ROUTES.createImageUrl(video?.id ?? 0)}`;
		else if (projectTypeId == MissionType.video || projectTypeId == MissionType.custom)
			return `${process.env.PUBLIC_URL}/video/${video?.id}?prev=${locationURL.pathname.slice(1)}`;
		else if (projectTypeId == MissionType.invasiveSpecies)
			return `${process.env.PUBLIC_URL}/session-viewer/${video?.sessionId}?prev=${locationURL.pathname.slice(1)}`;
		else return '';
	};

	return (
		<Paper
			sx={{
				borderRadius: '24px',
				overflow: 'hidden',
				boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.07)',
				minWidth: '292px',
				width: '100%',
				height: '540px',
				position: 'relative'
			}}
			className={`${selectedVideos.includes(video?.id ?? 0) ? styles.selectedVideo : ''}`}>
			{selectMode && handleSelected && (
				<Checkbox
					sx={{ position: 'absolute', top: '10px', right: '10px', svg: { borderRadius: '50%' }, zIndex: '100' }}
					icon={<img src={uncheckedIcon} alt="checked" />}
					checkedIcon={<img src={checkedIcon} alt="unchecked" />}
					checked={checked}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						setChecked(e.target.checked);
						handleSelected(checked, video?.id!);
					}}
				/>
			)}
			{!!title ? <Typography variant="h6">{title}</Typography> : null}
			{(projectTypeId == MissionType.video || projectTypeId == MissionType.custom) && (
				<Box className={styles.videoPlayer}>
					{/* {video?.id ?? 0 ? (
						<VideoPlayer hideVideoOverlay videoId={video?.id} loadVideo={loadVideo} videoSrc={videoUrl || (video?.fileName ?? '')} />
					) : null} */}

					<VideoPlayer hideVideoOverlay videoId={video?.id} loadVideo={loadVideo} videoSrc={videoUrl || (video?.fileName ?? '')} />
				</Box>
			)}

			{projectTypeId === MissionType.invasiveSpecies && (
				<Box className={styles.videoPlayer}>
					{/* {video?.id ?? 0 ? (
						<VideoPlayer hideVideoOverlay videoId={video?.id} loadVideo={loadVideo} videoSrc={videoUrl || (video?.fileName ?? '')} />
					) : null} */}

					<VideoPlayer
						hideVideoOverlay
						videoId={video?.uploads![0].id}
						loadVideo={loadVideo}
						videoSrc={videoUrl || (video?.uploads![0].fileName ?? '')}
					/>
				</Box>
			)}

			{isPhotoMission && (
				<Box sx={{ display: 'flex', width: '100%', height: '200px' }}>
					<Box
						sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
						component="img"
						src={video?.status === 'notUploaded' ? PhotoThumbnail : photoUrl}
						alt="photo"
					/>
				</Box>
			)}

			<Grid container direction={'column'} className={`${styles.content} ${childStyle}`}>
				<Box className={styles.status}>
					<Chip sx={{ backgroundColor: videoStatus ? videoStatusColors[videoStatus] : '#FE5F55', color: 'white' }} label={videoStatus} />
					{video?.gps === 'ok' && <Chip variant="outlined" color="primary" label={'GPS'} icon={<img src={checkedTagIcon} alt="checked icon" />} />}
					{video?.gps === 'fail' && <Chip variant="outlined" color="error" label={'GPS'} icon={<img src={xRedIcon} alt="x red icon" />} />}
				</Box>
				<Box className={styles.videoInfo}>
					{!isPhotoMission && (
						<Box component="span" className={styles.fileName}>
							{video?.fileName ?? ''}
						</Box>
					)}
					<Box component="span" className={styles.fileName}>
						{t('uploadCreatedAt')} {video?.createdAt && moment.unix(+video?.createdAt).format('HH:mm:ss, Do MMM YYYY')}
					</Box>
					{(isPhotoMission || projectTypeId === MissionType.photo) && (
						<Box component="span">
							{t('objectID')} {video?.id}
						</Box>
					)}

					{(projectTypeId === MissionType.video || projectTypeId === MissionType.custom) && (
						<Box component="span">
							{t('videoID')} {video?.id}
						</Box>
					)}

					{projectTypeId === MissionType.invasiveSpecies && (
						<Box component="span">
							{t('Session ID')}: {video?.sessionId}
						</Box>
					)}

					<Box component="span">
						{t('missionUser')} {video?.username}
					</Box>
					<Box component="span">
						{t('projectName')} {video?.projectName}
					</Box>
				</Box>
				<Link style={{ textDecoration: 'none' }} to={getLinkTo()}>
					<Button variant="contained" color="primary" sx={{ width: '100%' }}>
						{t('seeFile')}
					</Button>
				</Link>

				{adminRole === 'superadmin' || adminRole === 'admin' ? (
					<Box className={styles.videoActions}>
						<ApproveRejectBtn
							status={video?.status}
							onClick={() => {
								setOpenSelectPaymentStatus(!openSelectPaymentStatus);
							}}
							kind="reject"
						/>
						<Box className={styles.line}></Box>
						<ApproveRejectBtn
							status={video?.status}
							onClick={() => {
								setOpenApproveModal(true);
								setData({ message: t('ok_response') });
							}}
							kind="approve"
						/>
						{openSelectPaymentStatus && (
							<SelectRejectOption
								openRejectModal={open}
								handleClick={handleRejectOptionClick}
								setOpenRejectModal={setOpen}
								setRejectionWithPayment={setRejectionWithPayment}
							/>
						)}
					</Box>
				) : null}
			</Grid>
			<CarchupaDialog setOpen={setOpen} open={open}>
				<VideoModalReject
					data={data}
					setData={setData}
					setOpen={setOpen}
					handleSubmit={handleSubmit}
					handleDataChange={handleDataChange}
					setRejectionReasonState={setRejectionReason}
					rejectionReasonState={rejectionReason}
				/>
			</CarchupaDialog>
			<CarchupaDialog setOpen={setOpenApproveModal} open={openApproveModal}>
				<VideoModalApprove setOpen={setOpenApproveModal} handleSubmit={handleApproveSubmit} />
			</CarchupaDialog>
		</Paper>
	);
};

export default inject('videoStore', 'messageStore')(observer(VideoItem));
