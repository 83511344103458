import { observable, computed, action, decorate } from 'mobx';
import { PaymentData } from '../models/payment';

export class PaymentsStore {
	payments = observable<PaymentData>([]);
	selectedPaymentIds = observable<number>([]);

	updatePayments(newPayments: PaymentData[]) {
		this.payments.replace(newPayments);
	}

	get getPayments(): PaymentData[] {
		return this.payments;
	}

	get getSelectedPaymentIds() {
		return this.selectedPaymentIds;
	}

	updateSelectedPaymentIds(selectedPaymentIds: number[]) {
		this.selectedPaymentIds.replace(selectedPaymentIds);
	}
}

decorate(PaymentsStore, {
	payments: observable,
	selectedPaymentIds: observable,

	updatePayments: action,
	updateSelectedPaymentIds: action,

	getPayments: computed,
	getSelectedPaymentIds: computed
});

export default new PaymentsStore();
