import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { Box, Divider, Typography, TextField, FormControl, Select, MenuItem, FormHelperText, Button } from '@mui/material';
import { t } from 'i18next';
import { InfoTooltip } from './InfoTooltip';
import projectEditorStore, { ProjectCardType } from '../../../stores/projectEditorStore';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import { useDropzone } from 'react-dropzone';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import BackupIcon from '@mui/icons-material/Backup';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ClickableChip, ProjectEditorSection } from './ProjectEditorComponents';
import { API } from '../../../api';

const FileDropzone = observer(() => {
	const { project } = projectEditorStore;

	const onDrop = (file: any) => {
		project.csvFile = file[0];
	};

	const onFileDelete = (e: React.SyntheticEvent) => {
		e.stopPropagation();
		project.csvFile = undefined;
	};

	const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false, accept: '.csv' });

	return (
		<div>
			<Box>
				<label>Collectables CSV uploader</label>
				<a
					style={{ textDecoration: 'none', color: '#139074', paddingTop: '10px' }}
					download="example.csv"
					href="data:text/csv;base64,bGF0aXR1ZGUsbG9uZ2l0dWRlLHZhbHVlLG1hcmtlcklkCjYxLjQ5NjE2NCwgMjMuNzc3MDQwLDMsMQo=">
					{` (${t('editProject.sampleCSVLinkText')})`}
				</a>
			</Box>
			{!project.csvFile && (
				<div>
					<div
						{...getRootProps()}
						style={{
							background: '#F9F8F2',
							border: '1px dashed #A4A4A4',
							boxSizing: 'border-box',
							borderRadius: '8px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							padding: '18px',
							cursor: 'pointer'
						}}>
						<input {...getInputProps()} />
						<SummarizeOutlinedIcon color="primary" fontSize="large" />
						<span>Click to upload, or drag and drop CSV files here</span>
					</div>
				</div>
			)}

			{project.csvFile && (
				<div>
					<div
						{...getRootProps()}
						style={{
							background: '#F9F8F2',
							border: '1px dashed #A4A4A4',
							boxSizing: 'border-box',
							borderRadius: '8px',
							display: 'flex',

							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							padding: '18px',
							cursor: 'pointer'
						}}>
						<input {...getInputProps()} />
						<Box sx={{ display: 'flex', padding: '12px', alignItems: 'center', gap: '15px' }}>
							<Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
								<SummarizeOutlinedIcon color="primary" />
								{project.csvFile.name}
							</Box>
							<Button onClick={onFileDelete} color="error" startIcon={<DeleteOutlineIcon />}>
								Delete
							</Button>
						</Box>
					</div>
				</div>
			)}
		</div>
	);
});

const VehicleTypePicker = observer(() => {
	const { project } = projectEditorStore;
	const [vehicleTypes, setVehicleTypes] = React.useState<any[]>([]);

	useEffect(() => {
		API.getVehicles()
			.then(res => {
				setVehicleTypes(res.data.vehicles);
			})
			.catch(err => {
				console.error(err);
			});
	}, []);

	const handleChipClick = (value: any) => {
		project.vehicleId = value;
	};

	return (
		<div>
			<Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
				<div>
					<Divider>
						<Typography variant="caption">{t('projectEditor.projectVideo.vehicleTypeTitle')}</Typography>
						<InfoTooltip title={t('projectEditor.projectVideo.vehicleTypeTooltip')} />
					</Divider>
				</div>

				<Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'center' }}>
					{vehicleTypes.length > 0 &&
						vehicleTypes.map((vehicle: any) => (
							<ClickableChip
								value={vehicle.id}
								onClick={() => handleChipClick(vehicle.id)}
								isDisabled={false}
								selected={vehicle.id == project.vehicleId}
								text={vehicle.name}
							/>
						))}
				</Box>
			</Box>
		</div>
	);
});

export const ProjectVideoEditor = observer(() => {
	return (
		<ProjectEditorSection title={t('projectEditor.projectVideo.title')} tooltip={t('projectEditor.projectVideo.tooltip')}>
			<FileDropzone />
			<VehicleTypePicker />
		</ProjectEditorSection>
	);
});
