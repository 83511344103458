import { Button, Typography, Box, Chip } from '@mui/material';
import React, { ChangeEvent, FormEvent, useCallback, useContext, useState } from 'react';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import styles from './ImageDetailPage.module.scss';
import arrowLeft from '../../../assets/arrow-left.svg';
import { Link, useHistory, useParams } from 'react-router-dom';
import checkedTagIcon from '../../../assets/checked-tag-icon.svg';
import xRedIcon from '../../../assets/x-red-icon.svg';

import { useDate } from '../../../utils';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import { ApproveRejectBtn } from '../../../components/UI/approve-reject-btn';
import { ModalContext } from '../../../context/modalContext';
import { ImageModal } from './ImageModal';
import { useGetUpload } from '../../../queries/data-queries/useGetUploadQuery';
import CarchupaDialog from '../../../components/UX/dialog/CarchupaDialog';
import VideoModalReject from '../video/VideoModalReject';
import { usePatchUploadsQuery } from '../../../queries/data-queries/usePatchUploadsQuery';
import { UploadsDataMainQueryKey } from '../../../queries/data-queries/useGetUploadsData';
import VideoModalApprove from '../video/VideoModalApprove';
import { t } from 'i18next';
import DownloadIcon from '../../../assets/download-icon.svg';
import { CacheUploadsParamsMainQuery, useCacheUploadsParamsQuery } from '../../../queries/view-queries/useCacheUploadsParamsQuery';
import Map from '../../../components/UX/map/Map';
import settingsStore from '../../../stores/settingsStore';
import { API } from '../../../api';
import moment from 'moment';
import saveAs from 'file-saver';
import { toast } from 'react-toastify';
import SelectRejectOption from '../../../components/UX/select-reject-option/SelectRejectOption';

export const ImageDetailPage = (): JSX.Element => {
	const history = useHistory();
	const { imageId } = useParams<{ imageId: string }>();
	const { handleModal } = useContext(ModalContext);
	const [open, setOpen] = useState(false);
	const [data, setData] = useState({ message: '' });
	const [openApproveModal, setOpenApproveModal] = useState(false);
	const [rejectionReason, setRejectionReason] = useState<number>(0);
	const [rejectionWithPayment, setRejectionWithPayment] = useState(false);
	const [openSelectPaymentStatus, setOpenSelectPaymentStatus] = useState(false);

	/////////////////////////////////////////////// REACT-QUERY ////////////////////////////////////////////////////////
	const { upload: objectData } = useGetUpload(imageId);
	const { data: uploadsParams } = useCacheUploadsParamsQuery(CacheUploadsParamsMainQuery.uploadPageParamsQueryKey);
	const { mutate } = usePatchUploadsQuery(UploadsDataMainQueryKey.uploadsDataQuery, uploadsParams);

	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const downloadMetadata = useCallback(async (objectID: number) => {
		settingsStore.updateGlobalLoading(true);

		try {
			const response = await API.getTxt(objectID);
			let blob = new Blob([response], { type: 'text/plain' });
			saveAs(blob, `Crowdsorsa-TXT-${moment().format('YYYY-MM-DD')}.txt`);
		} catch (err: any) {
			err.data.message ? toast.error(err.data.message) : toast.error('Something went wrong');
		}
		settingsStore.updateGlobalLoading(false);

		// if (response) {
		// 	saveAs(response, `Crowdcupa-Video-${moment().format('YYYY-MM-DD')}-${getLink.data.URLs[0].ID}`);
		// }
	}, []);

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const customRejectionReason = data.message;
		const rejectionReasonId = rejectionReason;

		if (objectData) {
			if (data.message !== t('bad_response') && data.message) {
				mutate({ id: objectData.id, status: 'reject', bodyParams: { customRejectionReason: customRejectionReason } });
			} else {
				mutate({ id: objectData.id, status: 'reject', bodyParams: { rejectionReasonId: rejectionReasonId } });
			}
		}
		setOpenSelectPaymentStatus(false);
	};

	const handleApproveSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (objectData) {
			mutate({ id: objectData.id, status: 'approve' });
		}
	};

	const handleRejectOptionClick = async (rejectWithPayment: boolean, changedPaymentAmount?: number) => {
		if (objectData) {
			if (changedPaymentAmount) {
				mutate({
					id: objectData.id,
					status: rejectWithPayment ? 'rejectWithPayment' : 'reject',
					bodyParams: { customRejectionReason: 'rejected with payment', changedPaymentAmount: changedPaymentAmount }
				});
			} else {
				mutate({
					id: objectData.id,
					status: rejectWithPayment ? 'rejectWithPayment' : 'reject',
					bodyParams: { customRejectionReason: 'rejected with payment' }
				});
			}
		}
		setOpenSelectPaymentStatus(false);
	};

	const handleDataChange = ({ currentTarget }: ChangeEvent<HTMLInputElement>) => {
		setData(d => ({ ...d, [currentTarget.id]: currentTarget.value }));
	};
	const [copied, setCopied] = useState(false);

	const handleCopyClick = () => {
		// Create a temporary input element
		const input = document.createElement('input');
		input.setAttribute('value', video.id);
		document.body.appendChild(input);

		// Select the text in the input element
		input.select();
		document.execCommand('copy');
		document.body.removeChild(input);

		// Set the copied state to true
		setCopied(true);

		// Reset the copied state after a short delay
		setTimeout(() => {
			setCopied(false);
		}, 2000);
	};

	const getPreposition = () => {
		switch (objectData?.status) {
			case 'approved':
				return (
					<Typography variant="body2">
						Approved by:{' '}
						<b>
							{objectData.updatedByFirstName} {objectData.updatedByLastName}
						</b>
					</Typography>
				);
			case 'rejected':
				return (
					<Typography variant="body2">
						Rejected by:{' '}
						<b>
							{objectData.updatedByFirstName} {objectData.updatedByLastName}
						</b>
					</Typography>
				);

			case 'rejectedWithPayment':
				return (
					<Typography variant="body2">
						Rejected with payment by:{' '}
						<b>
							{objectData.updatedByFirstName} {objectData.updatedByLastName}
						</b>
					</Typography>
				);

			default:
				'';
		}

		if (objectData?.updateByUserId) return 'N/A';
	};
	return (
		<SimpleLayout passedStyles={styles.root}>
			<Box className={styles.backButton}>
				<Button onClick={history.goBack} sx={{ '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' } }} variant="text" color="primary">
					<img src={arrowLeft} alt={'arrow left'} />
					{t('backToUploads')}
				</Button>
			</Box>
			{objectData && (
				<Box className={styles.mainContainer}>
					<Box className={styles.generalInfo}>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Typography variant="h1">{t('objectDetails')}</Typography>
							<Box sx={{ marginLeft: '12px' }}>
								{objectData.status === 'pending' && <Chip sx={{ backgroundColor: '#FFA41D', color: 'white' }} label={'Pending'}></Chip>}
								{objectData.status === 'approved' && <Chip sx={{ backgroundColor: '#39A88F', color: 'white' }} label={'Approved'}></Chip>}
								{objectData.status === 'rejectedWithPayment' && (
									<Chip sx={{ backgroundColor: '#39A88F', color: 'white' }} label={'Rejected With Payment'}></Chip>
								)}
								{objectData.status === 'errored' && <Chip sx={{ backgroundColor: '#FE5F55', color: 'white' }} label={'Errored'}></Chip>}
								{objectData.status === 'rejected' && <Chip sx={{ backgroundColor: '#e74c3c', color: 'white' }} label={'Rejected'}></Chip>}
								{objectData.status === 'notUploaded' && <Chip sx={{ backgroundColor: '#cccbcb', color: 'white' }} label={'Not Uploaded'}></Chip>}
								{objectData?.gps === 'ok' && (
									<Chip variant="outlined" color="primary" label={'GPS'} icon={<img src={checkedTagIcon} alt="checked icon" />} />
								)}
								{objectData?.gps === 'fail' && <Chip variant="outlined" color="error" label={'GPS'} icon={<img src={xRedIcon} alt="x red icon" />} />}
							</Box>
						</Box>
						<Box
							sx={{
								backgroundColor: 'secondary.100',
								maxWidth: '1000px', // Adjust the width to 'auto' to fit the content
								borderRadius: '24px',
								padding: '6px',
								boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.07)',
								marginY: '24px'
							}}>
							<Box
								display="flex"
								sx={{
									'@media (max-width: 719px)': {
										flexDirection: 'column'
									}
								}}>
								<Box flex="1" p={2} sx={{ maxWidth: '300px' }}>
									<Typography variant="h6">Upload data</Typography>
									<Typography variant="body2" onClick={handleCopyClick} style={{ cursor: 'pointer' }}>
										Upload ID: <b>{objectData.id}</b>
										{copied && <span style={{ marginLeft: '8px' }}>Copied!</span>}
									</Typography>

									<Typography variant="body2">
										Project Name:{' '}
										<Link style={{ textDecoration: 'none' }} to={`${process.env.PUBLIC_URL}/project/${objectData.projectId}`}>
											<Button variant="text" size="small">
												{objectData.projectName}
											</Button>
										</Link>
									</Typography>
									<Typography variant="body2">{getPreposition()}</Typography>
									<Typography variant="body2">
										Uploaded by:{' '}
										<b>
											<Link style={{ textDecoration: 'none' }} to={`${process.env.PUBLIC_URL}/profile/${objectData.uploadedByUserId}`}>
												<Button variant="text" size="small">
													{objectData.uploadedByUsername}
												</Button>
											</Link>
										</b>
									</Typography>
									<Typography variant="body2">
										Uploaded: <b>{moment.unix(+objectData?.createdAt).format('Do MMM YYYY, HH:mm:ss')} </b>
									</Typography>

									<Button
										variant="contained"
										color="primary"
										sx={{ marginTop: '12px' }}
										onClick={() => {
											downloadMetadata(objectData.id);
										}}>
										<Box className={styles.actionButtons}>
											<Box component="img" src={DownloadIcon} alt="Download Icon" />
											<Box component="span">{t('DOWNLOADButton')}</Box>
										</Box>
									</Button>
								</Box>
								<Box flex="1" p={2}>
									<Box sx={{ position: 'relative', height: '100%', minHeight: '350px', width: '100%' }}>
										<Map projects={objectData} projectID={objectData.id} objectData={objectData} />
									</Box>
								</Box>
							</Box>
						</Box>
						<Box sx={{ marginTop: '40px;' }}>
							<CarchupaPaper type="row" parentStyle={styles.paper}>
								<Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '24px' }}>
									{objectData.photos?.map((photo: string, index: number) => {
										return (
											<Box
												onClick={() => {
													handleModal(<ImageModal image={photo} />);
												}}
												key={index}
												component="img"
												src={photo}
												sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
											/>
										);
									})}
								</Box>

								<Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', position: 'relative' }}>
									<ApproveRejectBtn
										status={objectData?.status}
										onClick={() => {
											setOpenSelectPaymentStatus(!openSelectPaymentStatus);
										}}
										kind="reject"
										imageDetail={true}
									/>
									<ApproveRejectBtn
										status={objectData?.status}
										onClick={() => {
											setOpenApproveModal(true);
											setData({ message: t('ok_response') });
										}}
										kind="approve"
										imageDetail={true}
									/>
									<Box sx={{ position: 'absolute', minWidth: '200px', top: '40px' }}>
										{openSelectPaymentStatus && (
											<SelectRejectOption
												openRejectModal={open}
												handleClick={handleRejectOptionClick}
												setOpenRejectModal={setOpen}
												setRejectionWithPayment={setRejectionWithPayment}
											/>
										)}
									</Box>
								</Box>
							</CarchupaPaper>
						</Box>
						<CarchupaDialog setOpen={setOpen} open={open}>
							<VideoModalReject
								data={data}
								setData={setData}
								setOpen={setOpen}
								handleSubmit={handleSubmit}
								handleDataChange={handleDataChange}
								setRejectionReasonState={setRejectionReason}
								rejectionReasonState={rejectionReason}
							/>
						</CarchupaDialog>
						<CarchupaDialog setOpen={setOpenApproveModal} open={openApproveModal}>
							<VideoModalApprove setOpen={setOpenApproveModal} handleSubmit={handleApproveSubmit} />
						</CarchupaDialog>
					</Box>
				</Box>
			)}
		</SimpleLayout>
	);
};
