import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router-dom';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';

import styles from './GlobalMap.module.scss';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import { API } from '../../../api';
import messageStore from '../../../stores/messageStore';
import settingsStore from '../../../stores/settingsStore';
import { Map, GoogleApiWrapper, Polyline, Marker } from 'google-maps-react';
import { groupBy } from '../../../logic/group-by';
import { ROUTES } from '../../../parameters/routes';
import authStore from '../../../stores/authStore';
import SelectList, { ObjectStatusOptions } from '../../../components/UX/select-list/SelectList';
import { MainQueryKey, QueryKey, useSelectListQuery } from '../../../queries/view-queries/useSelectListQuery';
import { useGetProjectListQuery } from '../../../queries/data-queries/useGetProjectListQuery';
import { useGetPhotoExportMarkers } from '../../../queries/data-queries/useGetPhotoExportMarkersQuery';
import benchObject from '../../../assets/bench-object.svg';
import { Box, InputLabel } from '@mui/material';
import distributionCabinet from '../../../assets/distribution-cabinet.svg';
import drainage from '../../../assets/drainage.svg';
import manholeCover from '../../../assets/manhole-cover.svg';
import roadblock from '../../../assets/roadblock.svg';
import transformer from '../../../assets/transformer.svg';
import trashcan from '../../../assets/trashcan.svg';
import brokenSoundBeacon from '../../../assets/broken_sound_beacon.svg';
import workingSoundBeacon from '../../../assets/working_sound_beacon.svg';
import pedestrianPath from '../../../assets/pedestrian_path.svg';
import droppedCurb from '../../../assets/dropped_curb.svg';
import canadaGoldenrod from '../../../assets/canada_goldenrod.svg';
import falseSpiraea from '../../../assets/false_spiraea.svg';
import culvert from '../../../assets/culvert.svg';
import spanishSlug from '../../../assets/spanish_slug.svg';
import { MapPolylineCoords, WaypointsData } from '../../../models/general';
import { t } from 'i18next';
import { DateRangePickerInput } from '../../../components/UX/date-range-picker/DateRangePickerInput';
import { DatePickerMainQueryKey, DatePickerQueryKey, useDatePickerQuery } from '../../../queries/view-queries/useDatePickerQuery';

interface MapperProps {
	google: any;
	waypointsCoords: MapPolylineCoords[];
	callback: (videoId: number) => void;
	markers: {
		ID: number;
		photoID: number;
		latitude: number;
		longitude: number;
		type: number;
	}[];
}

const Mapper = (props: MapperProps) => {
	const { google, callback, markers, waypointsCoords } = props;
	const grouped: any = groupBy(waypointsCoords, (item: MapPolylineCoords) => item.videoID);
	const keys = Array.from(grouped.keys());

	const onPolylineClick = (videoId: number) => {
		callback(videoId);
		window.open(ROUTES.createVideoUrl(videoId), '_blank');
	};

	const renderObjectMarkers = markers?.map((marker: any, index: number) => {
		return (
			<Marker
				key={index}
				position={{ lat: Number(marker.latitude), lng: Number(marker.longitude) }}
				icon={{
					url:
						(marker.type === 1 && manholeCover) ||
						(marker.type === 2 && benchObject) ||
						(marker.type === 3 && distributionCabinet) ||
						(marker.type === 4 && roadblock) ||
						(marker.type === 5 && trashcan) ||
						(marker.type === 6 && drainage) ||
						(marker.type === 7 && transformer) ||
						(marker.type === 21 && brokenSoundBeacon) ||
						(marker.type === 22 && workingSoundBeacon) ||
						(marker.type === 23 && pedestrianPath) ||
						(marker.type === 24 && droppedCurb) ||
						(marker.type === 27 && culvert) ||
						(marker.type === 28 && spanishSlug) ||
						'',
					scaledSize: new google.maps.Size(40, 40)
				}}
				onClick={() => {
					window.open(ROUTES.createImageUrl(marker.photoID), '_blank');
				}}
			/>
		);
	});

	const renderVideoRute = (coords: MapPolylineCoords[], index: number, color: string) => {
		return (
			<Polyline key={index} path={coords} strokeColor={color} strokeOpacity={1} strokeWeight={6} onClick={() => onPolylineClick(coords[0].videoID)} />
		);
	};

	const renderPolylines = keys.map((key, index) => {
		const item = grouped.get(key);
		const color = item[0].isApproved == 1 ? '#39A88F' : item[0].isRejected == 1 ? '#fc2c03' : item[0].isPending == 1 ? '#fc9803' : 'gray';

		return renderVideoRute(item, index, color);
	});

	return (!!markers?.length || !!waypointsCoords.length) && google ? (
		<Map
			google={google}
			initialCenter={
				(!!markers?.length && {
					lat: markers[0].latitude,
					lng: markers[0].longitude
				}) ||
				waypointsCoords[0]
			}>
			{renderPolylines}
			{renderObjectMarkers}
		</Map>
	) : null;
};

interface GlobalMapProps extends RouteComponentProps {
	google: any;
}

const GlobalMap = (props: GlobalMapProps) => {
	const { t } = useTranslation();

	/////////////////////////////////////////////// REACT-QUERY ////////////////////////////////////////////////////////
	const { getSelectedOption: getSelectedOptionForMission } = useSelectListQuery(MainQueryKey.mission, QueryKey.globalMap);
	const { getSelectedOption: getSelectedOptionForObjectStatus } = useSelectListQuery(MainQueryKey.objectStatus, QueryKey.globalMap);
	const objectStatus = getSelectedOptionForObjectStatus(MainQueryKey.objectStatus, QueryKey.globalMap);

	const { dates } = useDatePickerQuery(DatePickerMainQueryKey.dateRangePickerInput, DatePickerQueryKey.videoCreationDate); // map view video filter by date NOT FINISHED

	const selectedMission = getSelectedOptionForMission(MainQueryKey.mission, QueryKey.globalMap);
	const { data: projectListData } = useGetProjectListQuery({ fields: 'id,name' });

	const { markers } = useGetPhotoExportMarkers({
		projectId: selectedMission === 'all' ? undefined : selectedMission,
		offset: 0,
		limit: 20,
		status: objectStatus === 'all' ? undefined : objectStatus
	});

	// map view video filter by date NOT FINISHED
	// createdAt = creation date of a waypoint, stored in the createdAt property of the WaypointsData interface
	// dates = date range selected by the user, and
	// dateFrom and dateTo = the start and end dates of that range
	type DateRange<T> = [T | null, T | null];

	const isDateInRange = (createdAt: Date, dates: DateRange<Date> | null): boolean => {
		if (!dates) {
			return true; // If no date range is selected, consider the date within range
		}
		// const [startDate, endDate] = dates;
		const [dateFrom, dateTo] = dates; // Use the dates variable to get the selected date range

		if (dateFrom && dateTo) {
			return createdAt >= dateFrom && createdAt <= dateTo;
		}
		return dateFrom && dateTo ? createdAt >= dateFrom && createdAt <= dateTo : true;
	};

	const [waypoints, setwaypoints] = useState<WaypointsData[]>([]);
	const [waypointsCoords, setWaypointsCoords] = useState<MapPolylineCoords[]>([]);

	//-------ORIGINAL START
	// const fetchData = (currentPage: number, waypoints: WaypointsData[]) => {
	// 	API.getWaypoints({
	// 		videoIDs: [],
	// 		projectID: selectedMission === 'all' ? undefined : selectedMission,
	// 		offset: (currentPage - 1) * 10,
	// 		limit: 10
	// 	})
	// 		.then(response => {
	// 			if (!!response.data.waypoints) {
	// 				const newPoints = [...waypoints, ...response.data.waypoints];
	// 				setwaypoints(newPoints);
	// 				const pageCount = response.data.videosNumber.videosNumber || 0;
	// 				// if there is more pages, load more
	// 				// manual lazy load
	// 				if (currentPage < Math.ceil(pageCount / 10)) {
	// 					fetchData(currentPage + 1, newPoints);
	// 				} else {
	// 					settingsStore.updateGlobalLoading(false);
	// 				}
	// 			} else {
	// 				setwaypoints([]);
	// 				settingsStore.updateGlobalLoading(false);
	// 				messageStore.snackbar({
	// 					message: t('Project does not have waypoints'),
	// 					type: 'error'
	// 				});
	// 			}
	// 		})
	// 		.catch(err => {
	// 			setwaypoints([]);
	// 			settingsStore.updateGlobalLoading(false);
	// 			messageStore.snackbar({
	// 				message: t('Failed fetching waypoints or no videos'),
	// 				type: 'error'
	// 			});
	// 		});
	// };
	//-------ORIGINAL END

	// map view video filter by date NOT FINISHED
	const fetchData = (currentPage: number, waypoints: WaypointsData[]) => {
		API.getWaypoints({
			videoIDs: [],
			projectID: selectedMission === 'all' ? undefined : selectedMission,
			offset: (currentPage - 1) * 10,
			limit: 10,
			startDate: dates && dates[0] ? dates[0] : undefined,
			endDate: dates && dates[1] ? dates[1] : undefined,
			status: objectStatus
		})
			.then(response => {
				if (!!response.data.waypoints) {
					const filteredWaypoints = response.data.waypoints.filter(waypoint => {
						const createdAt = new Date(waypoint.createdAt);
						// return isDateInRange(waypointDate, videoObject.createdAt); // videoObject = object that represents the specific video you want to filter the waypoints by
						return isDateInRange(createdAt, dates);
					});
					const newPoints = [...waypoints, ...filteredWaypoints];

					setwaypoints(newPoints);
					const pageCount = response.data.videosNumber.videosNumber || 0;
					// if there are more pages, load more
					// manual lazy load
					if (currentPage < Math.ceil(pageCount / 10)) {
						fetchData(currentPage + 1, newPoints);
					} else {
						settingsStore.updateGlobalLoading(false);
					}
				} else {
					setwaypoints([]);
					settingsStore.updateGlobalLoading(false);
					messageStore.snackbar({
						message: t('globalMap.projectDoesNotHaveWaypoints'),
						type: 'error'
					});
				}
			})
			.catch(err => {
				setwaypoints([]);
				settingsStore.updateGlobalLoading(false);
				messageStore.snackbar({
					message: t('globalMap.failedFetchingWaypointsVideos'),
					type: 'error'
				});
			});
	};

	const fetchWaypoints = useCallback(async () => {
		if (selectedMission) {
			settingsStore.updateGlobalLoading(true);
			fetchData(1, []);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedMission, t, objectStatus]);

	useEffect(() => {
		const coords: MapPolylineCoords[] = [];
		waypoints.length > 1 &&
			waypoints.map(point =>
				coords.push({
					videoID: point.videoID,
					lat: point.latitude,
					lng: point.longitude,
					isApproved: point.isApproved,
					isRejected: point.isRejected,
					isPending: point.isPending
				})
			);
		setWaypointsCoords(coords);
	}, [waypoints]);

	useEffect(() => {
		fetchWaypoints();
	}, [fetchWaypoints]);

	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const MapWrapper = GoogleApiWrapper({
		apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''
	})(Mapper);

	return (
		<SimpleLayout passedStyles={styles.root}>
			<CarchupaPaper type="row" parentStyle={styles.paper}>
				<SelectList
					inputWidth={'200px'}
					queryKey={QueryKey.globalMap}
					mainQueryKey={MainQueryKey.mission}
					labelId="mission"
					id="mission-select"
					htmlFor="mission"
					labelText={t('globalMap.missionLabelText')}
					data={projectListData?.projects}
				/>

				<SelectList
					sx={{ width: '200px' }}
					queryKey={QueryKey.globalMap}
					mainQueryKey={MainQueryKey.objectStatus}
					labelId="user"
					id="user-select"
					htmlFor="user"
					labelText={t('globalMap.objectStatusLabelText')}
					data={ObjectStatusOptions}
				/>
				{/*map view video filter by date NOT FINISHED */}
				<Box>
					<InputLabel sx={{ color: '#494F4D' }}>{t('filter videos by date')}</InputLabel>
					<DateRangePickerInput dates={dates} queryKey={DatePickerQueryKey.videoCreationDate} />
				</Box>
				{/*map view video filter by date NOT FINISHED */}
			</CarchupaPaper>
			<CarchupaPaper type="row" parentStyle={styles.paper}>
				{!!selectedMission ? (
					<Box className={styles.container}>
						<Box className={styles.map}>
							<MapWrapper waypointsCoords={waypointsCoords} markers={markers} callback={videoId => {}} />
						</Box>
					</Box>
				) : (
					<Box component="span">{t('pleaseSelectProjectText')}</Box>
				)}
			</CarchupaPaper>
		</SimpleLayout>
	);
};

export default inject('settingsStore', 'messageStore', 'authStore')(observer(GlobalMap));
