import styles from './TickerCell.module.scss';
import React from 'react';

const TickerCell = ({ label, value }: any) => {
	const formattedValue = value < 0 ? '00' : value < 10 ? `0${value}` : value.toString();

	return (
		<div className={styles.tickerCell}>
			<span className={styles.tickerCellValue}>{formattedValue}</span>
			<span className={styles.tickerCellLabel}>{label}</span>
		</div>
	);
};

export default TickerCell;
