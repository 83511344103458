import { useQuery, useQueryClient } from 'react-query';

export enum CacheUploadsParamsMainQuery {
	uploadPageParamsQueryKey = 'upload-page-cache-params'
}

export const useCacheUploadsParamsQuery = (mainQueryKey: CacheUploadsParamsMainQuery) => {
	const queryClient = useQueryClient();

	const setCacheUploadsParamsQuery = (mainQueryKey: CacheUploadsParamsMainQuery, params?: any) => {
		queryClient.setQueryData([mainQueryKey], params);
	};

	const getSelectedOption = (mainQueryKey: CacheUploadsParamsMainQuery) => {
		const data: string | undefined = queryClient.getQueryData([mainQueryKey]);
		return data;
	};

	const { data } = useQuery([mainQueryKey], () => getSelectedOption(mainQueryKey));
	return { data, setCacheUploadsParamsQuery };
};
