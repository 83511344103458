import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { uploadPolygon } from '../../../api/mapApi';
import SatelliteIcon from '@mui/icons-material/Satellite';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import projectEditorStore from '../../../stores/projectEditorStore';
import { t } from 'i18next';
import { InfoTooltip } from './InfoTooltip';

export const ProjectGameAreaEditor = () => {
	const [isGeoUploading, setIsGeoUploading] = useState<boolean>(false);
	const { project } = projectEditorStore;
	const projectId = project.id;
	const [geoJsonFile, setGeoJsonFile] = useState<File | undefined>(undefined);

	const onDrop = (file: File[]) => {
		setGeoJsonFile(file[0]);
	};

	const onFileDelete = (e: React.SyntheticEvent) => {
		e.stopPropagation();
		setGeoJsonFile(undefined);
	};

	const onFileUpload = async (e: React.SyntheticEvent) => {
		if (!geoJsonFile) return;
		setIsGeoUploading(true);
		e.stopPropagation();

		try {
			const res = await uploadPolygon(projectId, geoJsonFile);
			if (res.status === 201) toast.success('Polygon uploaded');
		} catch (err) {
			console.error('Error uploading polygon', err);
			toast.error('Error uploading polygon');
		} finally {
			setIsGeoUploading(false);
		}
	};

	const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false, accept: '.json, .geojson' });

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
			<div>
				<Divider>
					<Typography variant="caption">{t('projectEditor.projectGameArea.title')}</Typography>
					<InfoTooltip title={t('projectEditor.projectGameArea.tooltip')} />
				</Divider>
			</div>
			{!geoJsonFile && (
				<div>
					<div
						{...getRootProps()}
						style={{
							background: projectId > 0 ? '#F9F8F2' : 'rgba(0, 0, 0, 0.1)',
							border: '1px dashed #A4A4A4',
							boxSizing: 'border-box',
							borderRadius: '8px',
							display: 'flex',

							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							padding: '24px',
							cursor: projectId > 0 ? 'pointer' : 'not-allowed'
						}}>
						<input {...getInputProps()} disabled />
						<SatelliteIcon color={projectId > 0 ? 'primary' : 'disabled'} fontSize="large" />
						{projectId > 0 && <span>Click to upload or drag and drop GeoJSON file here.</span>}
						{!projectId && (
							<span>
								<i>Save project before uploading polygon</i>
							</span>
						)}
					</div>
				</div>
			)}

			{geoJsonFile ? (
				<div>
					<div
						{...getRootProps()}
						style={{
							background: '#F9F8F2',
							border: '1px dashed #A4A4A4',
							boxSizing: 'border-box',
							borderRadius: '8px',
							display: 'flex',

							justifyContent: 'center',
							alignItems: 'center',
							flexDirection: 'column',
							padding: '24px',
							cursor: 'pointer'
						}}>
						<input {...getInputProps()} />
						{geoJsonFile.name}
						<Box sx={{ display: 'flex', padding: '12px' }}>
							<Button color="warning" disabled={isGeoUploading} onClick={onFileDelete} startIcon={<DeleteOutlineIcon />}>
								Delete
							</Button>
							<LoadingButton
								color="primary"
								loading={isGeoUploading}
								onClick={onFileUpload}
								startIcon={<DriveFolderUploadIcon />}
								variant="contained">
								Upload to map server
							</LoadingButton>
						</Box>
					</div>
				</div>
			) : null}
		</Box>
	);
};
