import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField } from '@mui/material';
import { API } from '../../../api';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

interface props {
	open: boolean;
	onClose: () => void;
	userId: number;
}

export const AddBalanceDialog = ({ open, onClose, userId }: props) => {
	const [value, setValue] = useState<number>();
	const [error, setError] = useState<string>('');
	const [info, setInfo] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleAddBalance = () => {
		setError('');
		setIsLoading(true);
		API.modifyUserBalance(value as number, info, userId)
			.then((res: any) => {
				if (res.code == 200) {
					toast.success('Balance added successfully, refresh to see new transaction');
				}
				onClose();
			})
			.catch(err => {
				console.error(err);
				setError('Unknown server error, check log for error');
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<div>
			<Dialog open={open} onClose={onClose} fullWidth>
				<DialogTitle>Add manual balance</DialogTitle>
				<DialogContent style={{ paddingTop: '10px' }}>
					<div style={{ paddingBottom: '20px' }}>
						You can update a users balance manually here. You can add and remove balance by entering a positive or negative number.
					</div>
					<Box sx={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
						<TextField
							fullWidth
							label="Amount"
							error={error.length > 0}
							helperText={error}
							type="number"
							value={value}
							onChange={e => setValue(Number(e.target.value))}
							InputProps={{
								startAdornment: <InputAdornment position="start">€</InputAdornment>
							}}
						/>
						<TextField
							fullWidth
							label="Information or message to the user"
							value={info}
							onChange={e => setInfo(e.target.value)}
							error={info.length < 1}
							helperText={'This is required'}
							placeholder="Info"
						/>
					</Box>
				</DialogContent>
				<DialogActions style={{ paddingBottom: '10px' }}>
					<LoadingButton variant="outlined" loading={isLoading} onClick={handleAddBalance} disabled={info.length < 1}>
						Change balance
					</LoadingButton>
					<LoadingButton loading={isLoading} color="error" onClick={onClose}>
						Close
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</div>
	);
};
