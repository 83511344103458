import React, { useEffect, useState } from 'react';
import { Container } from './CrowdsorsaCheckbox.styled';

interface Props {
	inputName: string;
	inputId: string;
	checked?: boolean;
	value?: number;
	register?: any;
	registerValue?: string;
	tagLabel: string;
	isChecked?: boolean;
	isDisabled?: boolean;
}

const CrowdsorsaCheckbox = (props: Props) => {
	const { inputName, inputId, value, registerValue, register, tagLabel, isChecked, isDisabled } = props;

	const [checked, setChecked] = useState<Boolean>();

	useEffect(() => {
		setChecked(isChecked);
	}, [isChecked]);

	return (
		<Container>
			<input
				disabled={isDisabled}
				checked={checked}
				onClick={() => setChecked(!checked)}
				{...register(registerValue)}
				value={value}
				type="checkbox"
				name={inputName}
				id={inputId}
			/>
			<label htmlFor={inputId}>{tagLabel}</label>
		</Container>
	);
};

export default CrowdsorsaCheckbox;
