import { Button, TableCell } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { DataTable } from '../../../components/UI/table/DataTable';
import { HeadCell } from '../../../components/UI/table/DataTableHead';
import { UserList } from '../../../models/user';
import { useGetUserListQuery } from '../../../queries/data-queries/useGetUserListQuery';
import userStore from '../../../stores/userStore';

interface UsersTableProps {
	mission?: string;
	country?: string;
	searchedValue?: string;
	handleDownloadUserCSV: (userIds: number[]) => void;
}

export const UsersTable = (props: UsersTableProps) => {
	const { handleDownloadUserCSV } = props;
	const { t } = useTranslation();
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	/////////////////////////////////////////////// REACT-QUERY ////////////////////////////////////////////////////////
	const { users, totalItems, isLoading } = useGetUserListQuery({
		page,
		rowsPerPage,
		country: props?.country,
		mission: props?.mission,
		searchedValue: props.searchedValue
	});
	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		userStore.updateUsers(users);
	}, [users]);

	const getPage = (page: number) => {
		setPage(page);
	};

	const getRowsPerPage = (rows: number) => {
		setRowsPerPage(rows);
	};

	const headCells: Array<HeadCell<UserList>> = [
		{
			id: 'id',
			numeric: true,
			disablePadding: true,
			label: t('ID')
		},
		{
			id: 'username',
			numeric: false,
			disablePadding: false,
			label: t('username')
		},
		{ id: 'email', numeric: false, disablePadding: false, label: t('email') },
		{
			id: 'approved',
			numeric: true,
			disablePadding: false,
			label: t('approved')
		},
		{
			id: 'uploaded',
			numeric: true,
			disablePadding: false,
			label: t('Uploaded')
		},
		{
			id: 'notUploaded',
			numeric: true,
			disablePadding: false,
			label: t('notUploaded')
		}
	];
	return isLoading ? null : (
		<DataTable
			handleDownloadUserCSV={handleDownloadUserCSV}
			rows={users}
			headCells={headCells}
			tableCells={TableCells}
			withToolbar
			withPagination
			totalItems={totalItems}
			getPage={getPage}
			getRowsPerPage={getRowsPerPage}
		/>
	);
};

const TableCells = (row: UserList) => {
	return (
		<>
			<TableCell align="left">
				<Link style={{ textDecoration: 'none' }} to={`${process.env.PUBLIC_URL}/profile/${row.id}?prev=users`}>
					<Button
						sx={{
							textDecoration: 'underline'
						}}
						variant="text"
						size="small">
						{row.username}
					</Button>
				</Link>
			</TableCell>
			<TableCell align="left">{row.email}</TableCell>

			<TableCell align="left">{row.approved}</TableCell>
			<TableCell align="left">{row.uploaded}</TableCell>
			<TableCell align="left">{row.notUploaded}</TableCell>
		</>
	);
};
