import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import authStore from '../../../stores/authStore';

interface ContractAdminRouteProps {
	path: any;
	exact?: boolean;
	component: any;
}

const ContractAdminRoute = (props: ContractAdminRouteProps) => {
	const path = props.path;
	const component = props.component;

	return authStore.adminRole === 'superadmin' || authStore.adminRole === 'contractAdmin' ? (
		<Route path={path} exact={props.exact ? props.exact : false} component={component} />
	) : (
		<Redirect to={{ pathname: '/uploads' }} />
	);
};

export default ContractAdminRoute;
