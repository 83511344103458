import { useQuery, useQueryClient } from 'react-query';

export enum MissionTypeMainQueryKey {
	missionType = 'mission-type'
}

export const useMissionTypeQuery = () => {
	const queryClient = useQueryClient();

	const setMissionTypeQuery = (type: number) => {
		queryClient.setQueryData(MissionTypeMainQueryKey.missionType, type);
	};

	const getMissionTypeQuery = (): any => {
		const data = queryClient.getQueryData(MissionTypeMainQueryKey.missionType);
		return data;
	};

	const { data } = useQuery(MissionTypeMainQueryKey.missionType, () => getMissionTypeQuery(), { cacheTime: Infinity, initialData: 1 });

	return {
		setMissionTypeQuery,
		missionType: data
	};
};
