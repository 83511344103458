import { DateTimePicker } from '@mui/lab';
import { Box, Divider, Typography, Tooltip, Autocomplete, TextField } from '@mui/material';
import { t } from 'i18next';
import { observer } from 'mobx-react';
import { useState, useEffect } from 'react';
import { API } from '../../../api';
import { userFromAllList } from '../../../models/general';
import projectEditorStore from '../../../stores/projectEditorStore';
import { InfoTooltip } from './InfoTooltip';

export const ProjectDetailsEditor = observer(() => {
	const { project, errors } = projectEditorStore;
	const [users, setUsers] = useState<userFromAllList[]>([]);

	const loadUsers = async () => {
		const res = await API.getAllUsers({});
		setUsers(res.data.users);
	};

	const clientUsersOnChange = (_e: React.SyntheticEvent, values: userFromAllList[]) => (project.clientUsers = values.map(v => v.id));
	const privateUsersOnChange = (_e: React.SyntheticEvent, values: userFromAllList[]) => (project.privateUsers = values.map(v => v.id));

	useEffect(() => {
		loadUsers();
	}, []);

	return (
		<Box sx={{ display: 'flex', gap: '20px', flexDirection: 'column' }}>
			<div>
				<Divider>
					<Typography variant="caption">{t('projectEditor.projectDetails.title')}</Typography>
					<InfoTooltip title={t('projectEditor.projectDetails.tooltip')} />
				</Divider>
			</div>
			<Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', alignContent: 'space-between' }}>
				<Box sx={{ flex: '1 1' }}>
					<Tooltip title={t('projectEditor.projectDetails.clientUsersTooltip') as string}>
						<Autocomplete
							getOptionLabel={(option: any) => {
								if (!option) return '';
								return option.id + '-' + option.username;
							}}
							id="tags-standard"
							multiple
							onChange={clientUsersOnChange}
							options={users}
							limitTags={2}
							renderInput={params => (
								<TextField
									{...params}
									label={t('projectEditor.projectDetails.clientUsers')}
									sx={{
										'.MuiOutlinedInput-root': {
											paddingTop: '0px',
											paddingBottom: '0px',
											minHeight: '46px',
											height: '100%',
											maxHeight: 'unset'
										}
									}}
								/>
							)}
							value={project.clientUsers.map(userId => {
								if (!users || !project.clientUsers) return [];
								const match = users.find(u => u.id === userId);

								if (match) return match;
							})}
						/>
					</Tooltip>
				</Box>

				<Box sx={{ flex: '1 1' }}>
					<Tooltip title={t('projectEditor.projectDetails.privateUsersTooltip') as string}>
						<Autocomplete
							getOptionLabel={(option: any) => {
								if (!option) return '';
								return option.id + '-' + option.username;
							}}
							id="tags-standard"
							multiple
							onChange={privateUsersOnChange}
							options={users}
							limitTags={2}
							renderInput={params => (
								<TextField
									{...params}
									label={t('projectEditor.projectDetails.privateUsers')}
									sx={{
										'.MuiOutlinedInput-root': {
											paddingTop: '0px',
											paddingBottom: '0px',
											minHeight: '46px',
											height: '100%',
											maxHeight: 'unset'
										}
									}}
								/>
							)}
							value={project.privateUsers.map(userId => {
								if (!users || !project.privateUsers) return [];
								const match = users.find(u => u.id === userId);

								if (match) return match;
							})}
						/>
					</Tooltip>
				</Box>
				<Box sx={{ maxWidth: '25%' }}>
					<Tooltip title={t('projectEditor.projectDetails.dateStartTooltip') as string}>
						<DateTimePicker
							ampm={false}
							onChange={date => (project.dateStart = date!)}
							renderInput={params => <TextField {...params} label={t('projectEditor.projectDetails.dateStart')} />}
							value={project.dateStart}
						/>
					</Tooltip>
				</Box>
			</Box>
			<Box>
				<Tooltip title={t('projectEditor.projectDetails.descriptionTooltip') as string}>
					<TextField
						fullWidth
						value={project.description}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => (project.description = e.target.value)}
						placeholder="Mission description legacy field"
						sx={{
							'.MuiOutlinedInput-root': {
								width: '100%',
								border: 'none',
								maxHeight: 'unset'
							}
						}}
						id="missionDescription"
						multiline
						rows={5}
						error={!!errors.MissionDescription?.message?.length}
					/>
				</Tooltip>
			</Box>
		</Box>
	);
});
