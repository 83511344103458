import React, { useState, useEffect, useRef } from 'react';
import { API } from '../../../api';
import { toast } from 'react-toastify';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import {
	Backdrop,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	Input,
	MenuItem,
	Select,
	Slider,
	TextField,
	Typography
} from '@mui/material';
// @ts-ignore
import mapboxgl, { Map, Marker } from '!mapbox-gl';
import { SessionStatusChip } from '../videos/InvasiveSpeciesItem';
import { t } from 'i18next';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ForestOutlinedIcon from '@mui/icons-material/ForestOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { ObjectTypes } from '../mapboxMap/MapboxMap';
import { getInvasiveProjectStyle } from '../../../api/mapApi';
import authStore from '../../../stores/authStore';
import InputAdornment from '@mui/material/InputAdornment';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN as string;

export const plantNames = {
	[ObjectTypes.HIMALAYAN_BALSAM]: 'Himalayan Balsam',
	[ObjectTypes.GIANT_HOGWEED]: 'Giant Hogweed',
	[ObjectTypes.LUPINE]: 'Lupine',
	[ObjectTypes.JAPANESE_ROSE]: 'Japanese Rose',
	[ObjectTypes.JAPANESE_KNOTWEED]: 'Japanese Knotweed',
	[ObjectTypes.CANADA_GOLDENROD]: 'Canada Goldenrod',
	[ObjectTypes.FALSE_SPIRAEA]: 'False Spiraea',
	[ObjectTypes.LESSER_PERIWINKLE]: 'Lesser Periwinkle',
	[ObjectTypes.BUDDLEIA]: 'Buddleia',
	[ObjectTypes.PARROTS_FEATHER]: 'Parrots Feather',
	[ObjectTypes.GOATS_RUE]: 'Goats Rue',
	[ObjectTypes.CHERRY_LAUREL]: 'Cherry Laurel'
};

const SessionData = (props: { sessionData: any; deviceData: { deviceName: string; deviceOS: string; appVersion: string } }) => {
	const { sessionData, deviceData } = props;

	const SessionDataItem = (props: { dataTitle: string; data: any }) => {
		const { dataTitle, data } = props;

		return (
			<Box>
				<Typography variant="caption" color="primary" fontWeight={'900'} letterSpacing={'0.05735em'}>
					{dataTitle}
				</Typography>
				<Typography variant="body1">{data}</Typography>
			</Box>
		);
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
			<Box sx={{ display: 'flex', flexDirection: 'row', gap: '30px' }}>
				<SessionDataItem dataTitle="Project name" data={sessionData.projectName} />
				<SessionDataItem dataTitle="Project id" data={sessionData.projectId} />
				<SessionDataItem
					dataTitle="Start date"
					data={`${new Date(sessionData.startedAt).toLocaleDateString()} ${new Date(sessionData.startedAt).toLocaleTimeString()}`}
				/>
				<SessionDataItem dataTitle="Username" data={sessionData.username} />
				<SessionDataItem dataTitle="userId" data={sessionData.userId} />

				<SessionDataItem dataTitle="Session value" data={sessionData.sessionValue} />
				<SessionDataItem dataTitle="Selected density" data={sessionData.density} />
				<SessionDataItem dataTitle="Area" data={sessionData.area} />
				<SessionDataItem dataTitle="Plant Id" data={sessionData.marker} />
				<SessionDataItem dataTitle="Plant name" data={plantNames[sessionData.marker]} />
			</Box>
			{deviceData ? (
				<Box sx={{ display: 'flex', flexDirection: 'row', gap: '30px' }}>
					<SessionDataItem dataTitle="Device name" data={deviceData.deviceName} />
					<SessionDataItem dataTitle="Device OS" data={deviceData.deviceOS} />
					<SessionDataItem dataTitle="App version" data={deviceData.appVersion} />
				</Box>
			) : (
				<>No device data</>
			)}
		</Box>
	);
};

const RejectSessiondialog = (props: { isOpen: boolean; onClose: () => void; sessionData: any; loadInvasiveSession: () => void }) => {
	const { isOpen, onClose, sessionData, loadInvasiveSession } = props;
	const [rejectionReasons, setRejectionReasons] = useState<{ id: string; description: string }[]>([]);

	const [isReasonsLoading, setIsReasonsLoading] = useState<boolean>(false);
	const [customRejectionReason, setCustomRejectionReason] = useState<string | undefined>(undefined);

	const [rejectionReasonId, setRejectionReasonId] = useState<number>(-1);

	useEffect(() => {
		setIsReasonsLoading(true);
		API.getRejectionReasons()
			.then(response => {
				setRejectionReasons(response.data.rejectionReasons);
			})
			.catch(err => {
				console.error(err);
			})
			.finally(() => {
				setIsReasonsLoading(false);
			});
	}, []);

	const reject = async () => {
		API.changeInvasiveSessionStatus(
			sessionData.uploads.map(upload => upload.id),
			'reject',
			rejectionReasonId,
			customRejectionReason,
			undefined,
			sessionData.sessionId
		)
			.then(response => {
				toast.success('Session rejected');
				loadInvasiveSession();
				onClose();
			})
			.catch(err => {
				console.error(err);
				toast.error('Error rejecting session');
			})
			.finally(() => {});
	};

	const onRejectionReasonChange = (e: any) => {
		if (!e.target) return;
		// If custom reason is selected, set rejectionReasonId to 0
		if (rejectionReasonId != 0) {
			setCustomRejectionReason(undefined);
		}

		setRejectionReasonId(e.target.value);
	};

	const onCustomRejectionReasonChange = (e: any) => {
		setCustomRejectionReason(e.target.value);
	};

	return (
		<Dialog open={isOpen} onClose={() => onClose()}>
			<DialogContent>
				<Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
					<Typography variant="body1">This will reject all uploads in the session. Are you sure?</Typography>

					<Select fullWidth value={rejectionReasonId} onChange={onRejectionReasonChange}>
						<MenuItem value={-1} disabled>
							Select a reason
						</MenuItem>
						<MenuItem value={0}>Custom reason</MenuItem>

						{rejectionReasons.map(reason => (
							<MenuItem value={reason.id}>{reason.description}</MenuItem>
						))}
					</Select>

					{rejectionReasonId == 0 && (
						<>
							Custom rejection reason
							<TextField placeholder="Custom reason" minRows={6} value={customRejectionReason} onChange={onCustomRejectionReasonChange} />
						</>
					)}

					<Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px', paddingTop: '20px', justifyContent: 'center' }}>
						<Button startIcon={<ThumbUpOffAltIcon />} variant="contained" color="error" onClick={() => reject()}>
							Reject session
						</Button>
						<Button variant="text" color="error" onClick={() => onClose()}>
							Cancel
						</Button>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

const RejectWithPaymentDialog = (props: { isOpen: boolean; onClose: () => void; sessionData: any; loadInvasiveSession: () => void }) => {
	const { isOpen, onClose, sessionData, loadInvasiveSession } = props;
	const [newSessionValue, setNewSessionValue] = useState<number | undefined>(undefined);

	const rejectWithPayment = async () => {
		API.changeInvasiveSessionStatus(
			sessionData.uploads.map(upload => upload.id),
			'rejectWithPayment',
			undefined,
			undefined,
			newSessionValue,
			sessionData.sessionId
		)
			.then(response => {
				toast.success('Session rejected with payment');
				loadInvasiveSession();
				onClose();
			})
			.catch(err => {
				console.error(err);
				toast.error('Error rejecting session with payment');
			})
			.finally(() => {});
	};

	return (
		<Dialog open={isOpen} onClose={() => onClose()}>
			<DialogContent>
				<Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
					<Typography variant="body1">
						This will reject with payment the whole session, if you set a new session value only the first video will take the new value the others
						will remain 0. Are you sure?
					</Typography>
					<TextField
						placeholder="New session value"
						type="number"
						value={newSessionValue}
						onChange={e => setNewSessionValue(Number(e.target.value))}
					/>

					<Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px', paddingTop: '20px', justifyContent: 'center' }}>
						<Button startIcon={<ThumbUpOffAltIcon />} variant="contained" color="warning" onClick={() => rejectWithPayment()}>
							Reject session with payment
						</Button>
						<Button variant="text" color="error" onClick={() => onClose()}>
							Cancel
						</Button>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

const UpdateAreaDialog = (props: { isOpen: boolean; onClose: () => void; sessionData: any; loadInvasiveSession: () => void }) => {
	const { isOpen, onClose, sessionData, loadInvasiveSession } = props;
	const [area, setArea] = useState<number>(0);
	const [newSessionValue, setNewSessionValue] = useState<number | undefined>(0);

	// Tests the potential value after selecing a new density
	const testSessionValue = async () => {
		if (!area) return;

		API.testSessionValue(sessionData.projectId, sessionData.density, sessionData.marker, area)
			.then(response => {
				setNewSessionValue(response.data.sessionValue);
			})
			.catch(err => {
				console.log(err);
			});
	};

	const updateArea = async () => {
		if (!area) return;
		if (area == sessionData.area) return;

		API.updateSessionArea(sessionData.sessionId, sessionData.density, area, sessionData.marker, sessionData.projectId)
			.then(response => {
				loadInvasiveSession();
				onClose();
				toast.success('Area updated');
			})
			.catch(err => {
				console.error(err);
				toast.error('Error changing area.');
			})
			.finally(() => {});
	};

	const handleChange = event => {
		const newValue = event.target.value;
		// Ensure the new value is either empty or a valid number
		if (newValue === '' || !isNaN(Number(newValue))) {
			setArea(newValue);
		}
	};

	useEffect(() => {
		testSessionValue();
	}, [area]);

	return (
		<Dialog open={isOpen} onClose={() => onClose()} maxWidth="md">
			<DialogContent>
				<Box>
					<Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', gap: '40px', paddingBottom: '20px' }}>
						<Box>
							<Typography variant="caption" color="primary" fontWeight={'900'} letterSpacing={'0.05735em'}>
								{'New session value'}
							</Typography>
							<Typography variant="body1">{newSessionValue?.toFixed(2)}</Typography>
						</Box>
					</Box>
					<Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', gap: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
						<TextField
							type="number"
							value={area}
							onChange={e => handleChange(e)}
							InputProps={{
								inputProps: { inputMode: 'numeric', pattern: '[0-9]*' },
								endAdornment: <InputAdornment position="end">m²</InputAdornment>
							}}
						/>
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px', paddingTop: '40px', justifyContent: 'center' }}>
						<Button startIcon={<ThumbUpOffAltIcon />} variant="contained" color="primary" onClick={() => updateArea()}>
							Update density
						</Button>
						<Button variant="text" color="error" onClick={() => onClose()}>
							Cancel
						</Button>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

const UpdateDensityDialog = (props: { isOpen: boolean; onClose: () => void; sessionData: any; loadInvasiveSession: () => void }) => {
	const { isOpen, onClose, sessionData, loadInvasiveSession } = props;
	const [density, setDensity] = useState<number>(Number(sessionData.density) || 0);
	const [newSessionValue, setNewSessionValue] = useState<number | undefined>(0);

	const densities = [
		{ value: 0.1, label: 'None' },
		{ value: 0.2, label: 'Low' },
		{ value: 0.4, label: 'Low-Moderate' },
		{ value: 0.6, label: 'Moderate' },
		{ value: 0.8, label: 'Moderate-High' },
		{ value: 1.0, label: 'High' }
	];

	const onSliderChange = (event: any, value: any) => {
		setDensity(value);
	};

	// Tests the potential value after selecing a new density
	const testSessionValue = async () => {
		if (!density) return;

		API.testSessionValue(sessionData.projectId, density, sessionData.marker, sessionData.area)
			.then(response => {
				setNewSessionValue(response.data.sessionValue);
			})
			.catch(err => {
				console.log(err);
			});
	};

	const updateDensity = async () => {
		if (!density) return;
		if (density == sessionData.density) return;

		API.updateSessionDensity(sessionData.sessionId, density, sessionData.area, sessionData.marker, sessionData.projectId)
			.then(response => {
				loadInvasiveSession();
				onClose();
				toast.success('Density updated');
			})
			.catch(err => {
				console.error(err);
				toast.error('Error changing density.');
			})
			.finally(() => {});
	};

	useEffect(() => {
		testSessionValue();
	}, [density]);

	useEffect(() => {
		setDensity(Number(sessionData.density));
	}, [sessionData]);

	return (
		<Dialog open={isOpen} onClose={() => onClose()} fullWidth maxWidth={'sm'}>
			<DialogContent>
				<Box>
					<Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', gap: '40px' }}>
						<Box>
							<Typography variant="caption" color="primary" fontWeight={'900'} letterSpacing={'0.05735em'}>
								{'New density'}
							</Typography>
							<Typography variant="body1">{density}</Typography>
						</Box>
						<Box>
							<Typography variant="caption" color="primary" fontWeight={'900'} letterSpacing={'0.05735em'}>
								{'New session value'}
							</Typography>
							<Typography variant="body1">{newSessionValue?.toFixed(2)}</Typography>
						</Box>
					</Box>
					<Box sx={{ display: 'flex', alignContent: 'center', alignItems: 'center', gap: '10px', paddingLeft: '20px', paddingRight: '20px' }}>
						<Slider
							value={density || Number(sessionData.density)}
							aria-labelledby="discrete-slider"
							valueLabelDisplay="auto"
							marks={densities}
							min={0.1}
							max={1.0}
							step={null}
							onChange={onSliderChange}
						/>
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px', paddingTop: '40px', justifyContent: 'center' }}>
						<Button startIcon={<ThumbUpOffAltIcon />} variant="contained" color="primary" onClick={() => updateDensity()}>
							Update density
						</Button>
						<Button variant="text" color="error" onClick={() => onClose()}>
							Cancel
						</Button>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

const ApproveSessionDialog = (props: { isOpen: boolean; onClose: () => void; sessionData: any; loadInvasiveSession: () => void }) => {
	const { isOpen, onClose, sessionData, loadInvasiveSession } = props;

	const approveSession = async () => {
		API.changeInvasiveSessionStatus(
			sessionData.uploads.map(upload => upload.id),
			'approve',
			undefined,
			undefined,
			sessionData.sessionId
		)
			.then(response => {
				loadInvasiveSession();
				onClose();
				toast.success('Session status changed');
			})
			.catch(err => {
				console.error(err);
				toast.error('Error updating session status');
			})
			.finally(() => {});
	};

	return (
		<Dialog open={isOpen} onClose={() => onClose()}>
			<DialogContent>
				<Typography variant="body1">This will approve all uploads in the session. Are you sure?</Typography>
				<Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px', paddingTop: '20px', justifyContent: 'center' }}>
					<Button startIcon={<ThumbUpOffAltIcon />} variant="contained" color="primary" onClick={() => approveSession()}>
						Approve session
					</Button>
					<Button variant="text" color="error" onClick={() => onClose()}>
						Cancel
					</Button>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

const SessionUploadItem = (props: { upload: any; projectId: number }) => {
	const { upload, projectId } = props;
	const [uploadUrl, setUploadUrl] = useState<string>('');
	const [waypoints, setWaypoints] = useState<any[]>([]);
	const mapContainer = useRef(null);
	const map = useRef<mapboxgl.Map | null>(null);

	const UploadData = (props: { dataTitle: string; data: any }) => {
		const { dataTitle, data } = props;

		return (
			<Box>
				<Typography variant="caption" color="primary" fontWeight={'900'} letterSpacing={'0.05735em'}>
					{dataTitle}
				</Typography>
				<Typography variant="body1">{data}</Typography>
			</Box>
		);
	};

	const getWaypoints = async () => {
		API.getWaypoints({
			projectID: undefined,
			videoIDs: [upload.id],
			offset: undefined,
			limit: 99999,
			endDate: undefined,
			status: undefined
		})
			.then(response => {
				setWaypoints(
					response.data.waypoints.map((waypoint: any) => ({
						lat: waypoint.latitude,
						lon: waypoint.longitude
					}))
				);
			})
			.catch(err => {
				console.error(err);
				toast.error('Failed to load waypoints');
			});
	};

	const getUploadUrls = async () => {
		API.getUpload(upload.id)
			.then(response => {
				setUploadUrl(response.data.upload.url);
			})
			.catch(() => {
				toast.error('Failed to load upload url');
			})
			.finally(() => {});
	};

	useEffect(() => {
		if (!map.current && mapContainer.current) {
			map.current = new mapboxgl.Map({
				container: mapContainer.current,
				style: 'mapbox://styles/mapbox/streets-v11',
				center: [0, 0],
				zoom: 8,
				transformRequest: (url, resourceType) => {
					const gisApiUrl = process.env.REACT_APP_GIS_API || '';
					// We need to add the auth header to calls to the map server
					// but not to mapbox itself, otherwise errors.
					if (url.includes(gisApiUrl)) {
						return {
							url,
							headers: {
								authorization: `Bearer ${authStore.token}`,
								// @ts-ignore
								'X-Api-Key': process.env.REACT_APP_API_KEY
							}
						};
					}
				}
			});

			getInvasiveProjectStyle(projectId).then(res => {
				map.current.setStyle(res.data);
			});
		}

		map.current.on('load', () => {
			getWaypoints();
		});

		// Clean up on unmount
		return () => {
			if (map.current) {
				map.current.remove();
			}
		};
	}, []);

	useEffect(() => {
		getUploadUrls();
	}, []);

	useEffect(() => {
		if (!map.current || waypoints.length === 0) return;

		const sourceID = 'route';
		const lineLayerID = 'route-line';

		if (map.current.getSource(sourceID)) {
			// Update the source with new data if it already exists
			(map.current.getSource(sourceID) as mapboxgl.GeoJSONSource).setData({
				type: 'Feature',
				properties: {},
				geometry: {
					type: 'LineString',
					coordinates: waypoints.map(wp => [wp.lon, wp.lat])
				}
			});
		} else {
			if (!map.current) return;

			// Create the source and layer for the line
			map.current.addSource(sourceID, {
				type: 'geojson',
				data: {
					type: 'Feature',
					properties: {},
					geometry: {
						type: 'LineString',
						coordinates: waypoints.map(wp => [wp.lon, wp.lat])
					}
				}
			});

			map.current.addLayer({
				id: lineLayerID,
				type: 'line',
				source: sourceID,
				layout: {
					'line-cap': 'round',
					'line-join': 'round'
				},
				paint: {
					'line-color': '#ff0000',
					'line-width': 5
				}
			});
		}

		const bounds = new mapboxgl.LngLatBounds();
		waypoints.forEach(wp => bounds.extend([wp.lon, wp.lat]));
		map.current.fitBounds(bounds, {
			padding: 100,
			linear: true
		});
	}, [waypoints]);

	return (
		<Box sx={{ display: 'flex', flexDirection: 'row', gap: '50px' }}>
			<Box>
				<Box id="title-container" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
					<Typography variant="h5">
						{'Session upload'}: {upload.id}
					</Typography>

					<SessionStatusChip sessionStatus={upload.status} />
				</Box>

				<Box sx={{ display: 'flex', flexDirection: 'row', gap: '50px', paddingBottom: '30px' }}>
					<UploadData dataTitle="Filename" data={upload.fileName} />
				</Box>
				<Box sx={{ display: 'flex', alignContent: 'space-evenly', gap: '10px', minHeight: '282px', maxHeight: '282px', maxWidth: '1400px' }}>
					{upload.url != '' && <video src={uploadUrl} controls width={'50%'} style={{ objectFit: 'cover', width: `${1200 / 2}px` }} />}
					<div ref={mapContainer} style={{ height: '282px', width: `${1200 / 2}px` }} />
				</Box>
			</Box>
		</Box>
	);
};

export const InvasiveSpeciesSessionViewer = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [sessionData, setSessionData] = useState<any>({});
	const [deviceData, setDeviceData] = useState<any>({ deviceName: '', deviceOS: '', appVersion: '' });
	const url = window.location.pathname;
	const sessionId = Number(url.substring(url.lastIndexOf('/') + 1, url.length));

	const [isApproveDialogOpen, setIsApproveDialogOpen] = useState<boolean>(false);
	const [isRejectDialogOpen, setIsRejectDialogOpen] = useState<boolean>(false);
	const [isRejectWithPaymentDialogOpen, setIsRejectWithPaymentDialogOpen] = useState<boolean>(false);
	const [isUpdateDensityDialogOpen, setIsUpdateDensityDialogOpen] = useState<boolean>(false);
	const [isUpdateAreaDialogOpen, setIsUpdateAreaDialogOpen] = useState<boolean>(false);
	const [isInvalidSession, setIsInvalidSession] = useState<boolean>(false);
	const [isIncomplete, setIsIncomplete] = useState<boolean>(false);

	const loadInvasiveSession = async () => {
		setIsLoading(true);
		setIsInvalidSession(false);
		setIsIncomplete(false);

		API.getInvasiveSpeciesSessionData(sessionId)
			.then(response => {
				setSessionData(response.data.sessionData);
				if (response.data.sessionData.isSessionComplete == false) {
					toast.warn('This session is still active.');
					setIsIncomplete(true);
				}
			})
			.catch(error => {
				const code = error.data.code;

				if (error.data.data.sessionData) {
					setSessionData(error.data.data.sessionData);
				}

				if (code == 1021) {
					setIsInvalidSession(true);
				} else {
					toast.error('Failed to load invasive species session data');
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		loadInvasiveSession();
		API.getInvasiveSpeciesDeviceMetadata(sessionId)
			.then(res => {
				setDeviceData(res.data.deviceData);
			})
			.catch(err => {
				console.error(err);
			});
	}, []);

	return (
		<SimpleLayout passedStyles={''}>
			<Backdrop open={isLoading} sx={{ color: '#fff', zIndex: 99999 }}>
				<CircularProgress size="48px" color="inherit" />
			</Backdrop>

			<Box sx={{ maxWidth: '1400px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
				<Box id="title-container" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
					<Typography variant="h4">
						{t('invasiveSessionViewer.title')} {sessionId}
					</Typography>

					<SessionStatusChip sessionStatus={sessionData.isSessionComplete == false ? 'incomplete' : sessionData.sessionStatus} />
				</Box>
				{!isInvalidSession && <SessionData sessionData={sessionData} deviceData={deviceData} />}
			</Box>

			{(authStore.adminRole === 'superadmin' || authStore.adminRole === 'admin') && (
				<Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px' }}>
					<Button
						variant="text"
						color="primary"
						startIcon={<ThumbUpOffAltIcon />}
						onClick={() => {
							setIsApproveDialogOpen(true);
						}}>
						Approve
					</Button>
					<Button
						variant="text"
						color="error"
						startIcon={<ThumbDownOffAltIcon />}
						onClick={() => {
							setIsRejectDialogOpen(true);
						}}>
						Reject
					</Button>
					<Button
						variant="text"
						color="warning"
						startIcon={<ThumbUpOffAltIcon />}
						onClick={() => {
							setIsRejectWithPaymentDialogOpen(true);
						}}>
						Reject with payment
					</Button>
					<Button
						variant="text"
						color="primary"
						startIcon={<ForestOutlinedIcon />}
						onClick={() => {
							setIsUpdateDensityDialogOpen(true);
						}}>
						Update density
					</Button>
					<Button
						variant="text"
						color="primary"
						startIcon={<MapOutlinedIcon />}
						onClick={() => {
							setIsUpdateAreaDialogOpen(true);
						}}>
						Update area
					</Button>
					<Button variant="text" color="primary" startIcon={<MapOutlinedIcon />} onClick={() => {}}>
						Download session data
					</Button>
				</Box>
			)}
			<Box>
				{isInvalidSession && (
					<Typography sx={{ maxWidth: '800px', fontWeight: '600' }} variant="body1">
						⚠️ This session is invalid, the uploads as part of this session do not have linking invasive species session data. This means that that it
						is unknown if any invasive species data was ever sent, for example the user could have closed the app before completing the session. You
						can still change the status of these uploads if you wish.
					</Typography>
				)}
				{isIncomplete && (
					<Typography sx={{ maxWidth: '800px', fontWeight: '600' }} variant="body1">
						⚠️ This session is still active, the user has not yet fully completed the session, we are waiting if the user will complete the session or
						it is cancelled or timesout.
					</Typography>
				)}
				<Box
					id="uploads-container"
					sx={{
						paddingTop: '30px',
						display: 'flex',
						gap: '50px',
						flexDirection: 'column',
						justifySelf: 'center',
						width: '100%'
					}}>
					{sessionData.uploads && sessionData.uploads.map((upload: any) => <SessionUploadItem upload={upload} projectId={sessionData.projectId} />)}
				</Box>
			</Box>

			<ApproveSessionDialog
				isOpen={isApproveDialogOpen}
				onClose={() => setIsApproveDialogOpen(false)}
				sessionData={sessionData}
				loadInvasiveSession={loadInvasiveSession}
			/>
			<RejectSessiondialog
				isOpen={isRejectDialogOpen}
				onClose={() => setIsRejectDialogOpen(false)}
				sessionData={sessionData}
				loadInvasiveSession={loadInvasiveSession}
			/>
			<RejectWithPaymentDialog
				isOpen={isRejectWithPaymentDialogOpen}
				onClose={() => setIsRejectWithPaymentDialogOpen(false)}
				sessionData={sessionData}
				loadInvasiveSession={loadInvasiveSession}
			/>
			<UpdateDensityDialog
				isOpen={isUpdateDensityDialogOpen}
				onClose={() => setIsUpdateDensityDialogOpen(false)}
				sessionData={sessionData}
				loadInvasiveSession={loadInvasiveSession}
			/>
			<UpdateAreaDialog
				isOpen={isUpdateAreaDialogOpen}
				onClose={() => setIsUpdateAreaDialogOpen(false)}
				sessionData={sessionData}
				loadInvasiveSession={loadInvasiveSession}
			/>
		</SimpleLayout>
	);
};
