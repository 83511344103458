import { useMutation, useQueryClient } from 'react-query';
import { API } from '../../api';
import messageStore from '../../stores/messageStore';
import { UploadsDataMainQueryKey } from './useGetUploadsData';
import { useHistory } from 'react-router-dom';

type uploadsDataMainQueryKeyType = UploadsDataMainQueryKey;

interface ParamsI {
	id: number;
	status: string;
	bodyParams?: {
		rejectionReasonId?: number;
		customRejectionReason?: string;
		changedPaymentAmount?: number;
	};
}

export const usePatchUploadsQuery = (uploadsDataMainQueryKey?: uploadsDataMainQueryKeyType, uploadsParams?: any) => {
	const history = useHistory();
	const queryClient = useQueryClient();
	const { mutate } = useMutation(
		(params: ParamsI) => {
			const { id, status, bodyParams } = params;

			return API.patchUploads(id, status, bodyParams);
		},
		{
			onError: (err: { message: string }) => {
				messageStore.snackbar({
					message: err?.message,
					type: 'error'
				});
			},
			onSuccess: data => {
				//history.push('/uploads');
				messageStore.snackbar({
					message: data?.data?.message,
					type: 'success'
				});
			},
			onSettled: () => {
				queryClient.invalidateQueries([uploadsDataMainQueryKey, uploadsParams]);
			}
		}
	);

	// queryClient.invalidateQueries([uploadsDataMainQueryKey, uploadsParams]);
	return { mutate };
};
