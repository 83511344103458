import { Box, FormControlLabel, Checkbox, TextField, Paper, Divider, IconButton, Button } from '@mui/material';
import { t } from 'i18next';
import { ColorPicker } from 'material-ui-color';
import { observer } from 'mobx-react';
import missionEditorStore, { Step } from '../../../stores/missionEditorStore';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

export const RecordingStep = observer((props: { step: Step }) => {
	const { step } = props;
	const stepIndex = step.order - 1;
	const { setStepProperty, errors } = missionEditorStore;

	return (
		<Box sx={{ paddingTop: '20px', display: 'flex', gap: '20px', flexWrap: 'wrap', flexDirection: 'column', justifyContent: 'flex-start' }}>
			<div>
				<FormControlLabel
					checked={step.recordingParams?.portrait}
					control={<Checkbox checked={step.recordingParams?.portrait} onChange={e => setStepProperty(step, 'portrait', e.target.checked)} />}
					label={t('missionEditor.recordingStep.portrait')}
				/>
			</div>
			<TextField
				error={errors[`[${stepIndex}].recordingParams.headerText`]}
				helperText={errors[`[${stepIndex}].recordingParams.headerText`]}
				label={t('missionEditor.recordingStep.headerText')}
				onChange={e => setStepProperty(step, 'headerText', e.target.value)}
				value={step.recordingParams?.headerText}
			/>
			<div>
				<FormControlLabel
					checked={step.recordingParams?.hasCollectables}
					control={
						<Checkbox checked={step.recordingParams?.hasCollectables} onChange={e => setStepProperty(step, 'hasCollectables', e.target.checked)} />
					}
					label={t('missionEditor.recordingStep.allowCollectable')}
				/>
			</div>
			<TextField
				error={errors[`[${stepIndex}].recordingParams.collectRadius`]}
				helperText={errors[`[${stepIndex}].recordingParams.collectRadius`]}
				label={t('missionEditor.recordingStep.collectRadius')}
				onChange={e => setStepProperty(step, 'collectRadius', e.target.value)}
				value={step.recordingParams?.collectRadius}
				type="number"
			/>
			<div>
				<FormControlLabel
					control={<Checkbox checked={step.recordingParams?.generatePath} onChange={e => setStepProperty(step, 'generatePath', e.target.checked)} />}
					label={t('missionEditor.recordingStep.generatePath')}
					value={step.recordingParams?.generatePath}
				/>
			</div>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<span>{t('missionEditor.recordingStep.defaultPathColour')}</span>
				<ColorPicker
					disableAlpha
					disablePlainColor
					onChange={e => setStepProperty(step, 'defaultPath', e.hex ? `#${e.hex}` : `${e}`)}
					value={step.recordingParams?.defaultPath}
				/>
			</Box>
			<Box>
				{t('missionEditor.recordingStep.pathChoices')}
				{step.recordingParams?.pathChoices.map((choice, index) => {
					const choices = step.recordingParams?.pathChoices;

					return (
						<Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '6px', paddingBottom: '6px' }}>
							<span>{index + 1}.</span>
							<ColorPicker
								disableAlpha
								disablePlainColor
								onChange={e => {
									// e is a string when it's edited in the text field, but a object when it's edited
									choices![index] = e.hex ? `#${e.hex}` : `${e}`;
									setStepProperty(step, `pathChoices`, choices);
								}}
								value={step.recordingParams?.pathChoices[index]}
							/>
							<IconButton
								color="error"
								onClick={() => {
									choices?.splice(index, 1);
									setStepProperty(step, `pathChoices`, choices);
								}}>
								<RemoveCircleOutlineIcon />
							</IconButton>
						</Box>
					);
				})}
				<Button
					onClick={() =>
						step.recordingParams?.pathChoices.push(
							'#' +
								Math.floor(Math.random() * 16777215)
									.toString(16)
									.toUpperCase()
						)
					}
					startIcon={<AddCircleOutlineIcon />}>
					{t('missionEditor.recordingStep.addChoice')}
				</Button>
			</Box>

			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<span>{t('missionEditor.recordingStep.defaultFillColour')}</span>
				<ColorPicker
					disableAlpha
					disablePlainColor
					onChange={e => setStepProperty(step, 'defaultFill', e.hex ? `#${e.hex}` : `${e}`)}
					value={step.recordingParams?.defaultFill}
				/>
			</Box>
			<Box>
				{t('missionEditor.recordingStep.fillChoices')}
				{step.recordingParams?.fillChoices.map((choice, index) => {
					const choices = step.recordingParams?.fillChoices;

					return (
						<Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '6px', paddingBottom: '6px' }}>
							<span>{index + 1}.</span>
							<ColorPicker
								disableAlpha
								disablePlainColor
								onChange={e => {
									// e is a string when it's edited in the text field, but a object when it's edited
									choices![index] = e.hex ? `#${e.hex}` : `#${e}`;
									setStepProperty(step, `fillChoices`, choices);
								}}
								value={step.recordingParams?.fillChoices[index]}
							/>
							<IconButton
								color="error"
								onClick={() => {
									choices?.splice(index, 1);
									setStepProperty(step, `fillChoices`, choices);
								}}>
								<RemoveCircleOutlineIcon />
							</IconButton>
						</Box>
					);
				})}
				<Button
					onClick={() =>
						step.recordingParams?.fillChoices.push(
							'#' +
								Math.floor(Math.random() * 16777215)
									.toString(16)
									.toUpperCase()
						)
					}
					startIcon={<AddCircleOutlineIcon />}>
					{t('missionEditor.recordingStep.addChoice')}
				</Button>
			</Box>
		</Box>
	);
});
