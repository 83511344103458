// import { ModalProps } from 'models/components/_index'
import React from 'react';
import { useRef } from 'react';

import { ModalWrapper, ModalContainer } from './Modal.style';

interface ModalPropsI {
	isActive: boolean;
	children: React.ReactNode;
	closeModal: () => void;
}

export const Modal: React.FunctionComponent<ModalPropsI> = ({ isActive = false, closeModal, children }) => {
	if (!isActive) return null;
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const containerRef = useRef(null);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	// useOnClickOutside(containerRef, closeModal);

	return (
		<ModalWrapper isActive={isActive}>
			<ModalContainer ref={containerRef}>{children}</ModalContainer>
		</ModalWrapper>
	);
};
