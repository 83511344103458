import { Box, Divider, Typography, Tooltip, TextField, Button } from '@mui/material';
import { t } from 'i18next';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import projectEditorStore, { AvailableLanguages } from '../../../stores/projectEditorStore';
import { ProjectCardsEditor } from './ProjectCardsEditor';

export const ProjectContentEditor = observer((props: { language: AvailableLanguages }) => {
	// load the language
	const { language } = props;
	const { project, errors } = projectEditorStore;

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px', width: '100%' }}>
			<Divider>
				<Typography variant="caption">{t('projectEditor.projectContent.projectCaption')}</Typography>
			</Divider>

			<Tooltip title={t('projectEditor.projectContent.projectNameTooltip') as string} enterDelay={500} leaveDelay={200}>
				<TextField
					label={t('projectEditor.projectContent.projectName')}
					placeholder="Tampere bike survey"
					error={errors[`name.${language}`]}
					helperText={errors[`name.${language}`]}
					value={project.name[language] || ''}
					onChange={e => {
						project.name[language] = e.target.value;
					}}
				/>
			</Tooltip>

			<ProjectCardsEditor language={language} />
		</Box>
	);
});
