import { Box, Divider, Typography, Select, MenuItem } from '@mui/material';
import { t } from 'i18next';
import projectEditorStore, { AvailableLanguages } from '../../../stores/projectEditorStore';
import { InfoTooltip } from './InfoTooltip';
import { observer } from 'mobx-react';

export const EmptyTab = observer(() => {
	const { createNewLocalisation, selectedLanguages, setSelectedLanguage } = projectEditorStore;

	// Find the languages the user has already chosen
	const currentLanguages = selectedLanguages;
	// Remaining languages are the ones that are not yet chosen
	const remainingLanguages = Object.keys(AvailableLanguages).filter(language => !currentLanguages.includes(language));
	// Check if there are any remaining languages for conditional rendering
	const isRemainingLanguages = remainingLanguages.length > 0;

	// Handles the language change by creating a new localisation and changing the tab
	const handleLanguageChange = (event: any) => {
		createNewLocalisation(event.target.value);
		setSelectedLanguage(event.target.value);
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifySelf: 'center',
				maxWidth: '700px',
				width: '100%',
				paddingTop: '10px'
			}}>
			{isRemainingLanguages && (
				<Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%', gap: '10px' }}>
					<div>
						<Divider>
							<Typography variant="caption">{t('projectEditor.selectLanguage')}</Typography>
							<InfoTooltip title={t('projectEditor.selectLanguageTooltip')} />
						</Divider>
					</div>

					<Select onChange={handleLanguageChange} sx={{ maxWidth: '400px', width: '100%', alignSelf: 'center' }}>
						{remainingLanguages.map((language: string) => (
							<MenuItem value={language as AvailableLanguages} key={language}>
								{language}
							</MenuItem>
						))}
					</Select>
				</Box>
			)}

			{!isRemainingLanguages && <Typography variant="h2">No remaining languages</Typography>}
		</Box>
	);
});
