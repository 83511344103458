import { Box } from '@mui/material';
import React, { useContext } from 'react';
import CarchupaDialog from '../../../components/UX/dialog/CarchupaDialog';
import { ModalContext } from '../../../context/modalContext';

interface ImageModalI {
	image: string;
}

export const ImageModal: React.FC<ImageModalI> = ({ image }) => {
	const { handleModal } = useContext(ModalContext);

	const dialogStylesFromProp = {
		maxWidth: '1200px'
	};

	return (
		<CarchupaDialog setOpen={handleModal} open={true} dialogStylesFromProp={dialogStylesFromProp}>
			<Box component="img" src={image} />
		</CarchupaDialog>
	);
};
