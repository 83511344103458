import { useQuery, useQueryClient } from 'react-query';

export enum MainQueryKey {
	mission = 'selected-mission',
	country = 'selected-country',
	fileStatus = 'file-status',
	objectStatus = 'object-status'
}

export enum QueryKey {
	users = 'users',
	projects = 'projects',
	uploads = 'uploads',
	account = 'account',
	userPaymentModal = 'user-payment-modal',
	payments = 'payments',
	globalMap = 'global-map'
}

export const useSelectListQuery = (mainQueryKey: MainQueryKey, queryKey: QueryKey) => {
	const queryClient = useQueryClient();

	const setSelectedOption = (mainQueryKey: MainQueryKey, queryKey: QueryKey, selected?: string | number) => {
		queryClient.setQueryData([mainQueryKey, queryKey], selected || 'all');
	};

	const getSelectedOption = (mainQueryKey: MainQueryKey, queryKey: QueryKey): any => {
		const data: string | undefined = queryClient.getQueryData([mainQueryKey, queryKey]);
		return data !== 'all' ? data : null;
	};

	useQuery([mainQueryKey, queryKey], () => getSelectedOption(mainQueryKey, queryKey), { cacheTime: Infinity });

	return {
		setSelectedOption,
		getSelectedOption
	};
};
