import React, { useEffect, useState } from 'react';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import { API } from '../../../api';
import { Box, CircularProgress, LinearProgress, Table, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ProjectsData } from '../../../models/projects';

const columns: GridColDef[] = [
	{ field: 'id', headerName: 'Project Id', width: 120 },
	{ field: 'projectName', headerName: 'Project Name', width: 250 },
	{
		field: 'missionProgress',
		headerName: 'Mission Progress',
		width: 350,
		renderCell: params => {
			return (
				<Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', top: '10px' }}>
					<Box sx={{ width: '100%', mr: 1 }}>
						<LinearProgress
							sx={{
								padding: '5px',
								height: '25px',
								borderRadius: '4px',
								backgroundColor: '#FDE568',
								'& .MuiLinearProgress-bar': {
									backgroundColor: '#139074'
								}
							}}
							variant="determinate"
							value={params.row.missionProgress || 0}
						/>
					</Box>
					<Box sx={{ minWidth: 35 }}>
						<Typography variant="body2" color="text.secondary">{`${params.row.missionProgress || 0}%`}</Typography>
					</Box>
				</Box>
			);
		}
	},

	{ field: 'remainingRewards', headerName: 'Remaining rewards', width: 180 }
];

export const Dashboard = () => {
	const [projects, setProjects] = useState<ProjectsData[]>([]);
	const [totalItems, setTotalItems] = useState<number>(0);
	const [rows, setRows] = useState<any[]>([]);

	const [paginationModel, setPaginationModel] = useState<any>({
		pageSize: 15,
		page: 0
	});

	useEffect(() => {
		API.getProjectList({ limit: paginationModel.pageSize, page: paginationModel.page + 1, status: 'active' }).then(res => {
			if (res.code === 200) {
				setProjects(res.data.projects);
				setTotalItems(res.data.totalItems);
			}
		});
	}, []);

	useEffect(() => {
		API.getProjectList({ limit: paginationModel.pageSize, page: paginationModel.page + 1, status: 'active' }).then(res => {
			if (res.code === 200) {
				setProjects(res.data.projects);
				setTotalItems(res.data.totalItems);
			}
		});
	}, [paginationModel]);

	const onPaginationModelChange = (newModel: any) => {
		setPaginationModel(newModel);
	};

	useEffect(() => {
		const newRows = projects.map(project => {
			return {
				id: project.id,
				projectName: project.name,
				missionProgress: project.missionProgress,
				remainingRewards: project.remainingRewards
			};
		});
		setRows(newRows);
	}, [projects]);

	return (
		<SimpleLayout passedStyles="">
			<Typography variant="h4">Active Projects</Typography>
			<DataGrid
				rowCount={totalItems}
				paginationMode="server"
				paginationModel={paginationModel}
				onPaginationModelChange={newModel => onPaginationModelChange(newModel)}
				pagination
				columns={columns}
				rows={rows}
			/>
		</SimpleLayout>
	);
};
