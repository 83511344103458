import { useQuery, useQueryClient } from 'react-query';

enum MainQueryKey {
	searchInput = 'search-input'
}

export enum InputSearchQueryKey {
	users = 'users',
	uploads = 'uploads'
}

export const useSearchInputQuery = (queryKey: InputSearchQueryKey) => {
	const queryClient = useQueryClient();

	const setSearchTerm = (queryKey: InputSearchQueryKey, term?: string | number) => {
		queryClient.setQueryData([MainQueryKey.searchInput, queryKey], term);
	};

	const getSearchTerm = (queryKey: InputSearchQueryKey): any => {
		const data: string | undefined = queryClient.getQueryData([MainQueryKey.searchInput, queryKey]);
		return data;
	};

	const { data } = useQuery([MainQueryKey.searchInput, queryKey], () => getSearchTerm(queryKey), { cacheTime: Infinity });

	return {
		setSearchTerm,
		getSearchTerm,
		searchTerm: data
	};
};
