import React, { forwardRef, useEffect, useState } from 'react';
import { DevTool } from '@hookform/devtools';
import styles from './SeeProject.module.scss';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import { API } from '../../../api';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Button, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { Countries, Objects, oldReferenceImages, Tags, Vehicles } from '../../../models/general';
import file from '../../../assets/file-icon.svg';
import CrowdsorsaRadioButton from '../../../components/UX/crowdsorsa-radio-button/CrowdsorsaRadioButton';
import CrowdsorsaCheckbox from '../../../components/UX/crowdsorsa-checkbox/CrowdsorsaCheckbox';
import ErrorMessage from '../../../components/UI/error-message/ErrorMessage';
import * as Yup from 'yup';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { yupResolver } from '@hookform/resolvers/yup';
import { SingleProjectData } from '../../../models/projects';
import { DateTimePicker } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterMoment';
import { useHistory } from 'react-router-dom';
import arrowLeft from '../../../assets/arrow-left.svg';
import CrowdsorsaImageCheckbox from '../../../components/UX/crowdsorsa-image-checkbox/CrowdsorsaImageCheckbox';
interface Props {}

interface FormValues {
	MissionType: number;
	RequiredVehicle: number;
	Country?: number;
	MissionName?: string;
	MissionDescription: string;
	Lattitude: any;
	Longitude: any;
	CSVFile?: any;
	Tags: any;
	DateStart: any;
	Objects: any;
}

const EditProject = forwardRef<HTMLDivElement, Props>(ref => {
	const { t } = useTranslation();
	const [countries, setCountries] = useState<Countries[]>([]);
	const [vehicles, setVehicles] = useState<Vehicles[]>([]);
	const [tags, setTags] = useState<Tags[]>([]);
	const [projectData, setProjectData] = useState<SingleProjectData>();
	const [formObjects, setFormObjects] = useState<Objects[]>([]);
	const [vehicleState, setVehicleState] = useState(1);
	const [refreshFlag, setRefreshFlag] = useState<boolean>(true);
	const [formattedObjectData, setFormattedObjectData] = useState<Objects[]>([]);
	const [oldReferenceImages, setOldReferenceImages] = useState<oldReferenceImages[]>([]);

	const schema = Yup.object().shape({
		MissionType: Yup.number().typeError(`${t('selectTheMissionTypeMessage')}`),
		RequiredVehicle: Yup.number().typeError(`${t('selectTheRequiredVehicle')}`),
		Country: Yup.number(),
		MissionName: Yup.string().max(40, `${t('mustBeLessThan40Characters')}`),
		MissionDescription: Yup.string().max(1500, `${t('mustBeLessThan1500Characters')}`),
		DateStart: Yup.mixed().typeError(`${t('insertValidDateMessage')}`),
		Lattitude: Yup.number().typeError(`${t('latitudeMustBeNumber')}`),
		Longitude: Yup.number().typeError(`${t('longitudeMustBeNumber')}`),
		Tags: Yup.array().typeError(`${t('selectAtLeastOneTag')}`)
	});

	const {
		control,
		reset,
		register,
		formState: { errors },
		getValues
	} = useForm<FormValues>({
		resolver: yupResolver(schema),
		mode: 'all'
	});

	const url = window.location.pathname;
	const currentProjectId = url.substring(url.lastIndexOf('/') + 1, url.length);
	const history = useHistory();

	const fetchCountries = async () => {
		const res = await API.getCountries();
		setCountries(res.data.countries);
	};

	const fetchTags = async () => {
		const res = await API.getTags();
		setTags(res.data.tags);
	};

	const fetchVehicles = async () => {
		const res = await API.getVehicles();
		setVehicles(res.data.vehicles);
	};

	const fetchObjects = async () => {
		const res = await API.getObjects();
		let objects = res.data.objects;
		objects.forEach(obj => (obj.price = 0)); //Adding price property so we can modify it dynamically trough the form
		setFormObjects(res.data.objects);
	};

	const fetchProjectData = async () => {
		const res = await API.getProject(+currentProjectId);
		setProjectData(res.data.project);
		res.data.project?.vehicle?.id && setVehicleState(res.data.project.vehicle.id);
		res.data.project.references && setOldReferenceImages(res.data.project.references);
		reset({
			DateStart: res.data.project.dateStart * 1000,
			Country: res.data.project.countryId,
			MissionDescription: res.data.project.description,
			MissionName: res.data.project.name,
			Lattitude: res.data.project.lat,
			Longitude: res.data.project.lon,
			Tags: res.data.project.tags.map(tag => tag.id),
			Objects: res.data.project.objects ? res.data.project.objects.map(a => JSON.stringify(a.type)) : ''
		});
	};

	useEffect(() => {
		fetchProjectData();
		fetchCountries();
		fetchTags();
		fetchVehicles();
		fetchObjects();
	}, []);

	const formValues = getValues();

	const setInitialObjectPrices = () => {
		const newArr: Objects[] = [];
		if (projectData?.objects && formObjects) {
			formObjects.forEach(obj => {
				projectData?.objects?.forEach(dataObj => {
					if (obj.ID === dataObj.type) {
						newArr.push({ ...obj, price: dataObj.value, canEdit: dataObj.canEdit });
					}
				});
			});
		}
		const objectsWithZeroPrice = formObjects.filter(obj => {
			return !newArr.some(dataObj => {
				return obj.ID === dataObj.ID;
			});
		});

		const finalArray = newArr.concat(objectsWithZeroPrice).sort((a, b) => a.ID - b.ID);

		return finalArray;
	};

	useEffect(() => {
		setFormattedObjectData(setInitialObjectPrices());
	}, [formObjects, projectData]);

	return formValues ? (
		<SimpleLayout passedStyles={styles.root}>
			<CarchupaPaper type="column" parentStyle={`${styles.paper} ${styles.messagebox}`} childStyle={styles.generalInfo} removeBoxShadow>
				<div className={styles.backButton}>
					<Button onClick={history.goBack} sx={{ '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' } }} variant="text" color="primary">
						<img src={arrowLeft} alt={'arrow left'} />
						{t('backTo')} {formValues.MissionName}
					</Button>
				</div>
				<div className={styles.createMissionHeader}>
					<Typography variant="h1">{formValues.MissionName ? formValues.MissionName : ''}</Typography>
				</div>
				<form>
					<Controller
						name="MissionType"
						control={control}
						render={() => {
							return (
								<div className={styles.radioRoot}>
									<InputLabel sx={{ marginBottom: '10px', color: '#494F4D' }}>{t('missionType')}</InputLabel>
									<div className={styles.radioContainer}>
										<CrowdsorsaRadioButton
											register={register}
											isChecked={true}
											value={1}
											registerValue={'MissionType'}
											inputName="MissionType"
											inputId={projectData?.projectTypeId === 1 ? 'Video mission' : 'Photo mission'}
										/>
									</div>
									<ErrorMessage>{errors.MissionType?.message}</ErrorMessage>
								</div>
							);
						}}
					/>

					{projectData?.projectTypeId === 1 && (
						<Controller
							name="RequiredVehicle"
							control={control}
							render={() => {
								return (
									<div className={styles.radioRoot}>
										<InputLabel sx={{ marginBottom: '10px', color: '#494F4D' }}>{t('requiredVehicle')}</InputLabel>
										<div className={styles.radioContainer}>
											{vehicles.map(vehicle => {
												return (
													<CrowdsorsaRadioButton
														isDisabled
														key={vehicle.id}
														isChecked={vehicle.id === vehicleState}
														register={register}
														registerValue={'RequiredVehicle'}
														value={vehicle.id}
														inputName="RequiredVehicle"
														inputId={vehicle.name}
													/>
												);
											})}
										</div>
										<ErrorMessage>{errors.RequiredVehicle?.message}</ErrorMessage>
									</div>
								);
							}}
						/>
					)}

					<Controller
						name="Country"
						control={control}
						render={({ field }) => {
							return (
								<>
									<InputLabel sx={{ color: '#494F4D' }}>{t('country')}</InputLabel>
									<Select
										disabled
										{...field}
										value={field.value ?? ' '}
										sx={{ width: '648px', border: 'none' }}
										id="Country"
										error={!!errors.Country?.message?.length}
										defaultValue={projectData?.countryId ? projectData.countryId : 0}>
										{countries.map(country => (
											<MenuItem key={country.id} value={country.id}>
												{country.name}
											</MenuItem>
										))}
									</Select>
									<ErrorMessage>{errors.Country?.message}</ErrorMessage>
								</>
							);
						}}
					/>

					<Controller
						name="MissionName"
						control={control}
						render={({ field }) => {
							return (
								<>
									<InputLabel sx={{ marginTop: '20px', color: '#494F4D' }}>{t('missionName')}</InputLabel>
									<TextField
										disabled
										{...field}
										value={field.value ?? ' '}
										onChange={e => field.onChange(e.target.value)}
										onBlur={field.onBlur}
										placeholder={projectData?.name}
										sx={{
											'.MuiOutlinedInput-root': {
												width: '648px',
												border: 'none'
											}
										}}
										id="missionName"
										rows={1}
										error={!!errors.MissionName?.message?.length}
									/>
									<ErrorMessage>{errors.MissionName?.message}</ErrorMessage>
								</>
							);
						}}
					/>

					<Controller
						name="MissionDescription"
						control={control}
						render={({ field }) => {
							return (
								<>
									<InputLabel sx={{ marginTop: '20px', color: '#494F4D' }}>{t('missionDescription')}</InputLabel>
									<TextField
										disabled
										{...field}
										value={field.value ?? ' '}
										placeholder={projectData?.description}
										sx={{
											'.MuiOutlinedInput-root': {
												width: '648px',
												border: 'none',
												maxHeight: 'unset'
											}
										}}
										id="missionDescription"
										multiline
										rows={5}
										error={!!errors.MissionDescription?.message?.length}
									/>
									<ErrorMessage>{errors.MissionDescription?.message}</ErrorMessage>
								</>
							);
						}}
					/>

					<div className={styles.twoInRow}>
						<Controller
							name="Lattitude"
							control={control}
							render={({ field }) => {
								return (
									<div>
										<InputLabel sx={{ marginTop: '20px', color: '#494F4D' }}>{t('lattitude')}</InputLabel>
										<TextField
											disabled
											{...field}
											value={field.value ?? ' '}
											placeholder={projectData?.lat}
											sx={{
												'.MuiOutlinedInput-root': {
													width: '312px',
													border: 'none'
												}
											}}
											id="Lattitude"
											error={!!errors.Lattitude?.message?.length}
										/>
										<ErrorMessage>{errors.Lattitude?.message}</ErrorMessage>
									</div>
								);
							}}
						/>
						<Controller
							name="Longitude"
							control={control}
							render={({ field }) => {
								return (
									<div>
										<InputLabel sx={{ marginTop: '20px', color: '#494F4D' }}>{t('longitude')}</InputLabel>
										<TextField
											disabled
											{...field}
											value={field.value ?? ' '}
											placeholder={projectData?.lon}
											sx={{
												'.MuiOutlinedInput-root': {
													width: '312px',
													border: 'none'
												}
											}}
											id="Longitude"
											error={!!errors.Longitude?.message?.length}
										/>
										<ErrorMessage>{errors.Longitude?.message}</ErrorMessage>
									</div>
								);
							}}
						/>
					</div>

					<LocalizationProvider dateAdapter={DateAdapter}>
						<Controller
							name="DateStart"
							control={control}
							render={({ field }) => {
								return (
									<div>
										<InputLabel sx={{ marginTop: '20px', color: '#494F4D' }}>{t('dateTimeStart')}</InputLabel>
										<DateTimePicker
											disabled
											ampm={false}
											value={field.value}
											onChange={(date: any) => field.onChange(date)}
											renderInput={params => <TextField sx={{ width: '312px' }} {...params} />}
										/>
										<ErrorMessage>{errors.DateStart?.message}</ErrorMessage>
									</div>
								);
							}}
						/>
					</LocalizationProvider>

					{projectData?.projectTypeId === 2 && (
						<>
							<Controller
								name="Objects"
								control={control}
								render={() => {
									return (
										<div className={styles.radioRoot}>
											<InputLabel sx={{ margin: '20px 0 20px', color: '#494F4D' }}>{t('selectObjects')}</InputLabel>
											<div className={styles.radioContainer}>
												{formattedObjectData.map((object, index) => (
													<CrowdsorsaImageCheckbox
														canEdit={false}
														isChecked={object.price > 0}
														refreshFlag={refreshFlag}
														setRefreshFlag={setRefreshFlag}
														key={object.ID}
														register={register}
														value={object.ID}
														registerValue={'Objects'}
														inputName="Objects"
														inputId={`Object ${object.name}b`}
													/>
												))}{' '}
											</div>
											<ErrorMessage>{errors.Tags?.message}</ErrorMessage>
										</div>
									);
								}}
							/>
							<div>
								{formValues.Objects
									? formValues.Objects.map((object: string, index: number) => {
											return (
												<div key={index}>
													<InputLabel sx={{ marginTop: '5px', color: '#494F4D' }}>{`Object ${object} price*`}</InputLabel>
													<TextField
														disabled
														value={formattedObjectData.find(obj => obj.ID === +object)?.price}
														sx={{
															'.MuiOutlinedInput-root': {
																width: '648px',
																border: 'none'
															}
														}}
														id="objectPrice"
														rows={1}
														inputProps={{ min: '0' }}
														type="number"
													/>
												</div>
											);
									  })
									: null}
							</div>

							<div className={styles.dropzoneLabelContainer}>
								<img src={file} alt={'fileIcon'} />
								<InputLabel sx={{ color: '#494F4D' }}>{t('uploadReferencePhotos')}</InputLabel>
							</div>
							{!!oldReferenceImages &&
								oldReferenceImages.map((image: oldReferenceImages, index: number) => (
									<div className={styles.uploadedImagesFileContainer}>
										<img height={108} width={88} src={image.URL} alt={'file'} />
										<div className={styles.uploadedImagesFile}>
											<span>{image.caption}</span>
											<TextField
												disabled
												value={image.caption}
												sx={{
													'.MuiOutlinedInput-root': {
														width: '544px',
														border: 'none',
														marginTop: '12px'
													}
												}}
												id="imageCaption"
												rows={1}
											/>
										</div>
									</div>
								))}
						</>
					)}

					<Controller
						name="Tags"
						control={control}
						render={() => {
							return (
								<div className={styles.radioRoot}>
									<InputLabel sx={{ margin: '20px 0 10px', color: '#494F4D' }}>{t('selectTags')}</InputLabel>
									<div className={styles.radioContainer}>
										{tags.map(tag => (
											<CrowdsorsaCheckbox
												isDisabled
												isChecked={projectData?.tags.some(a => a.name === tag.name)}
												key={tag.id}
												register={register}
												tagLabel={tag.name}
												value={tag.id}
												registerValue={'Tags'}
												inputName="Tags"
												inputId={`Tag ${tag.name}`}
											/>
										))}{' '}
										{/* "Tag" in inputId is added because there might be a same name like e.g. "Car" used in radio buttons which is causing bugs */}
									</div>
									<ErrorMessage>{errors.Tags?.message}</ErrorMessage>
								</div>
							);
						}}
					/>
				</form>
				<DevTool control={control} placement="top-right" />
			</CarchupaPaper>
		</SimpleLayout>
	) : (
		<></>
	);
});

export default inject('settingsStore', 'userStore', 'messageStore')(observer(EditProject));
