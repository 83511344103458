import { Button, DialogContent, DialogTitle, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React, { ChangeEvent, Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from '../../../api';
import { RejectionReasonsData } from '../../../models/general';

interface Props {
	handleDataChange: ({ currentTarget }: ChangeEvent<HTMLInputElement>) => void;
	handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
	setOpen(valid: boolean): void;
	setRejectionReasonState: React.Dispatch<React.SetStateAction<number>>;
	rejectionReasonState: number;
	data: Record<string, string>;
	setData: Dispatch<SetStateAction<{ message: string }>>;
}

const VideoModalReject = ({ handleDataChange, handleSubmit, setOpen, setRejectionReasonState, rejectionReasonState, data, setData }: Props) => {
	const [rejectionReasons, setRejectionReasons] = useState<RejectionReasonsData[]>([]);
	const [disableCustomReason, setDisableCustomReason] = useState<boolean>(true);

	const fetchRejectionReasons = async () => {
		const res = await API.getRejectionReasons();
		setRejectionReasons([...res.data.rejectionReasons, { id: res.data.rejectionReasons.length + 1, description: 'Custom' }]);
	};

	useEffect(() => {
		fetchRejectionReasons();
	}, []);

	const { t } = useTranslation();

	useEffect(() => {
		if (rejectionReasons.length === rejectionReasonState && rejectionReasons.length !== 0) {
			setDisableCustomReason(false);
		} else {
			setData && setData({ message: '' });
		}
	}, [rejectionReasonState, rejectionReasons]);

	const dialogContentStyles = {
		display: 'flex',
		flexDirection: 'column',
		paddingTop: '0px'
	};

	const buttonStyles = {
		position: 'relative',
		bottom: '26px',
		left: '26px',
		padding: '8px 20px',
		marginTop: '30px',
		marginBottom: '-10px'
	};

	const textFieldStyles = {
		'.MuiOutlinedInput-root': {
			width: '400px',
			height: '150px',
			paddingTop: '0px',
			maxHeight: 'unset'
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<DialogTitle sx={{ fontWeight: 700 }} id="form-dialog-title">
				{t('rejectionReason')}
			</DialogTitle>
			<DialogContent sx={dialogContentStyles}>
				<InputLabel>{t('rejectionReason')}</InputLabel>
				<Select
					id="RejectionReason"
					onChange={(e: any) => {
						setRejectionReasonState && setRejectionReasonState(e.target.value);
						setDisableCustomReason(true);
					}}>
					{rejectionReasons.map(item => (
						<MenuItem key={item.id} value={item.id}>
							{item.description}
						</MenuItem>
					))}
				</Select>
				<InputLabel sx={{ marginTop: '20px', color: data.message.length >= 255 ? '#e74c3c' : undefined, transition: 'color 0.5s' }}>
					{t('customReason')} ({data.message.length} / 255)
				</InputLabel>
				<TextField
					value={data && data.message}
					disabled={disableCustomReason}
					sx={textFieldStyles}
					id="message"
					multiline
					onChange={handleDataChange}
					rows={4}
				/>
			</DialogContent>
			<Button
				disabled={!rejectionReasonState || (rejectionReasonState === rejectionReasons.length && !data.message) || data.message.length >= 255}
				size="large"
				type="submit"
				variant="contained"
				onClick={() => setOpen(false)}
				color="primary"
				sx={buttonStyles}>
				{t('send')}
			</Button>
		</form>
	);
};

export default VideoModalReject;
