import { useEffect, useState } from 'react';

interface ReturnValue {
	width: number;
}

const useResize = (): ReturnValue => {
	const [width, setWidth] = useState(-1);

	const handleResize = (): void => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		setWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);

		return (): void => window.removeEventListener('resize', handleResize);
	}, []);

	return {
		width
	};
};

export default useResize;
