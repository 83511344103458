import styled from '@emotion/styled'

interface Props {
    error?: boolean
}

export const Container = styled.div<Props>` 
    background: #F9F8F2;
    border: 1px dashed ${props => (props.error ? '#FF8178' : '#A4A4A4')};
    
    box-sizing: border-box;
    border-radius: 8px;
    width: 648px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;   
`
