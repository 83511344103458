import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { API } from '../../../api';
import downloadIcon from '../../../assets/download-icon.svg';
import trashIcon from '../../../assets/trash.svg';

interface Props {
	selected?: number[];
	handleDownloadUserCSV?: (userIds: number[]) => void;
}

export const UsersToolboxBody = ({ selected, handleDownloadUserCSV }: Props) => {
	const { t } = useTranslation();

	const fetchDeleteSelectedUserRows = async (userIds: number[]) => {
		try {
			const response = await API.deleteUsers(userIds);
			if (response) window.location.reload();
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<>
			<Button
				color="primary"
				startIcon={<img src={downloadIcon} alt="export" />}
				variant="text"
				onClick={() => selected && handleDownloadUserCSV && handleDownloadUserCSV(selected)}>
				{t('exportSelectedRows')}
			</Button>
			<Button
				color="error"
				startIcon={<img src={trashIcon} alt="delete" />}
				variant="text"
				onClick={() => selected && fetchDeleteSelectedUserRows(selected)}>
				{t('deleteSelectedRows')}
			</Button>
		</>
	);
};
