import { observable, computed, action, decorate, IObservableArray } from 'mobx';

type StateType = { [x: string]: number | string };
type StateAsPropType = IObservableArray<StateType>;

// type FileStatusStateType = { [x: string]: string };
// type FileStatusStateAsPropType = IObservableArray<FileStatusStateType>;

export class SelectListsOptionsStore {
	missionSelectListOptions = observable<StateType>([]);
	userSelectListOptions = observable<StateType>([]);
	fileStatusSelectListOptions = observable<StateType>([]);
	countrySelectListOptions = observable<StateType>([]);

	private addOption(pageName: string, value: number | string, state: StateAsPropType) {
		const isPageExists = state.every(option => option[pageName]);

		state.length === 0 && state.push({ [pageName]: value });

		if (!isPageExists) {
			state.push({ [pageName]: value });
		}
	}

	private select(pageName: string, value: number | string, state: StateAsPropType) {
		state.forEach(options => {
			if (options[pageName]) {
				options[pageName] = value;
			}
		});
	}

	// MISSION OPTION HANDLERS
	createMissionOptions(pageName: string, value: number | string) {
		this.addOption(pageName, value, this.missionSelectListOptions);
	}

	selectNewMissionOption(pageName: string, value: number | string) {
		this.select(pageName, value, this.missionSelectListOptions);
	}

	get addNewMissionOptions() {
		return this.createMissionOptions.bind(this);
	}

	get selectMissionOption() {
		return this.selectNewMissionOption.bind(this);
	}

	// USER OPTIONS HANDLERS
	createUserOptions(pageName: string, value: number | string) {
		this.addOption(pageName, value, this.userSelectListOptions);
	}

	selectNewUserOption(pageName: string, value: number | string) {
		this.select(pageName, value, this.userSelectListOptions);
	}

	get addNewUserOptions() {
		return this.createUserOptions.bind(this);
	}

	get selectUserOption() {
		return this.selectNewUserOption.bind(this);
	}

	// FILE STATUS OPTIONS HANDLERS
	createFileStatusOptions(pageName: string, value: number | string) {
		this.addOption(pageName, value, this.fileStatusSelectListOptions);
	}

	selectNewFileStatusOption(pageName: string, value: number | string) {
		this.select(pageName, value, this.fileStatusSelectListOptions);
	}

	get addNewFileStatusOptions() {
		return this.createFileStatusOptions.bind(this);
	}

	get selectFileStatusOption() {
		return this.selectNewFileStatusOption.bind(this);
	}

	// COUNTRY STATUS OPTIONS HANDLERS
	createCountryOptions(pageName: string, value: number | string) {
		this.addOption(pageName, value, this.countrySelectListOptions);
	}

	selectNewCountryOption(pageName: string, value: number | string) {
		this.select(pageName, value, this.countrySelectListOptions);
	}

	get addNewCountryOptions() {
		return this.createCountryOptions.bind(this);
	}

	get selectCountryOption() {
		return this.selectNewCountryOption.bind(this);
	}
}

decorate(SelectListsOptionsStore, {
	// MISSION
	missionSelectListOptions: observable,
	createMissionOptions: action,
	selectNewMissionOption: action,
	addNewMissionOptions: computed,
	selectMissionOption: computed,

	// USER
	userSelectListOptions: observable,
	createUserOptions: action,
	selectNewUserOption: action,
	addNewUserOptions: computed,
	selectUserOption: computed,

	// FILE STATUS
	fileStatusSelectListOptions: observable,
	createFileStatusOptions: action,
	selectNewFileStatusOption: action,
	addNewFileStatusOptions: computed,
	selectFileStatusOption: computed,

	// COUNTRY
	countrySelectListOptions: observable,
	createCountryOptions: action,
	selectNewCountryOption: action,
	addNewCountryOptions: computed,
	selectCountryOption: computed
});

export default new SelectListsOptionsStore();
