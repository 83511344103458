import { Menu, MenuItem } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import LanguageIcon from '@material-ui/icons/Language';
import {
	AppBar,
	CssBaseline,
	Drawer,
	List,
	ListItem,
	Theme,
	Toolbar,
	ListItemIcon,
	ListItemText,
	Grid,
	Button,
	Dialog,
	DialogContent
} from '@mui/material';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import AdminRoute from '../../../components/UX/system/AdminRoute';
import SuperAdminRoute from '../../../components/UX/system/SuperAdminRoute';
import { ROUTES } from '../../../parameters/routes';
import MessageService from '../../../services/messages/MessageService';
import authStore from '../../../stores/authStore';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import sessionsStore from '../../../stores/sessionsStore';
import Coins from '../coins/Coins';
import { AllUserPayments } from '../finance/AllUserPayments';
import GlobalMap from '../globalMap/GlobalMap';
import Leaderboard from '../leaderboard/Leaderboard';
import Notifications from '../notifications/Notifications';
import Project from '../project/Project';
import Projects from '../projects/Projects';
import Sessions from '../sessions/Sessions';
import Superadmin from '../superadmin/Superadmin';
import { ProjectMap } from '../projectMap/ProjectMap';
import { Account } from '../user/Account';
import UserPayments from '../userPayments/UserPayments';
import { Users } from '../users/Users';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import Video from '../video/Video';
import { Uploads } from '../videos/Uploads';
import styles from './Home.module.scss';
import ExpandIcon from '../../../assets/expand-icon.svg';
import ShrinkIcon from '../../../assets/shrink-icon.svg';
import Logo from '../../../assets/Logo.svg';
import SeeProject from '../seeProject/SeeProject';
import { SystemAdministration } from '../system-administration/SystemAdministration';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

import { ImageDetailPage } from '../image-detail-page';
import ContractAdminRoute from '../../../components/UX/system/ContractAdminRoute';

import {
	LogoutOutlined,
	PersonOutlineOutlined,
	PeopleAltOutlined,
	FormatListBulletedOutlined,
	CloudUploadOutlined,
	MapOutlined,
	EuroOutlined,
	LeaderboardOutlined,
	ChatBubbleOutline,
	AdminPanelSettingsOutlined
} from '@mui/icons-material';
import MissionEditor from '../missionEditor/MissionEditor';
import ProjectEditor from '../projectEditor/ProjectEditor';
import { InvasiveSpeciesSessionViewer } from '../invasiveSessionViewer/InvasiveSpeciesSessionViewer';
import { Dashboard } from '../dashboard/Dashboard';

const Home = (props: RouteComponentProps) => {
	const { history } = props;
	const { t, i18n } = useTranslation();
	const [openSideNav, setIsSideNavOpen] = useState(localStorage.getItem('openSideNav') ? true : false);
	const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState<boolean>(false);

	const { adminRole } = authStore;
	const drawerWidth = openSideNav ? 163 : 55;

	const setOpenSideNav = () => {
		localStorage.setItem('openSideNav', openSideNav ? '' : 'open');
		setIsSideNavOpen(!openSideNav);
	};

	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			root: {
				display: 'flex'
			},
			appBar: {
				'&.MuiAppBar-root': {
					overflow: 'hidden',
					backgroundColor: '#0A775F',
					zIndex: theme.zIndex.drawer + 1,
					color: 'white',
					boxShadow: 'unset'
				}
			},
			drawer: {
				width: openSideNav ? '163px' : '55px',
				flexShrink: 0
			},
			drawerPaper: {
				width: drawerWidth
			},
			drawerContainer: {
				overflow: 'hidden',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between'
			},
			content: {
				flexGrow: 1,
				transition: 'all 150ms ease-in-out',
				height: 'calc(100vh - 64px)'
			}
		})
	);
	const [languageAnchorEl, setLanguageAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleLanguageClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setLanguageAnchorEl(event.currentTarget);
	};

	const handleLanguageClose = () => {
		setLanguageAnchorEl(null);
	};

	const classes = useStyles();
	const customclass = useMemo(
		() =>
			classnames({
				[styles.smallSideNav]: !openSideNav
			}),
		[openSideNav]
	);

	const setPage = (route: string) => {
		history.push(`${route}`);
	};

	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng);
		localStorage.setItem('lng', lng);
		handleLanguageClose();
	};

	return (
		<div className={classes.root}>
			<CssBaseline />
			<MessageService />
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar sx={{ '&.MuiToolbar-root': { paddingLeft: '16px' } }}>
					<Grid container direction="row" justifyContent="space-between" alignItems="center">
						<Grid>
							<Grid container direction="row" justifyContent="flex-start" alignItems="center">
								{/* <Typography variant="h2" noWrap color="white">
									crowdsorsa {userStore.selectedUser ? `/ ${t('User')} ${userStore.selectedUser.ID}` : null}{' '}
									okojasd
									{sessionsStore.selectedSession ? `/ ${t('Session')} ${sessionsStore.selectedSession.ID}` : null}
								</Typography> */}
								<img src={Logo} alt="crowdsorsaLogo"></img>
							</Grid>
						</Grid>
						<Grid>
							<Button aria-controls={'language-menu'} className={styles.languageMenu} aria-haspopup="true" onClick={handleLanguageClick}>
								<LanguageIcon />
							</Button>
							<Menu id={'language-menu'} anchorEl={languageAnchorEl} keepMounted open={Boolean(languageAnchorEl)} onClose={handleLanguageClose}>
								<MenuItem
									onClick={() => changeLanguage('fi')}
									className={classnames({
										[styles.hightlight]: i18n.language === 'fi'
									})}>
									{t('fi')}
								</MenuItem>
								<MenuItem
									onClick={() => changeLanguage('en')}
									className={classnames({
										[styles.hightlight]: i18n.language === 'en'
									})}>
									{t('en')}
								</MenuItem>
								<MenuItem
									onClick={() => changeLanguage('es')}
									className={classnames({
										[styles.hightlight]: i18n.language === 'es'
									})}>
									{t('es')}
								</MenuItem>
								<MenuItem
									onClick={() => changeLanguage('se')}
									className={classnames({
										[styles.hightlight]: i18n.language === 'se'
									})}>
									{t('se')}
								</MenuItem>
							</Menu>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<Drawer
				className={`${classes.drawer} ${customclass}`}
				variant="permanent"
				classes={{
					paper: classes.drawerPaper
				}}>
				<Toolbar />
				<div className={`${classes.drawerContainer} ${styles.drawerContainer}`}>
					<div>
						<List sx={{ backgroundColor: '#0A775F' }}>
							{adminRole === 'superadmin' || adminRole === 'admin' ? (
								<ListItem
									sx={{
										height: '56px',
										'&.Mui-selected': { backgroundColor: '#19BD98' }
									}}
									button
									selected={history.location.pathname.includes(ROUTES.dashboard)}
									classes={{
										selected: styles.x
									}}
									onClick={() => setPage(ROUTES.dashboard)}>
									<ListItemIcon>
										<DashboardOutlinedIcon sx={{ color: 'white ' }} />
									</ListItemIcon>
									<ListItemText sx={{ span: { color: 'white' }, marginLeft: '-18px' }} primary={t('Users')} />
								</ListItem>
							) : null}
							{adminRole === 'superadmin' || adminRole === 'admin' ? (
								<ListItem
									sx={{
										height: '56px',
										'&.Mui-selected': { backgroundColor: '#19BD98' }
									}}
									button
									selected={history.location.pathname.includes(ROUTES.users)}
									classes={{
										selected: styles.x
									}}
									onClick={() => setPage(ROUTES.users)}>
									<ListItemIcon>
										<PeopleAltOutlined sx={{ color: 'white ' }} />
									</ListItemIcon>
									<ListItemText sx={{ span: { color: 'white' }, marginLeft: '-18px' }} primary={t('Users')} />
								</ListItem>
							) : null}
							{(adminRole === 'superadmin' || adminRole === 'contractAdmin') && (
								<>
									<ListItem
										sx={{ height: '56px', '&.Mui-selected': { backgroundColor: '#19BD98' } }}
										button
										selected={history.location.pathname.includes(ROUTES.projects)}
										onClick={() => setPage(ROUTES.projects)}>
										<ListItemIcon>
											<FormatListBulletedOutlined sx={{ color: 'white ' }} />
										</ListItemIcon>
										<ListItemText sx={{ span: { color: 'white' }, marginLeft: '-18px' }} primary={t('missions')} />
									</ListItem>
								</>
							)}
							{/* <ListItem
                button
                selected={history.location.pathname.includes("sessions")}
                classes={{
                  selected: styles.highlight,
                }}
                onClick={() => {
                  sessionsStore.updateSession(undefined);
                  userStore.selectedUser
                    ? setPage(
                        ROUTES.createUserSessionsUrl(userStore.selectedUser.ID)
                      )
                    : setPage(ROUTES.sessions);
                }}
              >
                <ListItemIcon>
                  <DirectionsCarIcon />
                </ListItemIcon>
                <ListItemText primary={t("Sessions")} />
              </ListItem> */}
							<ListItem
								sx={{ height: '56px', '&.Mui-selected': { backgroundColor: '#19BD98' } }}
								button
								selected={history.location.pathname.includes('uploads')}
								onClick={() => {
									sessionsStore.selectedSession ? setPage(ROUTES.createVideosUrl(sessionsStore.selectedSession.ID)) : setPage(ROUTES.uploads);
								}}>
								<ListItemIcon>
									<CloudUploadOutlined sx={{ color: 'white ' }} />
								</ListItemIcon>
								<ListItemText sx={{ span: { color: 'white' }, marginLeft: '-18px' }} primary={t('uploads')} />
							</ListItem>
							<ListItem
								sx={{ height: '56px', '&.Mui-selected': { backgroundColor: '#19BD98' } }}
								button
								selected={history.location.pathname.includes('global-map')}
								onClick={() => setPage(ROUTES.globalmap)}>
								<ListItemIcon>
									<MapOutlined sx={{ color: 'white' }} />
								</ListItemIcon>
								<ListItemText sx={{ span: { color: 'white' }, marginLeft: '-18px' }} primary={t('globalMap.mapLabelText')} />
							</ListItem>
							<ListItem
								sx={{ height: '56px', '&.Mui-selected': { backgroundColor: '#19BD98' } }}
								button
								selected={history.location.pathname.includes('projectMap')}
								onClick={() => setPage(ROUTES.projectMap)}>
								<ListItemIcon>
									<PublicOutlinedIcon sx={{ color: 'white' }} />
								</ListItemIcon>
								<ListItemText sx={{ span: { color: 'white' }, marginLeft: '-18px' }} primary={t('globalMap.mapLabelText')} />
							</ListItem>
							{adminRole === 'superadmin' && (
								<>
									<ListItem
										sx={{ height: '56px', '&.Mui-selected': { backgroundColor: '#19BD98' } }}
										button
										selected={history.location.pathname.includes('payments')}
										onClick={() => setPage(ROUTES.payments)}>
										<ListItemIcon>
											<EuroOutlined sx={{ color: 'white' }} />
										</ListItemIcon>
										<ListItemText sx={{ span: { color: 'white' }, marginLeft: '-18px' }} primary={t('Payments')} />
									</ListItem>
									<ListItem
										sx={{ height: '56px', '&.Mui-selected': { backgroundColor: '#19BD98' } }}
										button
										selected={history.location.pathname.includes('leaderboard')}
										onClick={() => setPage(ROUTES.leaderboard)}>
										<ListItemIcon>
											<LeaderboardOutlined sx={{ color: 'white' }} />
										</ListItemIcon>
										<ListItemText sx={{ span: { color: 'white' }, marginLeft: '-18px' }} primary={t('Leaderboard')} />
									</ListItem>
									{/* <ListItem
									button
									selected={history.location.pathname.includes('coin-upload')}
									onClick={() => setPage(ROUTES.coinUpload)}>
									<ListItemIcon>
										<img src={NotificationsIcon} alt={'notifications'}/>
									</ListItemIcon>
									<ListItemText sx={{span: {color: 'white'}, marginLeft:'-18px'}}  primary={t('Coins')} />
								</ListItem> */}
								</>
							)}
							{adminRole === 'superadmin' || adminRole === 'admin' ? (
								<>
									<ListItem
										sx={{ height: '56px', '&.Mui-selected': { backgroundColor: '#19BD98' } }}
										button
										selected={history.location.pathname.includes('notifications')}
										onClick={() => setPage(ROUTES.notifications)}>
										<ListItemIcon>
											<ChatBubbleOutline sx={{ color: 'white' }} />
										</ListItemIcon>
										<ListItemText sx={{ span: { color: 'white' }, marginLeft: '-18px' }} primary={t('Notifications')} />
									</ListItem>
								</>
							) : null}
							{adminRole === 'superadmin' && (
								<>
									<ListItem
										sx={{ height: '56px', '&.Mui-selected': { backgroundColor: '#19BD98' } }}
										button
										selected={history.location.pathname.includes(ROUTES.admins)}
										onClick={() => setPage(ROUTES.admins)}>
										<ListItemIcon>
											<PersonOutlineOutlined sx={{ color: 'white' }} />
										</ListItemIcon>
										<ListItemText sx={{ span: { color: 'white' }, marginLeft: '-18px' }} primary={t('Admins')} />
									</ListItem>
								</>
							)}
							{adminRole === 'superadmin' && (
								<>
									<ListItem
										sx={{ height: '56px', '&.Mui-selected': { backgroundColor: '#19BD98' } }}
										button
										selected={history.location.pathname.includes(ROUTES.appSettings)}
										onClick={() => setPage(ROUTES.appSettings)}>
										<ListItemIcon>
											<AdminPanelSettingsOutlined sx={{ color: 'white' }} fontSize="medium" />
										</ListItemIcon>
										<ListItemText sx={{ span: { color: 'white' }, marginLeft: '-18px' }} primary={t('System')} />
									</ListItem>
								</>
							)}
							<ListItem sx={{ height: '56px', '&.Mui-selected': { backgroundColor: '#19BD98' } }} button onClick={() => setIsLogoutDialogOpen(true)}>
								<ListItemIcon>
									<LogoutOutlined sx={{ color: 'white' }} />
								</ListItemIcon>
								<ListItemText sx={{ span: { color: 'white' }, marginLeft: '-18px' }} primary={t('Logout')} />
							</ListItem>
						</List>
					</div>
					<div>
						<List sx={{ backgroundColor: '#0A775F' }}>
							<ListItem sx={{ height: '56px', '&.Mui-selected': { backgroundColor: '#19BD98' } }} button onClick={() => setOpenSideNav(!openSideNav)}>
								<ListItemIcon>{openSideNav ? <img src={ShrinkIcon} alt={'shrink'} /> : <img src={ExpandIcon} alt={'expand'} />}</ListItemIcon>
							</ListItem>
						</List>
					</div>
				</div>
			</Drawer>
			<main className={classes.content}>
				<Switch>
					<SuperAdminRoute path={ROUTES.admins} exact={true} component={Superadmin} />
					<SuperAdminRoute path={ROUTES.users} exact={true} component={Users} />
					<SuperAdminRoute path={ROUTES.sessions} exact={true} component={Sessions} />
					<SuperAdminRoute path={ROUTES.userSessions} component={Sessions} />
					<Route path={ROUTES.uploads} component={Uploads} />
					<SuperAdminRoute path={ROUTES.sessionVideos} component={Uploads} />
					<Route path={ROUTES.video} component={Video} />
					<Route path={ROUTES.image} component={ImageDetailPage} />
					<Route path={ROUTES.globalmap} component={GlobalMap} />
					<Route path={ROUTES.projectMap} component={ProjectMap} />
					<AdminRoute path={ROUTES.dashboard} component={Dashboard} />
					<SuperAdminRoute path={ROUTES.user} component={Account} />
					<ContractAdminRoute path={ROUTES.projects} component={Projects} />
					<ContractAdminRoute path={ROUTES.project} component={Project} />
					<SuperAdminRoute path={ROUTES.seeProject} component={SeeProject} />
					<SuperAdminRoute path={ROUTES.leaderboard} exact={true} component={Leaderboard} />
					<SuperAdminRoute path={ROUTES.payments} exact={true} component={AllUserPayments} />
					<SuperAdminRoute path={ROUTES.userPayments} exact={true} component={UserPayments} />
					<SuperAdminRoute path={ROUTES.coinUpload} exact={true} component={Coins} />
					<SuperAdminRoute path={ROUTES.payments} exact={true} component={AllUserPayments} />
					<SuperAdminRoute path={ROUTES.appSettings} exact={true} component={SystemAdministration} />
					<AdminRoute path={ROUTES.missionEditor} component={MissionEditor} />
					<Route path={ROUTES.invasiveSessionViewer} component={InvasiveSpeciesSessionViewer} />
					<SuperAdminRoute path={ROUTES.projectEditor} component={ProjectEditor} />
					<AdminRoute path={ROUTES.notifications} exact={true} component={Notifications} />
					<Route path={ROUTES.home} render={() => <Redirect to={adminRole === 'superadmin' ? ROUTES.users : ROUTES.uploads} />} />
				</Switch>
			</main>
			<Dialog
				sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
				maxWidth="xs"
				open={isLogoutDialogOpen}
				onClose={() => setIsLogoutDialogOpen(false)}>
				<DialogTitle>{t('logoutConfirmation')}</DialogTitle>
				<DialogContent>{t('areYouSureYouWantToLogout')}</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={() => setIsLogoutDialogOpen(false)}>
						{t('Cancel')}
					</Button>
					<Button onClick={() => authStore.logout()}>{t('Logout')}</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default inject('authStore', 'userStore', 'sessionsStore')(observer(Home));
