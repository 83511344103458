import { LinearProgress } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React from 'react';
import settingsStore from '../../../stores/settingsStore';

interface GlobalLoaderProps {}

const GlobalLoader = (props: GlobalLoaderProps) => {
	const { globalLoading } = settingsStore;
	return globalLoading ? <LinearProgress /> : null;
};

export default inject('settingsStore')(observer(GlobalLoader));
