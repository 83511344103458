/* eslint-disable react/forbid-component-props */
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	Popover,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { API } from '../../../api';

import { ReactComponent as GiantHogweed } from '../../../assets/giant-hogweed.svg';
import { ReactComponent as HimalayanBalsam } from '../../../assets/himalayan-balsam.svg';
import { ReactComponent as JapaneseKnotweed } from '../../../assets/japanese-knotweed.svg';
import { ReactComponent as JapaneseRose } from '../../../assets/japanese-rose.svg';
import { ReactComponent as Lupine } from '../../../assets/lupine.svg';
import { ReactComponent as CanadaGoldenrod } from '../../../assets/canada_goldenrod.svg';
import { ReactComponent as FalseSpiraea } from '../../../assets/false_spiraea.svg';
import { ReactComponent as LesserPeriwinkle } from '../../../assets/lesser_periwinkle.svg';
import { ReactComponent as Buddleia } from '../../../assets/buddleia.svg';
import { ReactComponent as ParrotsFeather } from '../../../assets/parrots_feather.svg';
import { ReactComponent as GoatsRue } from '../../../assets/goats_rue.svg';
import { ReactComponent as CherryLaurel } from '../../../assets/cherry_laurel.svg';

import { Objects, ObjectsResponse } from '../../../models/general';
import projectEditorStore, { InvasiveObject } from '../../../stores/projectEditorStore';

interface SvgCheckboxProps {
	object: Objects;
	checked: boolean;
	checkObject: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

function SvgCheckbox({ object, checkObject, checked }: SvgCheckboxProps) {
	const backgroundColor = checked ? 'rgb(19,141,144,0.5)' : checked ? 'rgb(245,218,72,0.5)' : 'rgb(0,0,0,0.1)';

	const containerStyle = { width: '58px', height: '58px', background: backgroundColor };

	switch (object.ID) {
		case 12:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<HimalayanBalsam />}
					icon={<HimalayanBalsam style={{ filter: 'grayscale(100%)' }} />}
					onClick={checkObject}
					sx={containerStyle}
				/>
			);
		case 13:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<GiantHogweed />}
					icon={<GiantHogweed style={{ filter: 'grayscale(100%)' }} />}
					onClick={checkObject}
					sx={containerStyle}
				/>
			);
		case 14:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<Lupine />}
					icon={<Lupine style={{ filter: 'grayscale(100%)' }} />}
					onClick={checkObject}
					sx={containerStyle}
				/>
			);
		case 15:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<JapaneseRose />}
					icon={<JapaneseRose style={{ filter: 'grayscale(100%)' }} />}
					onClick={checkObject}
					sx={containerStyle}
				/>
			);
		case 20:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<JapaneseKnotweed />}
					icon={<JapaneseKnotweed style={{ filter: 'grayscale(100%)' }} />}
					onClick={checkObject}
					sx={containerStyle}
				/>
			);
		case 25:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<CanadaGoldenrod />}
					icon={<CanadaGoldenrod style={{ filter: 'grayscale(100%)' }} />}
					onClick={checkObject}
					sx={containerStyle}
				/>
			);
		case 26:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<FalseSpiraea />}
					icon={<FalseSpiraea style={{ filter: 'grayscale(100%)' }} />}
					onClick={checkObject}
					sx={containerStyle}
				/>
			);
		case 29:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<LesserPeriwinkle />}
					icon={<LesserPeriwinkle style={{ filter: 'grayscale(100%)' }} />}
					onClick={checkObject}
					sx={containerStyle}
				/>
			);
		case 30:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<Buddleia />}
					icon={<Buddleia style={{ filter: 'grayscale(100%)' }} />}
					onClick={checkObject}
					sx={containerStyle}
				/>
			);
		case 31:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<ParrotsFeather />}
					icon={<ParrotsFeather style={{ filter: 'grayscale(100%)' }} />}
					onClick={checkObject}
					sx={containerStyle}
				/>
			);

		case 32:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<GoatsRue />}
					icon={<GoatsRue style={{ filter: 'grayscale(100%)' }} />}
					onClick={checkObject}
					sx={containerStyle}
				/>
			);

		case 33:
			return (
				<Checkbox
					checked={checked}
					checkedIcon={<CherryLaurel />}
					icon={<CherryLaurel style={{ filter: 'grayscale(100%)' }} />}
					onClick={checkObject}
					sx={containerStyle}
				/>
			);
	}
	return null;
}
export const ProjectInvasiveObjectEditor = observer(() => {
	const { project } = projectEditorStore;

	const { data: objects = [] } = useQuery<ObjectsResponse, unknown, Objects[]>('objects', () => API.getObjects(), {
		staleTime: 600000,
		keepPreviousData: true,
		select: data => {
			return data?.data.objects;
		}
	});

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const open = Boolean(anchorEl);
	const id = open ? 'invasive-object-popover' : undefined;
	const [activeObject, setActiveObject] = useState<InvasiveObject>();
	const [areaVal, setAreaVal] = useState(0);
	const [valueVal, setValueVal] = useState(0);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>, obj: Objects) => {
		setAnchorEl(event.currentTarget);
		let foundObject = project.invasiveObjects?.find(i => i.object === obj.ID);
		if (!foundObject) {
			if (!project.invasiveObjects) {
				project.invasiveObjects = [];
			}
			// Creating new object with defaults of 24h initial, 2 week redo
			foundObject = {
				object: obj.ID,
				initial_window: 1440,
				redo_window: 20160,
				disabled: true,
				value_multiplier: 1,
				values: []
			};
			project.invasiveObjects.push(foundObject);
		}
		setActiveObject(foundObject);
	};

	const handleClose = () => {
		setAnchorEl(null);
		// Committing to store
		if (!activeObject) return;
		const foundObject = project.invasiveObjects?.find(i => i.object === activeObject.object);
		if (foundObject) {
			Object.assign(foundObject, activeObject);
		}
	};

	return (
		<Box sx={{ gap: '10px', display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
			{objects.map(object => {
				const obj = project.invasiveObjects?.find(o => o.object === object.ID);
				const checked = obj !== undefined && !obj.disabled;

				return <SvgCheckbox checkObject={e => handleClick(e, object)} checked={checked} key={object.ID} object={object} />;
			})}
			<Popover
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				id={id}
				onClose={handleClose}
				open={open}>
				{activeObject ? (
					<Box sx={{ gap: 2, height: 500, width: '80vw', display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<TextField
								label="Initial window"
								onChange={e => {
									setActiveObject(prev => ({ ...prev!, initial_window: +e.target.value }));
								}}
								type="number"
								value={activeObject.initial_window}
							/>
							<TextField
								label="Re-do window"
								onChange={e => {
									setActiveObject(prev => ({ ...prev!, redo_window: +e.target.value }));
								}}
								type="number"
								value={activeObject.redo_window}
							/>
							<TextField
								label="Value multiplier"
								onChange={e => {
									setActiveObject(prev => ({ ...prev!, value_multiplier: +e.target.value }));
								}}
								type="number"
								value={activeObject.value_multiplier}
							/>
						</Box>
						<FormControlLabel
							control={
								<Checkbox
									checked={activeObject.disabled}
									onChange={e => {
										setActiveObject(prev => ({ ...prev!, disabled: e.target.checked }));
									}}
								/>
							}
							label="Disabled"
						/>
						<Typography variant="caption">Values</Typography>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Area</TableCell>
									<TableCell>Value</TableCell>
									<TableCell />
								</TableRow>
							</TableHead>
							<TableBody>
								{activeObject.values.map(val => (
									<TableRow key={val.m_square}>
										<TableCell>{val.m_square}</TableCell>
										<TableCell>{val.value}</TableCell>
										<TableCell width={60}>
											<Button
												onClick={() => {
													setActiveObject(prev => {
														const newValues = [...prev!.values];
														const idx = newValues.findIndex(i => i.m_square === val.m_square);
														newValues.splice(idx, 1);
														newValues.sort((a, b) => a.m_square - b.m_square);
														return { ...prev!, values: newValues };
													});
												}}>
												DELETE
											</Button>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
						<Box sx={{ display: 'flex', flexDirection: 'row' }}>
							<TextField
								label="Area"
								onChange={e => {
									setAreaVal(+e.target.value);
								}}
								type="number"
								value={areaVal}
							/>
							<TextField
								label="Value"
								onChange={e => {
									setValueVal(+e.target.value);
								}}
								type="number"
								value={valueVal}
							/>
							<Button
								onClick={() => {
									setActiveObject(prev => {
										const newValues = [...prev!.values];
										const prevArea = newValues.find(i => i.m_square === areaVal);
										if (prevArea) {
											prevArea.value = valueVal;
										} else {
											newValues.push({ m_square: areaVal, value: valueVal });
										}
										newValues.sort((a, b) => a.m_square - b.m_square);
										return { ...prev!, values: newValues };
									});
								}}>
								Add value
							</Button>
						</Box>
					</Box>
				) : null}
			</Popover>
		</Box>
	);
});
