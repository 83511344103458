import { Button } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from '../../../api';
import downloadIcon from '../../../assets/download-icon.svg';
import moneyIcon from '../../../assets/money.svg';
import trashIcon from '../../../assets/trash.svg';
import paymentsStore from '../../../stores/paymentsStore';

interface Props {
	onMarkPaidClick: () => void;
	showMarkAsPaid?: boolean;
	handleDownloadPaymentCSV?: (paymentIds: number[]) => void;
	selected?: number[];
}

export const PaymentsToolboxBody = (props: Props) => {
	const { onMarkPaidClick, showMarkAsPaid, selected, handleDownloadPaymentCSV } = props;

	useEffect(() => {
		if (selected) paymentsStore.updateSelectedPaymentIds(selected);
	}, [selected]);

	const { t } = useTranslation();

	const fetchDeleteSelectedPaymentsRows = async (paymentIds: number[]) => {
		try {
			const response = await API.deleteSelectedPaymentsRows(paymentIds);
			if (response) window.location.reload();
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<>
			{showMarkAsPaid && (
				<Button color="primary" startIcon={<img src={moneyIcon} alt="Mark paid" />} variant="text" onClick={onMarkPaidClick}>
					{t('markAsPaid')}
				</Button>
			)}
			<Button
				color="primary"
				startIcon={<img src={downloadIcon} alt="export" />}
				variant="text"
				onClick={() => selected && handleDownloadPaymentCSV && handleDownloadPaymentCSV(selected)}>
				{t('exportSelectedRows')}
			</Button>
			<Button
				color="error"
				startIcon={<img src={trashIcon} alt="delete" />}
				variant="text"
				onClick={() => selected && fetchDeleteSelectedPaymentsRows(selected)}>
				{t('deleteSelectedRows')}
			</Button>
		</>
	);
};
