import { TableCell } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from '../../../components/UI/table/DataTable';
import { HeadCell } from '../../../components/UI/table/DataTableHead';
import { UserPaymentsTableDataT } from './types';

interface UserData {
	id: string;
	workStartedDate?: string;
	workEndedDate?: string;
	paidDate?: string;
	totalValue: string;
}

interface UserPaymentsTablePropsI {
	paymentsTableData: UserPaymentsTableDataT;
}

export const UserPaymentsTable: React.FC<UserPaymentsTablePropsI> = ({ paymentsTableData }): JSX.Element => {
	const { t } = useTranslation();
	const headCells: Array<HeadCell<UserData>> = [
		{
			id: 'id',
			numeric: true,
			disablePadding: true,
			label: t('ID')
		},
		// {
		// 	id: 'workStartedDate',
		// 	numeric: false,
		// 	disablePadding: false,
		// 	label: t('workStarted')
		// },
		// {
		// 	id: 'workEndedDate',
		// 	numeric: false,
		// 	disablePadding: false,
		// 	label: t('workEnded')
		// },
		{
			id: 'paidDate',
			numeric: false,
			disablePadding: false,
			label: t('Paid')
		},
		{
			id: 'totalValue',
			numeric: false,
			disablePadding: false,
			label: t('paymentsTableTotalValue')
		}
	];

	const rows = paymentsTableData?.map(data => {
		function formatDateAndTime(d: number) {
			const dd = new Date(d * 1000);
			const time = new Date(d * 1e3).toISOString().slice(-13, -5);
			return `${dd.getFullYear()}-${dd.getDate()}-${dd.getMonth()} ${time}`;
		}

		const paidDate = data.paidDate !== null ? formatDateAndTime(+data.paidDate) : undefined;

		return {
			id: `${data.id}`,
			totalValue: `${data.amount}`,
			paidDate
		};
	});

	return <DataTable rows={rows} headCells={headCells} tableCells={TableCells} />;
};

const TableCells = (row: UserData) => {
	return (
		<>
			{/* <TableCell align="left">{row.workStartedDate}</TableCell>
			<TableCell align="left">{row.workEndedDate}</TableCell> */}
			<TableCell align="left">{row.paidDate}</TableCell>
			<TableCell align="left">{row.totalValue} $</TableCell>
		</>
	);
};
