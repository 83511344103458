import { Box, Chip, Divider, Typography } from '@mui/material';
import React from 'react';

import { InfoTooltip } from './InfoTooltip';

export const ClickableChip = (props: { selected: boolean; text: string; value: any; onClick: (...args: any[]) => void; isDisabled?: boolean }) => {
	const { selected, text, value, onClick, isDisabled } = props;

	const handleChipClick = () => onClick();

	return <Chip id={value} color={selected ? 'primary' : 'default'} label={text} disabled={isDisabled} onClick={handleChipClick} />;
};

export const ProjectEditorSection = (props: { title: string; tooltip?: string; children: React.ReactNode }) => {
	const { title, tooltip, children } = props;

	return (
		<Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', alignContent: 'center', justifyContent: 'center' }}>
			<div>
				<Divider>
					<Typography variant="caption">{title}</Typography>
					{tooltip && <InfoTooltip title={tooltip} />}
				</Divider>
			</div>
			{children}
		</Box>
	);
};
