export const groupBy = (list: any[], keyGetter: any, filterData?: any) => {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!!filterData) {
            if (filterData(item)) {
                if (!collection) {
                    map.set(key, [item]);
                } else {
                    collection.push(item);
                }
            }
        } else {
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        }

    });
    return map;
}