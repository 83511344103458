import React, { PropsWithChildren, useEffect, useCallback, useState } from 'react';
import { AuthStore } from '../../../stores/authStore';
import { inject, observer } from 'mobx-react';
import { ROUTES } from '../../../parameters/routes';
import { createBrowserHistory } from 'history';

interface AuthorizationProps {
  authStore: AuthStore;
}

function Authorization(props: PropsWithChildren<AuthorizationProps>) {
  const { authStore, children } = props;
  const history = createBrowserHistory();
  const [guard, setGuard] = useState(false);
  const checkAdmin = useCallback(async () => {
    if (history.location.pathname.includes('passwordReset')) {
      setGuard(true);
      return;
    } else if (history.location.pathname.includes(ROUTES.passwordRequest)) {
      history.push(ROUTES.passwordRequest);
    } else if (authStore.isAuthorized) {
      console.log('authStore.isAuthorized', authStore.isAuthorized);
      
      history.push(history.location.pathname);
    } else if (history.location.pathname.includes(ROUTES.login)) {
      history.push(ROUTES.login);
    } else {
      console.log('history.location.pathname', history.location.pathname);
      authStore.setfirstRoute(history.location.pathname);
      history.push(ROUTES.login);
    }
    setGuard(true);
  }, [authStore, history]);

  useEffect(() => {
    checkAdmin();
  }, [checkAdmin]);

  return <>{guard ? children : null}</>;
}

export default inject('authStore')(observer(Authorization));
