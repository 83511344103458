import { useState, useCallback } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useCallbackWithLoading<T, F extends (...args: any[]) => Promise<T>>(fn: F) {
	const [pending, setPending] = useState(0);

	const delayed = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		async (...args: any[]) => {
			setPending(c => c + 1);
			let rv: T;
			try {
				rv = await fn(...args);
			} finally {
				setPending(c => c - 1);
			}
			return rv;
		},
		[fn]
	);

	return [delayed, pending > 0] as [F, boolean];
}
