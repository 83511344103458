import React, { useEffect, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Box,
	Typography,
	Table,
	TableBody,
	Paper,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from '@mui/material';
import { toast } from 'react-toastify';
import { API } from '../../../api';
import authStore from '../../../stores/authStore';
import { useTranslation } from 'react-i18next';
import { plantNames } from '../invasiveSessionViewer/InvasiveSpeciesSessionViewer';

export const InvasiveSpeciesDialog = (props: any) => {
	const { sightingId, initSession, markerId, open, onClose } = props;
	const [sessions, setSessions] = useState<any>(null);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { t } = useTranslation();

	useEffect(() => {
		// Load the the sessions which belong to the sighting
		API.getInvasiveSessionFromSightingId(sightingId)
			.then(res => {
				const { sessions } = res.data;
				setSessions(sessions);
			})
			.catch(err => {
				console.error(err);
			});
	}, []);

	const disableSighting = async () => {
		API.disableSightingPolygon(initSession)
			.then(res => {
				toast.success('Sighting disabled');
			})
			.catch(err => {
				console.error(err);
				toast.error('Failed to disable sighting');
			});
	};

	const TableHeader = (props: { title: string }) => {
		const { title } = props;
		return (
			<TableCell>
				<Typography variant="caption" color="primary" fontWeight={'900'} letterSpacing={'0.05735em'}>
					{title}
				</Typography>
			</TableCell>
		);
	};

	interface InvasiveSession {
		startedAt: string;
		sightingId: string;
		sessionId: string;
		area: string;
		density: string;
		marker: any;
		userId: string;
		username: string;
	}

	const TableDataRow = (props: { session: InvasiveSession }) => {
		const { startedAt, sightingId, sessionId, area, density, marker, userId, username } = props.session;

		return (
			<TableRow key={sessionId}>
				<TableCell>{`${new Date(startedAt).toLocaleDateString()} ${new Date(startedAt).toLocaleTimeString()}`}</TableCell>
				<TableCell>{area}</TableCell>
				<TableCell>{density}</TableCell>
				<TableCell>{t(`${plantNames[marker]}`)}</TableCell>
				<TableCell>{sightingId}</TableCell>
				<TableCell>
					<a
						style={{ textDecoration: 'none', padding: 0 }}
						href={`${process.env.PUBLIC_URL}/invasive-session-viewer/${sessionId}`}
						target="_blank"
						rel="noopener noreferrer">
						<Button
							sx={{
								textDecoration: 'underline',
								padding: 0
							}}
							variant="text">
							{sessionId}
						</Button>
					</a>
				</TableCell>
				{(authStore.adminRole === 'superadmin' || authStore.adminRole === 'admin') && (
					<TableCell>
						<a
							style={{ textDecoration: 'none', padding: 0 }}
							href={`${process.env.PUBLIC_URL}/profile/${userId}`}
							target="_blank"
							rel="noopener noreferrer">
							<Button
								sx={{
									textDecoration: 'underline',
									padding: 0
								}}
								variant="text">
								{`${username} (${userId})`}
							</Button>
						</a>
					</TableCell>
				)}
			</TableRow>
		);
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
			<DialogTitle>Sighting sessions</DialogTitle>
			<DialogContent>
				<Table>
					<TableHead>
						<TableRow>
							<TableHeader title={t('Started')} />
							<TableHeader title={t('Area')} />
							<TableHeader title={t('Density')} />
							<TableHeader title={t('Species')} />
							<TableHeader title={t('Sighting ID')} />
							<TableHeader title={t('Session ID')} />
							{(authStore.adminRole === 'superadmin' || authStore.adminRole === 'admin') && <TableHeader title={t('User')} />}
						</TableRow>
					</TableHead>
					<TableBody>{sessions && sessions.map((session: any) => <TableDataRow session={session} />)}</TableBody>
				</Table>
			</DialogContent>
			<DialogActions>
				{(authStore.adminRole === 'superadmin' || authStore.adminRole === 'admin') && (
					<Button onClick={disableSighting} color="primary">
						Disable polygon
					</Button>
				)}
				<Button onClick={onClose} color="error">
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};
