import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Container } from './CrowdsorsaImageCheckbox.styled';
import { Checkbox, FormControlLabel } from '@mui/material';
import distributionCabinet from '../../../assets/distribution-cabinet.svg';
import drainage from '../../../assets/drainage.svg';
import benchObject from '../../../assets/bench-object.svg';
import manholeCover from '../../../assets/manhole-cover-v2.svg';
import roadblock from '../../../assets/roadblock.svg';
import transformer from '../../../assets/transformer.svg';
import trashcan from '../../../assets/trashcan.svg';
import slipperyPath from '../../../assets/slippery-path.svg';
import snowPlowed from '../../../assets/snow-plowed.svg';
import unplowedPath from '../../../assets/unplowed-path.svg';
import roadDefect from '../../../assets/road-defect.svg';
import himalayanBalsam from '../../../assets/himalayan-balsam.svg';
import giantHogweed from '../../../assets/giant-hogweed.svg';
import lupine from '../../../assets/lupine.svg';
import japaneseRose from '../../../assets/japanese-rose.svg';
import roughPath from '../../../assets/rough-path.svg';
import drainCover from '../../../assets/drain-cover.svg';
import curbsideDrainCover from '../../../assets/curbside-drain-cover.svg';
import waterValveCover from '../../../assets/water-valve-cover.svg';
import japaneseKnotweed from '../../../assets/japanese-knotweed.svg';
import brokenSoundBeacon from '../../../assets/broken_sound_beacon.svg';
import workingSoundBeacon from '../../../assets/working_sound_beacon.svg';
import pedestrianPath from '../../../assets/pedestrian_path.svg';
import droppedCurb from '../../../assets/dropped_curb.svg';
import canadaGoldenrod from '../../../assets/canada_goldenrod.svg';
import falseSpiraea from '../../../assets/false_spiraea.svg';
import culvert from '../../../assets/culvert.svg';
import spanishSlug from '../../../assets/spanish_slug.svg';
import lesser_periwinkle from '../../../assets/lesser_periwinkle.svg';
import buddleia from '../../../assets/buddleia.svg';
import parrots_feather from '../../../assets/parrots_feather.svg';
import goats_rue from '../../../assets/goats_rue.svg';
import cherry_laurel from '../../../assets/cherry_laurel.svg';

interface Props {
	inputName: string;
	inputId: string;
	checked?: boolean;
	value?: number;
	register?: any;
	registerValue?: string;
	isChecked?: boolean;
	refreshFlag?: boolean;
	setRefreshFlag: Dispatch<SetStateAction<boolean>>;
	canEdit?: boolean;
}

const CrowdsorsaImageCheckbox = (props: Props) => {
	const { inputName, inputId, value, registerValue, register, isChecked, refreshFlag, setRefreshFlag, canEdit } = props;

	const [checked, setChecked] = useState<boolean>(false);

	useEffect(() => {
		isChecked && setChecked(isChecked);
	}, [isChecked]);

	const onClickCheckbox = () => {
		setChecked(!checked);
		setRefreshFlag(!refreshFlag);
	};

	return (
		<Container>
			<FormControlLabel
				sx={{
					'&.MuiFormControlLabel-root': { margin: '0px', border: 'none', padding: '0px' }
				}}
				label={''}
				checked={checked}
				value={value}
				onClick={canEdit && onClickCheckbox}
				id={inputId}
				name={inputName}
				{...register(registerValue)}
				control={<Checkbox disabled={!canEdit} sx={{ '&.MuiCheckbox-root': { padding: '0px', input: { width: '30px', height: '30px' } } }} />}
			/>

			{value === 1 && <img height={'48px'} width={'48px'} src={manholeCover} alt="manholeCover" />}
			{value === 2 && <img height={'48px'} width={'48px'} src={benchObject} alt="benchObject" />}
			{value === 3 && <img height={'48px'} width={'48px'} src={distributionCabinet} alt="distributionCabinet" />}
			{value === 4 && <img height={'48px'} width={'48px'} src={roadblock} alt="roadblock" />}
			{value === 5 && <img height={'48px'} width={'48px'} src={trashcan} alt="trashcan" />}
			{value === 6 && <img height={'48px'} width={'48px'} src={drainage} alt="drainage" />}
			{value === 7 && <img height={'48px'} width={'48px'} src={transformer} alt="transformer" />}
			{value === 8 && <img height={'48px'} width={'48px'} src={slipperyPath} alt="slipperyPath" />}
			{value === 9 && <img height={'48px'} width={'48px'} src={snowPlowed} alt="snowPlowed" />}
			{value === 10 && <img height={'48px'} width={'48px'} src={unplowedPath} alt="unplowedPath" />}
			{value === 11 && <img height={'48px'} width={'48px'} src={roadDefect} alt="roadDefect" />}
			{value === 12 && <img height={'48px'} width={'48px'} src={himalayanBalsam} alt="himalayanBalsam" />}
			{value === 13 && <img height={'48px'} width={'48px'} src={giantHogweed} alt="giantHogweed" />}
			{value === 14 && <img height={'48px'} width={'48px'} src={lupine} alt="lupine" />}
			{value === 15 && <img height={'48px'} width={'48px'} src={japaneseRose} alt="japaneseRose" />}
			{value === 16 && <img height={'48px'} width={'48px'} src={roughPath} alt="roughPath" />}
			{value === 17 && <img height={'48px'} width={'48px'} src={drainCover} alt="drainCover" />}
			{value === 18 && <img height={'48px'} width={'48px'} src={curbsideDrainCover} alt="curbsideDrainCover" />}
			{value === 19 && <img height={'48px'} width={'48px'} src={waterValveCover} alt="waterValveCover" />}
			{value === 20 && <img height={'48px'} width={'48px'} src={japaneseKnotweed} alt="japaneseKnotweed" />}
			{value === 21 && <img height={'48px'} width={'48px'} src={brokenSoundBeacon} alt="japaneseKnotweed" />}
			{value === 22 && <img height={'48px'} width={'48px'} src={workingSoundBeacon} alt="japaneseKnotweed" />}
			{value === 23 && <img height={'48px'} width={'48px'} src={pedestrianPath} alt="japaneseKnotweed" />}
			{value === 24 && <img height={'48px'} width={'48px'} src={droppedCurb} alt="japaneseKnotweed" />}
			{value === 25 && <img height={'48px'} width={'48px'} src={canadaGoldenrod} alt="canadaGoldenrod" />}
			{value === 26 && <img height={'48px'} width={'48px'} src={falseSpiraea} alt="falseSpiraea" />}
			{value === 27 && <img height={'48p'} width={'48px'} src={culvert} alt="culvert" />}
			{value === 28 && <img height={'48px'} width={'48px'} src={spanishSlug} alt="spanishSlug" />}
			{value === 29 && <img height={'48px'} width={'48px'} src={lesser_periwinkle} alt="lesserPeriwinkle" />}
			{value === 30 && <img height={'48px'} width={'48px'} src={buddleia} alt="buddleia" />}
			{value === 31 && <img height={'48px'} width={'48px'} src={parrots_feather} alt="parrotsFeather" />}
			{value === 32 && <img height={'48px'} width={'48px'} src={goats_rue} alt="goatsRue" />}
			{value === 33 && <img height={'48px'} width={'48px'} src={cherry_laurel} alt="cherryLaurel" />}
		</Container>
	);
};

export default CrowdsorsaImageCheckbox;
