import { action, computed, decorate, observable, toJS } from 'mobx';
import { Tags, imageFile, oldReferenceImages } from '../models/general';
import * as yup from 'yup';
import { API } from '../api';
import { t } from 'i18next';

export enum StepType {
	EMPTY_STEP = 0,
	LOBBY_STEP = 1,
	PREVIEW_STEP = 2,
	RECORDING_STEP = 3,
	SUMMARY_STEP = 4,
	CHOICE_STEP = 5
}

const stepsSchema = yup.array().of(
	yup.object().shape({
		id: yup.number().min(0),
		order: yup.number().min(1),
		type: yup.number().min(1, 'Empty steps are not allowed'),
		lobbyParams: yup.object().when('type', {
			is: StepType.LOBBY_STEP,
			then: schema =>
				schema.shape({
					// Tiptap returns <p></p> if the content is empty.
					// https://github.com/ueberdosis/tiptap/issues/154
					// If you put whitespace and returns into the box it'll return just a bunch
					// of empty p tags. This regex will reject the content if ONLY has spaces or p tags.
					content: yup
						.string()
						.required()
						.test('reject-empty-p-tag', t('missionEditor.requiredErrorMessage'), value => {
							const regex = /^[<>p \/]*$/;
							return !regex.test(value as string);
						})
				})
		}),
		previewParams: yup.object().when('type', {
			is: StepType.PREVIEW_STEP,
			then: schema =>
				schema.shape({
					portrait: yup.boolean().required(),
					horizonPercentage: yup
						.number()
						.required(t('missionEditor.requiredErrorMessage'))
						.min(1, t('missionEditor.previewStep.horizonPercentageMinMaxErrorMessage'))
						.max(99, t('missionEditor.previewStep.horizonPercentageMinMaxErrorMessage')),
					horizonText: yup.string().required(t('missionEditor.requiredErrorMessage')),
					instructionText: yup.string().required(t('missionEditor.requiredErrorMessage')),
					popupEnabled: yup.boolean().default(false),
					popupTitle: yup.string().when('popupEnabled', {
						is: true,
						then: schema => schema.required(t('missionEditor.requiredErrorMessage'))
					}),
					popupText: yup.string().when('popupEnabled', {
						is: true,
						then: schema => schema.required(t('missionEditor.requiredErrorMessage'))
					}),
					popupButtonText: yup.string().when('popupEnabled', {
						is: true,
						then: schema => schema.required(t('missionEditor.requiredErrorMessage'))
					})
				})
		}),
		recordingParams: yup.object().when('type', {
			is: StepType.RECORDING_STEP,
			then: schema =>
				schema.shape({
					hasCollectables: yup.boolean(),
					collectableLayer: yup.string(),
					portrait: yup.boolean().required(),
					headerText: yup.string().required(),
					generatePath: yup.boolean().required(),
					collectRadius: yup.number().default(16),
					defaultPath: yup.string().when('generatePath', {
						is: true,
						then: schema => schema.required()
					}),
					pathChoices: yup
						.array()
						.of(yup.string())
						.when('generatePath', {
							is: true,
							then: schema => schema.required()
						})
				})
		}),
		summaryParams: yup.object().when('type', {
			is: StepType.SUMMARY_STEP,
			then: schema =>
				schema.shape({
					mapEnabled: yup.boolean().required()
				})
		}),
		choiceParams: yup.object().when('type', {
			is: StepType.CHOICE_STEP,
			then: schema =>
				schema.shape({
					title: yup.string().required(),
					objects: yup
						.array()
						.min(1)
						.of(
							schema.shape({
								ID: yup.number().required(),
								name: yup.string().optional(),
								number: yup.number().required(),
								value: yup.number().min(1).required(),
								stepChoiceId: yup.number().required().default(0)
							})
						)
				})
		})
	})
);

const missionSchema = yup.object().shape({
	// i18n is acting weird here. Hardcoding these few error messages.
	name: yup.string().required('Mission name is required'),
	lat: yup.number().typeError('Lattitude must be a number').required(t('missionEditor.requiredErrorMessage')),
	lon: yup.number().typeError('Longitude must be a number').required(t('missionEditor.requiredErrorMessage')),
	steps: stepsSchema,
	tags: yup.array().required('Required.').min(1, 'Select atleast one tag'),
	csvFile: yup.mixed()
});

export interface Step {
	id?: number;
	order: number;
	type: StepType;
	previewParams?: PreviewParams;
	lobbyParams?: LobbyParams;
	recordingParams?: RecordingParams;
	summaryParams?: SummaryParams;
	choiceParams?: ChoiceParams;
}

export interface LobbyParams {
	content: string;
}

export interface SummaryParams {
	mapEnabled: boolean;
}

interface Object {
	ID: number;
	name: number;
	number: number; // This is the number of photos that is required for photo missions..
	stepChoiceId: number;
	objectTypeId: number;
	value: number;
}
export interface ChoiceParams {
	objects: Object[];
	title: string;
}

export interface PreviewParams {
	portrait: boolean;
	horizonPercentage: number;
	horizonText: string;
	instructionText: string;
	popupEnabled: boolean;
	popupTitle: string;
	popupText: string;
	popupButtonText: string;
}

export interface RecordingParams {
	portrait: boolean;
	generatePath: boolean;
	defaultPath: string;
	defaultFill: string;
	fillChoices: string[];
	headerText: string;
	pathChoices: string[];
	collectableLayer: string;
	hasCollectables: boolean;
	collectRadius: number;
}

export interface Mission {
	id: number;
	name: string;
	projectTypeId: number;
	countryId: number;
	csvFile: any;
	dateStart: number | Date;
	lat: string;
	lon: string;
	tags: Tags[];
	users: number[];
	references: imageFile[];
	oldReferences: oldReferenceImages[];
	clientUsers: number[];
}

export class MissionEditorStore {
	selectedTab: string;
	errors: any = {};
	steps: Step[];
	mission: Mission;
	stepsToDelete: number[];

	constructor() {
		this.mission = {
			id: 0,
			name: '',
			lat: '',
			lon: '',
			countryId: 1, // Finland
			projectTypeId: 3,
			dateStart: new Date(),
			tags: [],
			csvFile: undefined,
			users: [],
			clientUsers: [],
			references: [],
			oldReferences: []
		};
		this.selectedTab = '1';
		this.steps = [{ id: 0, order: 1, type: 1, lobbyParams: { content: '' } }];
		this.stepsToDelete = [];
	}

	@computed get stepsWithErrors() {
		return Object.keys(this.errors)
			.map((error: any) => {
				const match = error.match(/\[(\d+)\]/);
				if (match) {
					// Extract the matched group (array number) from the regex match
					const arrayNumber = Number(match[1]);
					return arrayNumber;
				}
			})
			.filter(x => x != undefined);
	}

	reset() {
		this.mission = {
			id: 0,
			name: '',
			lat: '',
			lon: '',
			countryId: 1, // Finland
			projectTypeId: 3,
			dateStart: new Date(),
			tags: [],
			csvFile: undefined,
			users: [],
			references: [],
			oldReferences: [],
			clientUsers: []
		};
		this.selectedTab = '1';
		this.steps = [{ order: 1, type: 1, lobbyParams: { content: '' } }];
		this.stepsToDelete = [];
	}

	// Runs all the validations
	async validateMission() {
		const [res1, res2] = await Promise.all([this.validateMissionSchema(), this.validateStepSchema()]);

		this.setErrors({ ...res1, ...res2 });
	}

	async validateStepSchema() {
		return stepsSchema.validate(this.steps, { abortEarly: false }).catch(err => {
			const errorObject: any = {};

			// Each error is a object that has a path, message and other info
			// This returns an object where the path of the error is a property name, and the message.
			// so the errors can be access by using steps[2].previewParams.horizontalText: required for example.
			err.inner.forEach((error: any) => {
				errorObject[error.path] = error.message;
			});

			console.error(toJS(errorObject));
			return errorObject;
		});
	}

	async validateMissionSchema() {
		try {
			await missionSchema.validate(this.mission, { abortEarly: false, stripUnknown: true });
		} catch (err: any) {
			const errorObject: any = {};

			err.inner.forEach((error: any) => {
				errorObject[error.path] = error.message;
			});

			console.error(toJS(errorObject));
			return errorObject;
		}
	}

	async save(): Promise<number> {
		await this.validateMission();

		const isStepsValid = stepsSchema.isValidSync(this.steps);
		const isMissionValid = missionSchema.isValidSync(this.mission);

		if (!isStepsValid || !isMissionValid) return Promise.reject('Cannot save mission with validation errors.');

		const mission: any = this.getMission;
		const missionTags = mission.tags.map((tag: Tags) => tag.id);

		const saveRes = await API.saveCustomProject({
			...mission,
			isPrivate: mission.users.length > 0 ? true : false,
			stepsToDelete: this.stepsToDelete,
			tags: missionTags
		});

		if (saveRes.message === 'Duplicate coordinates') {
			Promise.reject('Duplicate coordinates in CSV');
		}

		return Promise.resolve(saveRes.data.data.projectId);
	}

	setErrors(errors: any) {
		this.errors = errors;
	}
	setSelectedTab(tab: string) {
		this.selectedTab = tab;
	}
	setUsers(users: number[]) {
		this.mission.users = users;
	}
	setClientUsers(users: number[]) {
		this.mission.clientUsers = users;
	}
	setSelectedTags(tags: Tags[]) {
		this.mission.tags = tags;
	}
	setMissionName(name: string) {
		this.mission.name = name;
	}
	setMission(mission: Mission) {
		this.mission = mission;
	}
	setMissionStartDate(startDate: Date | number) {
		this.mission.dateStart = startDate;
	}
	setMissionId(projectId: number) {
		this.mission.id = projectId;
	}
	setReferences(references: imageFile[]) {
		this.mission.references = references;
	}
	get getSteps() {
		return this.steps;
	}

	get getMission() {
		const steps = this.getSteps;
		return { ...toJS(this.mission), steps };
	}

	@computed get missionTags() {
		return this.mission.tags;
	}

	// When the type of a step is changed, change it's type to match with correct data.
	setStepType(type: StepType, step: Step) {
		switch (type) {
			/* You cannot make a new lobby step
            case StepType.LOBBY_STEP: 
                const lobbyStep: Step = {
                    order: step.order, 
                    type,
                    lobbyParams: {
                        content: "",
                        source:
                    }
                }
                this.steps[step.order - 1] = lobbyStep;
                break;
            */
			case StepType.PREVIEW_STEP:
				const previewStep: Step = {
					order: step.order,
					type,
					previewParams: {
						portrait: false,
						horizonPercentage: 50,
						horizonText: '',
						instructionText: '',
						popupEnabled: false,
						popupTitle: '',
						popupText: '',
						popupButtonText: ''
					}
				};
				this.steps[step.order - 1] = previewStep;
				break;
			case StepType.RECORDING_STEP:
				const recordingStep: Step = {
					order: step.order,
					type,
					recordingParams: {
						portrait: false,
						generatePath: false,
						hasCollectables: false,
						collectableLayer: 'abc',
						defaultPath: '#139074',
						pathChoices: [],
						defaultFill: '#19BD98',
						fillChoices: [],
						headerText: '',
						collectRadius: 16
					}
				};
				this.steps[step.order - 1] = recordingStep;
				break;
			case StepType.CHOICE_STEP:
				const choiceStep: Step = {
					order: step.order,
					type,
					choiceParams: {
						objects: [],
						title: ''
					}
				};
				this.steps[step.order - 1] = choiceStep;
				break;
			case StepType.SUMMARY_STEP:
				const summaryStep: Step = {
					order: step.order,
					type,
					summaryParams: {
						mapEnabled: false
					}
				};
				this.steps[step.order - 1] = summaryStep;
				break;
		}
	}

	// Each step type has a different property name, this function returns that name based on steptype
	getParamsPropertyName(stepType: StepType): string {
		switch (stepType) {
			case StepType.LOBBY_STEP:
				return 'lobbyParams';
			case StepType.PREVIEW_STEP:
				return 'previewParams';
			case StepType.RECORDING_STEP:
				return 'recordingParams';
			case StepType.CHOICE_STEP:
				return 'choiceParams';
			case StepType.SUMMARY_STEP:
				return 'summaryParams';
			default:
				console.warn('Property name not found');
				return '';
		}
	}

	// Sets the specified propertyName with propertyValue of step
	setStepProperty(step: Step, propertyName: any, propertyValue: any) {
		const paramsPropertyName = this.getParamsPropertyName(step.type);
		// @ts-ignore
		this.steps[step.order - 1][paramsPropertyName][propertyName] = propertyValue;
	}

	// Set's a step
	setStep(step: Step) {
		const stepIndex = step.order - 1;
		this.steps[stepIndex] = step;
	}

	// Returns the step that is currently selected.
	getSelectedStep(): Step {
		return this.steps[Number(this.selectedTab) - 1];
	}

	setSteps(steps: Step[]) {
		this.steps = steps;
	}

	swapStep(step1: Step, step2: Step) {
		const steps = this.steps;
		// Index inside step array are - 1 of the order
		const step1Index = step1.order - 1;
		const step2Index = step2.order - 1;

		// Swap the two steps
		steps[step1Index] = { ...step2, order: step1.order };
		steps[step2Index] = { ...step1, order: step2.order };

		// reset steps with swapped order
		this.setSteps(steps);
	}

	// Creates a new a step
	createStep() {
		this.steps.push({
			id: 0,
			order: this.steps.length + 1,
			type: 0
		});
		this.setSelectedTab(this.steps.length.toString());
	}

	regenerateOrder() {
		this.steps.forEach((step, index) => {
			step.order = index + 1;
		});
	}

	deleteStep(step: Step) {
		// Handles the case when a step has not yet been saved, no need to delete it on the server
		if (step.id) this.stepsToDelete.push(step.id);

		// Remove the step from the steps
		this.steps.splice(step.order - 1, 1);
		// Reset the order property of each step so it matches new order
		this.regenerateOrder();
		// Set the selected tab to last in the array
		this.setSelectedTab(this.steps.length.toString());
	}
}

decorate(MissionEditorStore, {
	mission: observable,
	errors: observable,
	selectedTab: observable,
	steps: observable,

	reset: action.bound,
	setMission: action.bound,
	missionTags: action.bound,
	setMissionName: action.bound,
	setMissionStartDate: action.bound,
	setStepProperty: action.bound,
	setStepType: action.bound,
	setStep: action.bound,
	setSteps: action.bound,
	setMissionId: action.bound,
	setSelectedTags: action.bound,
	setSelectedTab: action.bound,
	setReferences: action.bound,
	setClientUsers: action.bound,

	deleteStep: action.bound,
	swapStep: action.bound,
	setUsers: action.bound,
	getSelectedStep: action.bound,
	validateMission: action.bound,
	save: action.bound,
	createStep: action.bound
});

export default new MissionEditorStore();
