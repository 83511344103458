import { Close } from '@mui/icons-material';
import { Box, Dialog, IconButton } from '@mui/material';
import React, { PropsWithChildren, ReactElement } from 'react';

interface Props {
	open: boolean;
	setOpen(valid: boolean): void;
	dialogStylesFromProp?: Record<string, string>;
}

function CarchupaDialog({ children, open, setOpen, dialogStylesFromProp }: PropsWithChildren<Props>): ReactElement {
	const dialogStyles = {
		maxHeight: '445px',
		borderRadius: '20px',
		minHeight: '100px',
		padding: '8px 0',
		maxWidth: '714px'
	};

	return (
		<Dialog open={open} onClose={() => setOpen(false)} PaperProps={{ sx: dialogStylesFromProp || dialogStyles }}>
			<Box position="absolute" top={24} right={24}>
				<IconButton onClick={() => setOpen(false)}>
					<Close />
				</IconButton>
			</Box>
			{children}
		</Dialog>
	);
}

export default CarchupaDialog;
