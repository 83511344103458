import styled from '@emotion/styled';

export const Container = styled.div`
	display: flex;
	align-items: center;
	input {
		height: 18px;
		width: 18px;
		color: #139074;
	}
	label {
		color: #494f4d;
		font-size: 14px;
		border: 1px solid #494f4d;
		border-radius: 4px;
		padding: 6px 14px;
		cursor: pointer;
		display: inline-block;
	}
	input:checked + label {
		background-color: #139074;
		color: white;
	}
`;
