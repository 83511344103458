import { Box, Toolbar } from '@mui/material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { PaymentsToolboxBody } from '../../../routes/core/finance/PaymentsToolboxBody';
import { UsersToolboxBody } from '../../../routes/core/users/UsersToolboxBody';
import { useTranslation } from 'react-i18next';

interface EnhancedTableToolbarProps {
	numSelected: number;
	fetchMarkAsPaid: any;
	selected: number[];
	showMarkAsPaid?: boolean;
	handleDownloadPaymentCSV?: (paymentIds: number[]) => void;
	handleDownloadUserCSV?: (userIds: number[]) => void;
}

export const TableToolbar = ({
	selected,
	numSelected,
	fetchMarkAsPaid,
	showMarkAsPaid,
	handleDownloadPaymentCSV,
	handleDownloadUserCSV
}: EnhancedTableToolbarProps) => {
	const { t } = useTranslation();
	const location = useLocation();
	const onMarkPaidClick = () => {
		fetchMarkAsPaid(selected);
	};

	return (
		<Toolbar
			sx={{
				paddingLeft: '0px !important'
			}}>
			{numSelected > 0 && (
				<Box sx={{ flex: '1 1 100%', display: 'flex', gap: '60px', fontWeight: 700 }}>
					<p>{numSelected} {t('rowsSelected')}</p>
					{location.pathname === '/users' && <UsersToolboxBody handleDownloadUserCSV={handleDownloadUserCSV} selected={selected} />}
					{location.pathname === '/payments' && (
						<PaymentsToolboxBody
							handleDownloadPaymentCSV={handleDownloadPaymentCSV}
							selected={selected}
							showMarkAsPaid={showMarkAsPaid}
							onMarkPaidClick={onMarkPaidClick}
						/>
					)}
				</Box>
			)}
		</Toolbar>
	);
};
