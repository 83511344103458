import { Autocomplete, Box, Button, FormControl, TextField, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { API } from '../../../api';
import downloadCsv from '../../../assets/download-icon.svg';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import { UsersTable } from './UsersTable';
import SelectList from '../../../components/UX/select-list/SelectList';
import userStore from '../../../stores/userStore';
import { saveAs } from 'file-saver';
import { useGetProjectListQuery } from '../../../queries/data-queries/useGetProjectListQuery';
import { MainQueryKey, QueryKey, useSelectListQuery } from '../../../queries/view-queries/useSelectListQuery';
import { useGetCountriesQuery } from '../../../queries/data-queries/useGetCountriesQuery';
import moment from 'moment';
import { SearchInput } from '../../../components/UI/search-input';
import { InputSearchQueryKey, useSearchInputQuery } from '../../../queries/view-queries/useSearchInputQuery';
import { PaginationMainQueryKey } from '../../../queries/view-queries/usePaginationQuery';

interface UsersProps extends RouteComponentProps {}

export const Users = (props: UsersProps) => {
	const { t } = useTranslation();
	const { users } = userStore;

	/////////////////////////////////////////////// REACT-QUERY ////////////////////////////////////////////////////////
	const { data: projectListData } = useGetProjectListQuery({ fields: 'id,name' });
	const { data: countriesData } = useGetCountriesQuery();
	const { getSelectedOption: getSelectedOptionForMission, setSelectedOption } = useSelectListQuery(MainQueryKey.mission, QueryKey.users);
	const { getSelectedOption: getSelectedOptionForCountry } = useSelectListQuery(MainQueryKey.country, QueryKey.users);
	const mission = getSelectedOptionForMission(MainQueryKey.mission, QueryKey.users);
	const country = getSelectedOptionForCountry(MainQueryKey.country, QueryKey.users);
	const { searchTerm } = useSearchInputQuery(InputSearchQueryKey.users);
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const userIds: any = users.map(user => user.id);

	const handleDownloadUserCSV = async (userIds: number[]) => {
		const response = await API.getUsersCSV(userIds);
		if (response) {
			let blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
			saveAs(blob, `Crowdsorsa-CSV-${moment().format('YYYY-MM-DD')}.csv`);
		}
	};

	return (
		<SimpleLayout passedStyles="">
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Typography variant="h1">{t('Users')}</Typography>
				<Button variant="text" endIcon={<img src={downloadCsv} alt="download csv" />} onClick={() => handleDownloadUserCSV(userIds)}>
					{t('downloadACsv')}
				</Button>
			</Box>
			<Box sx={{ display: 'flex', width: '100%', gap: '24px' }}>
				<FormControl sx={{ width: '300px', opacity: 0.8 }}>
					<SelectList
						queryKey={QueryKey.users}
						mainQueryKey={MainQueryKey.country}
						data={countriesData?.countries}
						labelId="country"
						id="mission-select"
						htmlFor="country"
						labelText={t('country')}
					/>
				</FormControl>
				<FormControl sx={{ width: '300px' }}>
					<label htmlFor="missionName">{t('Mission Name')}</label>
					<Autocomplete
						options={projectListData?.projects || []}
						getOptionLabel={option => option.name}
						id="missionName"
						filterOptions={(options, { inputValue }) => {
							const filtered = options.filter(option => option.name.toLowerCase().includes(inputValue.trim().toLowerCase()));

							return filtered;
						}}
						renderOption={(props, item) => {
							return (
								<li {...props} key={item.id} value={item.id}>
									<div style={{ whiteSpace: 'normal', wordWrap: 'break-word', wordBreak: 'break-word' }} data-value={item.id}>
										{item.name}{' '}
										<sup data-value={item.id} style={{ color: 'grey' }}>
											({`ID: ${item.id}`})
										</sup>
									</div>
								</li>
							);
						}}
						onChange={(e: any) => {
							const value = e.target.value || e.target.getAttribute('data-value');
							setSelectedOption(MainQueryKey.mission, QueryKey.users, value);
						}}
						renderInput={params => (
							<TextField
								{...params}
								placeholder={t('All')}
								InputProps={{
									...params.InputProps,
									style: { alignContent: 'center' }, // Center text horizontally
									inputProps: {
										...params.inputProps,
										style: { alignContent: 'center', padding: '10px 0' } // Center text horizontally and vertically
									}
								}}
							/>
						)}
					/>
				</FormControl>
				<FormControl sx={{ width: '300px', opacity: 0.8 }} variant="outlined">
					<SearchInput
						htmlFor="search"
						id="search"
						label={t('Search')}
						placeholder={t('searchTextField')}
						queryKey={InputSearchQueryKey.users}
						paginationMainQueryKey={PaginationMainQueryKey.users}
					/>
				</FormControl>
			</Box>
			<UsersTable handleDownloadUserCSV={handleDownloadUserCSV} country={country} mission={mission} searchedValue={searchTerm} />
		</SimpleLayout>
	);
};
