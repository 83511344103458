import { useQuery } from 'react-query';
import { API } from '../../api';
import { useTranslation } from 'react-i18next';
import messageStore from '../../stores/messageStore';
import { ProjectRequest } from '../../models/projects';

enum MainQueryKey {
	projectListQuery = 'project-list-query'
}

export const useGetProjectListQuery = (params: ProjectRequest) => {
	const { countryId, limit, page, startDate, endDate, fields, hideFinished, projectName, language } = params;
	const { data, isError } = useQuery(
		[MainQueryKey.projectListQuery, countryId, limit, page, startDate, endDate, hideFinished, projectName, language],
		() =>
			API.getProjectList({
				countryId: countryId === 'all' ? undefined : countryId,
				limit,
				page,
				startDate,
				endDate,
				hideFinished,
				fields,
				projectName,
				language
			}),
		{
			staleTime: 600000,
			select: data => {
				return data?.data;
			},
			keepPreviousData: true
		}
	);
	const { t } = useTranslation();

	if (isError) {
		messageStore.snackbar({
			message: t('Failed fetching projects'),
			type: 'error'
		});
	}

	return { data };
};
