import { Close } from '@mui/icons-material';
import { DateRange, DateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { InputAdornment, OutlinedInput } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import calendar from '../../../assets/calendar.svg';
import { DatePickerMainQueryKey, DatePickerQueryKey, useDatePickerQuery } from '../../../queries/view-queries/useDatePickerQuery';
import { PaginationMainQueryKey, usePaginationQueryClient } from '../../../queries/view-queries/usePaginationQuery';
import styles from './DateRangePickerInput.module.scss';

interface Props {
	dates?: DateRange<Date>;
	// setDates?: Dispatch<SetStateAction<DateRange<Date>>>;
	queryKey: DatePickerQueryKey;
}

export const DateRangePickerInput = ({ queryKey }: Props) => {
	/////////////////////////////////////////////// REACT-QUERY ////////////////////////////////////////////////////////
	const { setPaginationData } = usePaginationQueryClient(1);
	const { setDateQuery, dates } = useDatePickerQuery(DatePickerMainQueryKey.dateRangePickerInput, queryKey);
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const displayDateText = () =>
		dates[0] === null && dates[1] === null ? 'All' : `${dates[0]?.toLocaleDateString()} - ${dates[1]?.toLocaleDateString()}`;
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DateRangePicker
				value={dates}
				onChange={e => {
					const start = e[0] !== null ? e[0] : dates[0];
					const end = e[1] !== null ? e[1] : dates[1];
					// setDates([start, end]);
					setDateQuery(DatePickerMainQueryKey.dateRangePickerInput, queryKey, [start, end]);
					setPaginationData(PaginationMainQueryKey.uploads, 1);
				}}
				renderInput={(startProps, endProps) => (
					<OutlinedInput
						endAdornment={calendarAdornment()}
						inputProps={{
							...endProps.inputProps,
							...startProps.inputProps,
							value: displayDateText()
						}}
					/>
				)}
			/>
			{dates[0] !== null && dates[1] !== null && (
				<Close
					fontSize="small"
					onClick={() => setDateQuery(DatePickerMainQueryKey.dateRangePickerInput, queryKey, [null, null])}
					className={styles.clearDateButton}
				/>
			)}
		</LocalizationProvider>
	);
};

export const DateRangePickerTwoInputs = ({ queryKey }: Props) => {
	const displayDateText = (date: Date | null) => (date === null ? 'Choose date' : date.toLocaleDateString());
	const { t } = useTranslation();

	/////////////////////////////////////////////// REACT-QUERY ////////////////////////////////////////////////////////
	const { setPaginationData } = usePaginationQueryClient(1);
	const { setDateQuery, dates } = useDatePickerQuery(DatePickerMainQueryKey.dateRangePickerTwoInputs, queryKey);
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<DateRangePicker
				value={dates}
				onChange={e => {
					const start = e[0] !== null ? e[0] : dates[0];
					const end = e[1] !== null ? e[1] : dates[1];
					// setDates([start, end]);
					setDateQuery(DatePickerMainQueryKey.dateRangePickerTwoInputs, queryKey, [start, end]);
					setPaginationData(PaginationMainQueryKey.uploads, 1);
				}}
				renderInput={(startProps, endProps) => (
					<Box sx={{ display: 'flex', gap: '24px' }}>
						<div>
							<label style={{ color: '#494F4D' }}>{t('startDate')}</label>
							<OutlinedInput
								sx={{ width: '200px' }}
								endAdornment={calendarAdornment()}
								inputProps={{
									...startProps.inputProps,
									value: displayDateText(dates[0])
								}}
							/>
						</div>
						<div>
							<label style={{ color: '#494F4D' }}>{t('endDate')}</label>
							<OutlinedInput
								sx={{ width: '200px' }}
								endAdornment={calendarAdornment()}
								inputProps={{
									...endProps.inputProps,
									value: displayDateText(dates[1])
								}}
							/>
						</div>
					</Box>
				)}
			/>
		</LocalizationProvider>
	);
};

const calendarAdornment = () => (
	<InputAdornment position="end">
		<img src={calendar} alt="calendar" />
	</InputAdornment>
);
