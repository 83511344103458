import { observable, action, decorate, computed } from 'mobx';
import { AdminRes } from '../models/general';

export class AdminStore {
  admins = observable<AdminRes>([]);
  selectedAdmins = observable<AdminRes>([]);
  selectedAdmin: AdminRes | undefined;

  updateUser(value: AdminRes | undefined) {
    this.selectedAdmin = value;
  }

  updateAdmins(newAdmins: AdminRes[]) {
    this.admins.replace(newAdmins);
  }

  get getAdmins(): AdminRes[] {
    return this.admins;
  }

  get getAdmin(): AdminRes | undefined {
    return this.selectedAdmin;
  }

  get getAdminId(): number | undefined {
    return this.selectedAdmin?.ID;
  }

  get getSelectedAdmins(): AdminRes[] {
    return this.selectedAdmins;
  }

  selectedAdminTablePage: number = 0;

  updateSelectedAdminTablePage(selectedAdminTablePage: number) {
    this.selectedAdminTablePage = selectedAdminTablePage;
  }
  get getSelectedAdminTablePage(): number {
    return this.selectedAdminTablePage;
  }
}

decorate(AdminStore, {
  admins: observable,
  selectedAdmin: observable,
  selectedAdminTablePage: observable,

  updateAdmins: action,
  updateSelectedAdminTablePage: action,

  getAdmins: computed,
  getAdmin: computed,
  getSelectedAdminTablePage: computed,
});

export default new AdminStore();
