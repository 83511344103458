import { useQuery } from 'react-query';
import { API } from '../../api';
import messageStore from '../../stores/messageStore';
import { useTranslation } from 'react-i18next';

interface QueryKeysI {
	page: number;
	rowsPerPage: number;
	country?: string;
	mission?: string;
	searchedValue?: string;
}

enum MainQueryKey {
	userListQuery = 'user-list-query'
}

export const useGetUserListQuery = (queryKeys: QueryKeysI) => {
	const { page, rowsPerPage, country, mission, searchedValue } = queryKeys;
	const { isLoading, data, isError } = useQuery(
		[MainQueryKey.userListQuery, page, rowsPerPage, country, mission, searchedValue],
		() => API.getUserList(page, rowsPerPage, country === 'all' ? undefined : country, mission === 'all' ? undefined : mission, searchedValue),
		{
			staleTime: 600000,
			keepPreviousData: true
		}
	);
	const { t } = useTranslation();

	if (isError) {
		messageStore.snackbar({
			message: t('Failed fetching users'),
			type: 'error'
		});
	}

	return {
		users: data?.data?.users || [],
		totalItems: data?.data?.totalItems || 0,
		isLoading
	};
};
