import { observable, computed, action, decorate } from 'mobx';

export class SettingsStore {
  globalLoading: boolean = false;

  updateGlobalLoading(selectedState: boolean) {
    this.globalLoading = selectedState;
  }

  selectedState: number | null = null;

  updateSelectedState(selectedState: number | null) {
    this.selectedState = selectedState;
  }
  get getSelectedState(): number | null {
    return this.selectedState;
  }

  selectedProject: number | null = null;

  updateSelectedProject(selectedProject: number | null) {
    this.selectedProject = selectedProject;
  }
  get getSelectedProject(): number | null {
    return this.selectedProject;
  }

  selectedPeriod: any = null;

  updateSelectedPeriod(selectedPeriod: any) {
    this.selectedPeriod = selectedPeriod;
  }
  get getSelectedPeriod(): any {
    return this.selectedPeriod;
  }

  selectedRowCount: number = 5;

  updateSelectedRowCount(selectedRowCount: number) {
    this.selectedRowCount = selectedRowCount;
  }
  get getSelectedRowCount(): number {
    return this.selectedRowCount;
  }
}

decorate(SettingsStore, {
  globalLoading: observable,
  selectedProject: observable,
  selectedPeriod: observable,
  selectedRowCount: observable,
  selectedState: observable,

  updateGlobalLoading: action,
  updateSelectedProject: action,
  updateSelectedPeriod: action,
  updateSelectedRowCount: action,
  updateSelectedState: action,

  getSelectedProject: computed,
  getSelectedPeriod: computed,
  getSelectedRowCount: computed,
  getSelectedState: computed,
});

export default new SettingsStore();
