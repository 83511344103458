import { Box, Button, FormControl, Pagination, Typography } from '@mui/material';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import uploadIcon from '../../../assets/upload-icon.svg';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import VideoItem from '../../../components/UI/video-item/VideoItem';
import { DateRangePickerInput } from '../../../components/UX/date-range-picker/DateRangePickerInput';
import { UserPaymentsTable } from './UserPaymentsTable';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { UserIdI, UserPaymentsTableDataT } from './types';
import { Upload, User } from '../../../models/general';
import { ProjectsData } from '../../../models/projects';
import SelectList from '../../../components/UX/select-list/SelectList';
import {
	fetchLastPendingUpload,
	fetchLastUploadedVideo,
	fetchPaymentsByUserId,
	fetchProjectLis,
	fetchUploads,
	fetchUploadSummary,
	fetchUsers,
	formatDateToUnixDate
} from './helpers';
import { MainQueryKey, QueryKey } from '../../../queries/view-queries/useSelectListQuery';
import styles from './Account.module.scss';
import arrowLeft from '../../../assets/arrow-left.svg';
import { DatePickerMainQueryKey, DatePickerQueryKey, useDatePickerQuery } from '../../../queries/view-queries/useDatePickerQuery';
import { useGetProjectListQuery } from '../../../queries/data-queries/useGetProjectListQuery';
import { useTranslation } from 'react-i18next';
import { UserTransactionsDialog } from './UserTransactionsDialog';
import { AddBalanceDialog } from './AddBalanceDialog';
import authStore from '../../../stores/authStore';

interface Props {}

export interface UploadSummaryI {
	pending: number;
	uploaded: number;
}

export const Account = (props: Props) => {
	const { userId } = useParams<UserIdI>();
	// const [dates, setDates] = useState<DateRange<Date>>([null, null]);
	const [startDate, setStartDate] = useState<number>();
	const [endDate, setEndDate] = useState<number>();
	const [userDetails, setUserDetails] = useState<User>();
	const [uploads, setUploads] = useState<Upload[]>();
	const [projectList, setProjectList] = useState<ProjectsData[]>();
	const [mission, setMission] = useState<any>('all');
	const [isTransactionDialogOpen, setIsTransactionDialogOpen] = useState(false);
	const [isAddBalanceDialgOpen, setIsAddBalanceDialogOpen] = useState(false);
	const [totalUploads, setTotalUploads] = useState<number>(0);
	const { i18n } = useTranslation();
	const { adminRole } = authStore;
	const [page, setPage] = useState(1);
	const [uploadSummary, setUploadSummary] = useState<UploadSummaryI>({
		pending: 0,
		uploaded: 0
	});
	const [paymentsTableData, setPaymentsTableData] = useState<UserPaymentsTableDataT>([]);
	const [lastPendingVideo, setLastPendingVideo] = useState<Upload | undefined>();
	const [lastUploadedVideo, setLastUploadedVideo] = useState<Upload | undefined>();

	/////////////////////////////////////////////// REACT-QUERY ////////////////////////////////////////////////////////
	const { dates } = useDatePickerQuery(DatePickerMainQueryKey.dateRangePickerInput, DatePickerQueryKey.account);
	const { data: projectListData } = useGetProjectListQuery({ fields: 'id,name', language: i18n.language });
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (dates[0]) {
			const unixDateFrom = formatDateToUnixDate(dates[0]);
			setStartDate(unixDateFrom);
		}

		if (dates[1]) {
			const unixDateTo = formatDateToUnixDate(dates[1]);
			setEndDate(unixDateTo);
		}
	}, [dates]);

	useEffect(() => {
		fetchUsers(+userId, setUserDetails);
		fetchProjectLis(setProjectList);
		fetchPaymentsByUserId(userId, setPaymentsTableData);
	}, [userId]);

	useEffect(() => {
		fetchUploads(
			{
				page,
				// limit: 20,
				userId: +userId,
				startDate: startDate,
				endDate: endDate,
				projectId: mission === 'all' ? undefined : mission
			},
			setUploads,
			setTotalUploads
		);
	}, [userId, startDate, endDate, mission, page]);

	useEffect(() => {
		fetchLastUploadedVideo(+userId, setLastUploadedVideo);
		fetchLastPendingUpload(+userId, setLastPendingVideo);
	}, [userId]);

	useEffect(() => {
		fetchUploadSummary(+userId, setUploadSummary);
	}, [userId]);

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value);
	};

	const search = useLocation().search;
	const urlPar = new URLSearchParams(search).get('prev');
	const history = useHistory();

	return (
		<SimpleLayout passedStyles="">
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<div className={styles.backButton}>
					<Button onClick={history.goBack} sx={{ '&.MuiButtonBase-root:hover': { bgcolor: 'transparent' } }} variant="text" color="primary">
						<img src={arrowLeft} alt={'arrow left'} />
						{urlPar?.includes('payments') && t('backToPayments')}
						{urlPar?.includes('users') && t('backToUsers')}
					</Button>
				</div>
			</Box>
			<Typography variant="h1">{t('accountDetails')}</Typography>
			<Box
				sx={{
					backgroundColor: 'secondary.100',
					width: '50%',
					borderRadius: '24px',
					padding: '24px',
					boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.07)'
				}}>
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
					<Typography variant="h2">{`${userDetails?.firstName} ${userDetails?.lastName}`}</Typography>
					<Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
						<Button variant="text" size="small" onClick={() => setIsTransactionDialogOpen(true)}>
							View Transactions
						</Button>
						{adminRole === 'superadmin' && (
							<Button variant="text" size="small" onClick={() => setIsAddBalanceDialogOpen(true)}>
								Manually change balance
							</Button>
						)}
					</Box>
				</Box>
				<Box sx={{ display: 'flex', gap: '24px', padding: '24px 0' }}>
					<Typography variant="body2">{`${t('Pending')}: ${uploadSummary.pending}`}</Typography>
					<Typography variant="body2">{`${t('Uploaded')}: ${uploadSummary.uploaded}`}</Typography>
				</Box>
				<Typography variant="body2">{`${t('ID')}: ${userDetails?.id}`}</Typography>
				<Typography variant="body2">{`${t('username')}: ${userDetails?.username}`}</Typography>
				<Typography variant="body2">{`${t('Email')}: ${userDetails?.email}`}</Typography>
				<Typography variant="body2">{`${t('country')}: ${userDetails?.countryName}`}</Typography>

				<Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '24px' }}>
					<Typography variant="body2">{`${t('Current wallet balance')}: ${userDetails?.wallet?.balance}`}</Typography>
					<Typography variant="body2">{`${t('All time wallet balance')}: ${userDetails?.wallet?.all_time_gains}`}</Typography>
				</Box>
			</Box>
			{/* todo fix videoItem styling */}
			<Box sx={{ display: 'flex', gap: '24px', width: '50%', marginBottom: '50px' }}>
				<Box sx={{ width: '100%' }}>
					<Typography variant="h2" paddingBottom={'24px'}>
						{t('lastPendingVideo')}
					</Typography>

					{lastPendingVideo && <VideoItem video={lastPendingVideo} backLink={{ from: 'User Details', url: `profile/1` }} />}
				</Box>
				<Box sx={{ width: '100%' }}>
					<Typography variant="h2" paddingBottom={'24px'}>
						{t('lastUploadedVideo')}
					</Typography>
					{uploads && <VideoItem video={lastUploadedVideo} />}
				</Box>
			</Box>
			<Typography variant="h2">{t('allVideos')}</Typography>
			<Box sx={{ display: 'flex', gap: '24px' }}>
				<SelectList
					queryKey={QueryKey.account}
					mainQueryKey={MainQueryKey.mission}
					labelId="mission"
					id="mission-select"
					htmlFor="mission"
					labelText={t('mission')}
					data={projectListData?.projects}
				/>
				<FormControl sx={{ width: '300px' }}>
					<label htmlFor="mission">{t('dateRange')}</label>
					<DateRangePickerInput dates={dates} queryKey={DatePickerQueryKey.account} />
				</FormControl>
			</Box>
			<Box sx={{ display: 'grid', gap: '24px', gridTemplateColumns: 'repeat(4, 1fr)' }}>
				{uploads?.map(upload => {
					return <VideoItem key={upload.id} video={upload} />;
				})}
			</Box>
			<Box sx={{ float: 'right' }}>
				<Pagination
					sx={{ display: 'flex', justifyContent: 'center' }}
					// count={Math.ceil(totalUploads / 8)}
					count={Math.ceil(totalUploads / 10)}
					page={page}
					onChange={handlePageChange}
					showFirstButton={false}
					showLastButton={false}
				/>
			</Box>
			<>
				<Typography variant="h2">{t('paymentData')}</Typography>
				{false ? (
					<Typography variant="caption">{t('No payment data for this user so far.')}</Typography>
				) : (
					<UserPaymentsTable paymentsTableData={paymentsTableData} />
				)}
			</>
			<UserTransactionsDialog isOpen={isTransactionDialogOpen} userId={Number(userId)} onClose={() => setIsTransactionDialogOpen(false)} />
			<AddBalanceDialog open={isAddBalanceDialgOpen} onClose={() => setIsAddBalanceDialogOpen(false)} userId={Number(userId)} />
		</SimpleLayout>
	);
};
