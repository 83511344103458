import { Chip, Box, Divider, Typography } from '@mui/material';
import { t, use } from 'i18next';
import { observer } from 'mobx-react';
import projectEditorStore from '../../../stores/projectEditorStore';
import { MissionType } from '../videos/Uploads';
import { InfoTooltip } from './InfoTooltip';
import { ProjectEditorSection } from './ProjectEditorComponents';
import { useHistory } from 'react-router-dom';

export const ProjectTypeSelector = observer(() => {
	const { project, errors } = projectEditorStore;
	const history = useHistory();

	const ClickableChip = (props: { selected: boolean; text: string; projectTypeId: number }) => {
		const { selected, text, projectTypeId } = props;
		const { setProjectTypeId, project, errors } = projectEditorStore;

		const handleChipClick = () => {
			// If the project type is custom, do not change the project type.
			// Unsure yet if clicking here should open the custom project type editor
			if (projectTypeId === MissionType.custom) history.push('/mission-editor/0');
			setProjectTypeId(projectTypeId);
		};

		return (
			<Chip
				id={projectTypeId.toString()} // Converting projectTypeId to string as id should be a string
				color={selected ? 'primary' : 'default'}
				label={text}
				disabled={project.id > 0}
				onClick={handleChipClick} // Corrected to use handleChipClick
			/>
		);
	};

	return (
		<ProjectEditorSection title={t('projectEditor.projectType.title')} tooltip={t('projectEditor.projectType.tooltip')}>
			<Box sx={{ display: 'flex', flexDirection: 'row', gap: '10px', justifyContent: 'center' }}>
				<ClickableChip selected={project.projectTypeId === MissionType.video} text="Video" projectTypeId={MissionType.video} />
				<ClickableChip selected={project.projectTypeId === MissionType.photo} text="Photo" projectTypeId={MissionType.photo} />
				<ClickableChip selected={project.projectTypeId === MissionType.custom} text="Custom" projectTypeId={MissionType.custom} />
				<ClickableChip
					selected={project.projectTypeId === MissionType.invasiveSpecies}
					text="Invasive Species"
					projectTypeId={MissionType.invasiveSpecies}
				/>
			</Box>
		</ProjectEditorSection>
	);
});
