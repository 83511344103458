import React, { useCallback, useEffect, useState } from 'react';
import styles from './Leaderboard.module.scss';
import { inject, observer } from 'mobx-react';
import { LeaderboardWithFiltering, LeaderboardUser } from '../../../models/general';
import { Header } from '../../../models/tables';
import { API } from '../../../api';
import messageStore from '../../../stores/messageStore';
import { useTranslation } from 'react-i18next';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import settingsStore from '../../../stores/settingsStore';
import { LinearProgress } from '@material-ui/core';

import MaterialTable from 'material-table';
import { Button, Chip } from '@mui/material';
import { Link } from 'react-router-dom';

const Leaderboard = () => {
	const { t } = useTranslation();
	const [rows, setRows] = useState<LeaderboardUser[]>([]);
	const [loading, setloading] = useState(true);

	const headCells: any[] = [
		{
			field: 'rank',
			title: t('rank'),
			sortable: true,
			cellStyle: {
				width: '10%'
			},
			headerStyle: {
				width: '10%'
			}
		},
		{
			field: 'username',
			title: t('username'),
			cellStyle: {
				width: '70%'
			},
			render: (user: any) => {
				return (
					<Link style={{ textDecoration: 'none' }} to={`${process.env.PUBLIC_URL}/profile/${user.userId}?prev=users`}>
						<Button
							sx={{
								textDecoration: 'underline'
							}}
							variant="text"
							size="small">
							{user.username}
						</Button>
					</Link>
				);
			}
		},
		{
			field: 'total',
			title: t('total'),
			render: (user: any) => {
				const total = user.total as number;
				return <Chip sx={{ backgroundColor: '#39A88F', color: 'white', paddingX: '10px' }} label={total.toFixed(2)} />;
			}
		}
	];

	const loadLeaderboard = useCallback(async () => {
		const response = await API.getLeaderboard();
		// Add rank for each response.
		setRows(
			response.data.users.map((u, index) => {
				return { ...u, rank: index + 1 };
			})
		);

		setloading(true);
		if (response) {
			setloading(false);
		} else {
			setloading(false);
			messageStore.snackbar({
				message: `${t('Leaderboard load failed')}`,
				type: 'error'
			});
		}
	}, [t]);

	useEffect(() => {
		loadLeaderboard();
	}, [loadLeaderboard]);

	return (
		<SimpleLayout passedStyles={styles.root}>
			{rows.length > 0 ? (
				<MaterialTable
					title={t('Leaderboard')}
					columns={headCells}
					data={rows}
					options={{
						search: true,
						pageSize: 10,
						pageSizeOptions: [5, 10, 15],
						headerStyle: {
							backgroundColor: '#f9f8f2'
						}
					}}
					components={{
						Container: (props: any) => <CarchupaPaper {...props} type="column" parentStyle={styles.paper} childStyle={styles.table} />
					}}
					onChangeRowsPerPage={(rows: any) => {
						settingsStore.updateSelectedRowCount(rows);
					}}
				/>
			) : loading ? (
				<LinearProgress />
			) : (
				<CarchupaPaper type="row" parentStyle={styles.paper}>
					<div>{t('noUserPaymentsText')}</div>
				</CarchupaPaper>
			)}
		</SimpleLayout>
	);
};

export default inject('userStore', 'messageStore', 'settingsStore')(observer(Leaderboard));
