import { useQuery } from 'react-query';
import { API } from '../../api';
import { PhotoExportMarkersI } from '../../models/projects';

enum MainQueryKey {
	photoExportMarkers = 'photo-export-markers'
}

export const useGetPhotoExportMarkers = (params: PhotoExportMarkersI, projectTypeId?: number) => {
	const { data } = useQuery([MainQueryKey.photoExportMarkers, params], () => API.getPhotoExportMarkers(params), {
		staleTime: 600000,
		// enabled: projectTypeId === 2 ? true : false,
		keepPreviousData: true,
		select: data => {
			return data?.data;
		}
	});

	return {
		markers: data?.markers ? data.markers : []
	};
};
