import { Box, FormControlLabel, Checkbox, TextField } from "@mui/material";
import { t } from "i18next";
import { observer } from "mobx-react";
import missionEditorStore, { Step } from "../../../stores/missionEditorStore";

export const PreviewStep = observer((props: { step: Step }) => {
    const { setStepProperty, errors} = missionEditorStore;
    const { step } = props;
    const stepIndex = step.order - 1;

    return (
        <Box sx={{ paddingTop: '20px', display: 'flex', gap: "20px", flexWrap: "wrap", flexDirection: "column", justifyContent: "flex-start"  }}>
            <div>
                <FormControlLabel
                    label={t("missionEditor.previewStep.portrait") as string} 
                    value={step.previewParams?.portrait}
                    control={
                        <Checkbox 
                            onChange={(e) => setStepProperty(step, "portrait", e.target.checked)}
                            checked={step.previewParams?.portrait}
                        />
                    }
                 />
            </div>
            <TextField 
                name="horizonPercentage" 
                variant="outlined" 
                label={t("missionEditor.previewStep.horizonPercentage")} 
                error={errors[`[${stepIndex}].previewParams.horizonPercentage`]}
                helperText={errors[`[${stepIndex}].previewParams.horizonPercentage`]}              
                type="number" 
                value={step.previewParams?.horizonPercentage}
                onChange={(e) => setStepProperty(step, "horizonPercentage", e.target.value)}
            />
            <TextField 
                name="horizonText"
                variant="outlined" 
                label={t("missionEditor.previewStep.horizonText")} 
                error={errors[`[${stepIndex}].previewParams.horizonText`]}
                helperText={errors[`[${stepIndex}].previewParams.horizonText`]}               
                value={step.previewParams?.horizonText}
                onChange={(e) => setStepProperty(step, "horizonText", e.target.value)} 
            />
            <TextField 
                name="instructionText" 
                variant="outlined" 
                label={t("missionEditor.previewStep.instructionText")}
                error={errors[`[${stepIndex}].previewParams.instructionText`]}
                helperText={errors[`[${stepIndex}].previewParams.instructionText`]}      
                value={step.previewParams?.instructionText}
                onChange={(e) => setStepProperty(step, "instructionText", e.target.value)}
            />
            <div>
                <FormControlLabel
                    label={t("missionEditor.previewStep.popupWindowEnabled") as string} 
                    value={step.previewParams?.popupEnabled}
                    control={
                        <Checkbox 
                            onChange={(e) => setStepProperty(step, "popupEnabled", e.target.checked)}
                            checked={step.previewParams?.popupEnabled}
                        />
                    }
                 />
            </div>
            <TextField 
                name="popupTitle" 
                variant="outlined" 
                label={t("missionEditor.previewStep.popupTitle")} 
                error={errors[`[${stepIndex}].previewParams.popupTitle`]}
                helperText={errors[`[${stepIndex}].previewParams.popupTitle`]}      
                value={step.previewParams?.popupTitle}
                disabled={!step.previewParams?.popupEnabled}
                onChange={(e) => setStepProperty(step, "popupTitle", e.target.value)}
            />
            <TextField 
                name="popupText"
                variant="outlined" 
                label={t("missionEditor.previewStep.popupText")} 
                error={errors[`[${stepIndex}].previewParams.popupText`]}
                helperText={errors[`[${stepIndex}].previewParams.popupText`]}      
                value={step.previewParams?.popupText}
                disabled={!step.previewParams?.popupEnabled}
                onChange={(e) => setStepProperty(step, "popupText", e.target.value)}
            />
            <TextField 
                name="popupButtonText" 
                variant="outlined" 
                label={t("missionEditor.previewStep.popupButtonText")} 
                error={errors[`[${stepIndex}].previewParams.popupButtonText`]}
                helperText={errors[`[${stepIndex}].previewParams.popupButtonText`]}      
                value={step.previewParams?.popupButtonText}
                disabled={!step.previewParams?.popupEnabled}
                onChange={(e) => { setStepProperty(step, "popupButtonText", e.target.value) }}
            />
        </Box> 
    )
})