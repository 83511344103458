// API.getProjectCollectablesSummary(projectId);

import { useQuery } from 'react-query';
import { API } from '../../api';

enum ProjectCollectablesSummary {
	MainQueryKey = 'project-collectables-summary'
}

export const useGetProjectCollectablesSummaryData = (projectId: number) => {
	const { data } = useQuery([ProjectCollectablesSummary.MainQueryKey, projectId], () => API.getProjectCollectablesSummary(projectId), {
		staleTime: 600000,
		select: data => data?.data
	});

	return { data };
};
