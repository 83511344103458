import { useQuery, useQueryClient } from 'react-query';

export enum DatePickerMainQueryKey {
	dateRangePickerInput = 'date-range-picker-input',
	dateRangePickerTwoInputs = 'date-range-picker-two-inputs'
}

export enum DatePickerQueryKey {
	uploads = 'uploads',
	account = 'account',
	payments = 'payments',
	projects = 'projects',
	paymentsModal = 'payments-modal',
	videoCreationDate = 'video-creation-date' // map view video filter by date NOT FINISHED- should it be globalMap?
}

export const useDatePickerQuery = (mainQueryKey: DatePickerMainQueryKey, queryKey: DatePickerQueryKey) => {
	const queryClient = useQueryClient();

	const setDateQuery = (mainQueryKey: DatePickerMainQueryKey, queryKey: DatePickerQueryKey, date: Array<Date | null>) => {
		queryClient.setQueryData([mainQueryKey, queryKey], date);
	};

	const getDateQuery = (mainQueryKey: DatePickerMainQueryKey, queryKey: DatePickerQueryKey): any => {
		const data: Array<Date | null> | undefined = queryClient.getQueryData([mainQueryKey, queryKey]);
		return data;
	};

	const { data } = useQuery([mainQueryKey, queryKey], () => getDateQuery(mainQueryKey, queryKey), { cacheTime: Infinity });

	return {
		setDateQuery,
		getDateQuery,
		dates: data || [null, null]
	};
};
