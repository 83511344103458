import React, { useState, useMemo, useCallback, FormEvent, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Paper, Dialog, Button, FormControlLabel, Switch, Grid, Card } from '@material-ui/core';
import { API } from '../../../api';
import DeleteIcon from '@material-ui/icons/Delete';
import { useCallbackWithLoading } from '../../../hooks/use-callback-with-loading';
import styles from './Coins.module.scss';
import MaterialTable from 'material-table';
import { Header } from '../../../models/tables';
import { useTranslation } from 'react-i18next';
import messageStore from '../../../stores/messageStore';
import { inject, observer } from 'mobx-react';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import settingsStore from '../../../stores/settingsStore';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import ProjectSelect from '../../../components/UX/project-select/ProjectSelect';
import { CSVFilesData } from '../../../models/general';
import moment from 'moment';

interface Props extends RouteComponentProps {}

const Coins = (props: Props) => {
	const [upload, uploadPending] = useCallbackWithLoading(API.uploadCollectables);
	const { t } = useTranslation();
	const [dialogDelete, setDialogDelete] = useState(false);
	const [dialogDeleteAll, setDialogDeleteAll] = useState(false);
	const { selectedRowCount, selectedProject } = settingsStore;
	const [dense, setDense] = React.useState(false);
	const [data, setData] = useState<any>({});
	const [open, setOpen] = React.useState(false);
	const canSubmit = useMemo(() => {
		return !!data?.selectedFiles?.length && !!selectedProject;
	}, [data, selectedProject]);
	const [rows, setRows] = useState<CSVFilesData[]>([]);
	const [selectedCoins, setSelectedCoins] = useState<number[]>([]);

	const headCells: Header[] = [
		{
			field: 'ID',
			title: t('ID'),
			sortable: true
		},
		{
			field: 'originalName',
			title: t('File Name'),
			sortable: true
		},
		{
			field: 'importedAt',
			title: t('Uploaded'),
			sortable: true,
			render: (rowData: CSVFilesData) => <span>{moment(rowData.importedAt).format('YYYY/MM/DD HH:ss')}</span>
		}
	];

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDense(event.target.checked);
	};

	const handleDataChange = useCallback((event: any) => {
		const list = Array.from(event.target.files);
		setData({
			selectedFiles: list,
			loaded: 0
		});
	}, []);

	const loadCoinList = useCallback(async () => {
		settingsStore.updateGlobalLoading(true);
		const response = await API.getCollectablesList(selectedProject);
		if (response) {
			settingsStore.updateGlobalLoading(false);

			// console.log(response.data);
			setRows(response.data.files);
			messageStore.snackbar({
				message: t('Coins loaded'),
				type: 'success'
			});
		} else {
			settingsStore.updateGlobalLoading(false);
			messageStore.snackbar({
				message: t('Failed fetching projects'),
				type: 'error'
			});
		}
	}, [selectedProject, t]);

	const handleButtonDeleteAll = () => {
		setDialogDeleteAll(true);
	};

	useEffect(() => {
		loadCoinList();
	}, [loadCoinList, selectedProject]);

	const handleSubmit = useCallback(
		async (e: FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			if (!canSubmit) {
				return;
			} else {
				try {
					data.selectedFiles.map(async (file: any) => {
						const forSending = new FormData();

						forSending.append('CSVFile', file);
						// canSubmit is checking that project is selected
						await upload(forSending, selectedProject as number)
							.then(res => {
								loadCoinList();
								messageStore.snackbar({
									message: `${t('Upload successfull')}`,
									type: 'success'
								});
							})
							.catch(error => {
								messageStore.snackbar({
									message: `${t('Fail uploading to Database - ') + error}`,
									type: 'error'
								});
								console.error('Fail uploading coins request', error);
							});

						setData({});
					});
				} catch (error) {
					messageStore.snackbar({
						message: `${t('Fail uploading coins')}`,
						type: 'error'
					});
					console.error('Fail uploading coins', error);
					setData({});
				}
			}
		},
		[canSubmit, data.selectedFiles, upload, selectedProject, loadCoinList, t]
	);

	return (
		<SimpleLayout passedStyles={styles.root}>
			<CarchupaPaper type="row" parentStyle={styles.paper}>
				<ProjectSelect />
			</CarchupaPaper>

			<Paper className={styles.paper}>
				<MaterialTable
					title={t('Coins')}
					columns={headCells}
					data={rows}
					options={{
						search: true,
						exportButton: true,
						selection: true,
						exportAllData: true,
						padding: dense ? 'dense' : 'default',
						pageSize: selectedRowCount
					}}
					actions={[
						{
							icon: 'cloud_upload',
							tooltip: t('Upload File'),
							isFreeAction: true,
							onClick: (event, rowData) => handleClickOpen()
						},
						{
							tooltip: t('Remove All Selected Files'),
							icon: 'delete',
							onClick: (data: CSVFilesData[]) => {
								setDialogDelete(true);
							}
						}
					]}
					onChangeRowsPerPage={rows => {
						settingsStore.updateSelectedRowCount(rows);
					}}
					onSelectionChange={(rows: CSVFilesData[]) => {
						console.log(
							'rows',
							rows.map(el => el.ID)
						);

						setSelectedCoins(rows.map(el => el.ID));
					}}
				/>
			</Paper>
			<FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label={t('Dense padding')} />
			<Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
				<Grid container direction="column" justify="center" alignItems="center" className={styles.modal}>
					<form onSubmit={handleSubmit}>
						<Card elevation={0}>
							<Grid container direction="column" justify="center" alignItems="center">
								<input multiple type="file" name="file" onChange={handleDataChange} />
								<br />
								<Button className={styles.inputs} type="submit" variant="contained" disabled={!canSubmit || uploadPending}>
									{t('upload_message')}
								</Button>
								<em>{t('A single project needs to be selected.')}</em>
							</Grid>
						</Card>
					</form>
				</Grid>
			</Dialog>
			<Button startIcon={<DeleteIcon />} className={styles.button} variant="contained" color="primary" type="submit" onClick={handleButtonDeleteAll}>
				{t('deleteAllCollectables')}
			</Button>

			{/* <CarchupaDialog
        open={dialogDeleteAll}
        handleClose={onCloseDialogDeleteAll}
        message={t('Are you sure that you want to delete all coins for all selected project?')}
        title={t('Confirm Delete')}
      /> */}

			{/* <CarchupaDialog
        open={dialogDelete}
        handleClose={onCloseDialogDelete}
        message={t('Are you sure that you want to delete coins for selected project?')}
        title={t('Confirm Delete')}
      /> */}
		</SimpleLayout>
	);
};

export default inject('messageStore', 'settingsStore')(observer(Coins));
