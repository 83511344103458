import authStore from './authStore';
import userStore from './userStore';
import sessionsStore from './sessionsStore';
import messageStore from './messageStore';
import settingsStore from './settingsStore';
import videoStore from './videoStore';
import playerStateStore from './playerStateStore';
import selectListsOptionsStore from './selectListsOptionsStore';
import missionEditorStore from './missionEditorStore';
import projectEditorStore from './projectEditorStore';

export default {
	authStore,
	userStore,
	sessionsStore,
	messageStore,
	settingsStore,
	videoStore,
	playerStateStore,
	selectListsOptionsStore,
	missionEditorStore,
	projectEditorStore
};
