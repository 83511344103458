import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { API } from '../../api';
import { Upload } from '../../models/general';
import { UploadsParams } from '../../models/video';
import messageStore from '../../stores/messageStore';

export enum UploadsDataMainQueryKey {
	uploadsDataQuery = 'uploads-data-query'
}

interface UseGetUploadsDataReturnI {
	countItems?: number;
	page?: number;
	totalItems: number;
	uploads: Upload[];
}

export const useGetUploadsData = (params: UploadsParams): UseGetUploadsDataReturnI => {
	const { data, isError } = useQuery([UploadsDataMainQueryKey.uploadsDataQuery, params], () => API.getUploads(params), {
		staleTime: 600000,
		keepPreviousData: true
	});

	const { t } = useTranslation();

	if (isError) {
		messageStore.snackbar({
			message: t('Failed fetching projects'),
			type: 'error'
		});
	}

	return { countItems: data?.data?.countItems, page: data?.data?.page, totalItems: data?.data?.totalItems || 0, uploads: data?.data?.uploads || [] };
};
