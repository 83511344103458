import { Box, Button, FormControl, Typography } from '@mui/material';
import saveAs from 'file-saver';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { API } from '../../../api';
import downloadCsv from '../../../assets/download-icon.svg';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import { DateRangePickerTwoInputs } from '../../../components/UX/date-range-picker/DateRangePickerInput';
import SelectList from '../../../components/UX/select-list/SelectList';
import { MainQueryKey, QueryKey } from '../../../queries/view-queries/useSelectListQuery';
import paymentsStore from '../../../stores/paymentsStore';
import { PaymentsTable } from './PaymentsTable';
import { PaymentUpdateModal } from './PaymentUpdateModal';
import { useSelectListQuery } from '../../../queries/view-queries/useSelectListQuery';
import { useGetProjectListQuery } from '../../../queries/data-queries/useGetProjectListQuery';
import moment from 'moment';
import { toast } from 'react-toastify';
import { DatePickerMainQueryKey, DatePickerQueryKey, useDatePickerQuery } from '../../../queries/view-queries/useDatePickerQuery';

export const AllUserPayments = () => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const paymentIds = paymentsStore.getPayments.map(payment => payment.id);

	/////////////////////////////////////////////// REACT-QUERY ////////////////////////////////////////////////////////
	const { data: projectListData } = useGetProjectListQuery({ fields: 'id,name' });
	const { getSelectedOption: getSelectedOptionForMission } = useSelectListQuery(MainQueryKey.mission, QueryKey.payments);
	const mission = getSelectedOptionForMission(MainQueryKey.mission, QueryKey.payments);
	const { dates } = useDatePickerQuery(DatePickerMainQueryKey.dateRangePickerTwoInputs, DatePickerQueryKey.payments);

	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const handleSubmit = () => {};

	const handleDownloadPaymentCSV = async (paymentIds: number[]) => {
		const response = await API.getPaymentsCSV(paymentIds);
		try {
			let blob = new Blob([response], { type: 'text/csv;charset=utf-8;' });
			saveAs(blob, `Crowdsorsa-CSV-${moment().format('YYYY-MM-DD')}.csv`);
		} catch (err: any) {
			err.data.message ? toast.error(err.data.message) : toast.error('Something went wrong');
		}
	};

	return (
		<SimpleLayout passedStyles="">
			<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Typography variant="h1">{t('Payments')}</Typography>
				<Button onClick={() => handleDownloadPaymentCSV(paymentIds)} variant="text" endIcon={<img src={downloadCsv} alt="download csv" />}>
					{t('downloadACsv')}
				</Button>
			</Box>
			<Box sx={{ display: 'flex', width: '100%', gap: '24px' }}>
				<FormControl sx={{ width: '300px', opacity: 0.8 }}>
					<SelectList
						queryKey={QueryKey.payments}
						mainQueryKey={MainQueryKey.mission}
						labelId="mission"
						id="mission-select"
						htmlFor="mission"
						labelText={t('mission')}
						data={projectListData?.projects}
					/>
				</FormControl>
				<FormControl sx={{ width: '300px', opacity: 0.8 }}>
					<DateRangePickerTwoInputs dates={dates} queryKey={DatePickerQueryKey.payments} />
				</FormControl>
			</Box>
			<PaymentsTable handleDownloadPaymentCSV={handleDownloadPaymentCSV} mission={mission} dates={dates} />
			<PaymentUpdateModal setOpen={setOpen} handleSubmit={handleSubmit} open={open} />
		</SimpleLayout>
	);
};
