import { LoadingButton } from '@mui/lab';
import { Dialog, DialogTitle, DialogContent, Select, SelectChangeEvent, MenuItem, Box, TextareaAutosize, DialogActions } from '@mui/material';
import { useState, useMemo, useEffect } from 'react';
import { toast } from 'react-toastify';
import { API } from '../../../api';
import { RejectionReasonsData } from '../../../models/general';

export const RejectionDialog = (props: {
	isOpen: boolean;
	marker: any;
	onClose: () => void;
	uploadId: number;
	onItemDialogClose: () => void;
	updateResponse: (uploadId: number, newStatus: string) => void;
}) => {
	const { isOpen, onClose, uploadId, onItemDialogClose, marker, updateResponse } = props;

	const [rejectionReasons, setRejectionReasons] = useState<RejectionReasonsData[]>([]);
	const [rejectionReason, setRejectionReason] = useState<string>('');
	const [customRejectionReason, setCustomRejectionReason] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const rejectionReasonsMemo = useMemo(() => rejectionReasons, [rejectionReasons]);

	const isCustomRejection = Number(rejectionReason) == rejectionReasons.length + 1;

	useEffect(() => {
		API.getRejectionReasons()
			.then(res => {
				setRejectionReasons(res.data.rejectionReasons);
			})
			.catch(err => {
				console.error(err);
			})
			.finally(() => {});
	}, []);

	const handleReject = () => {
		setIsLoading(true);

		let bodyParams: { customRejectionReason?: string; rejectionReasonId?: number } = {};

		if (isCustomRejection) {
			bodyParams['customRejectionReason'] = customRejectionReason;
		} else {
			bodyParams['rejectionReasonId'] = Number(rejectionReason);
		}

		API.patchUploads(uploadId, 'reject', bodyParams)
			.then(res => {
				toast.success(`Upload ${uploadId} rejected`, { autoClose: 1000 });
				setIsLoading(false);
				onClose();
				onItemDialogClose();
				updateResponse(uploadId, 'rejected');
			})
			.catch(err => {
				toast.error(`Error rejecting ${uploadId}: ${err.message}`, { autoClose: 1000 });
				setIsLoading(false);
				console.error(err);
			});
	};

	return (
		<Dialog open={isOpen} onClose={onClose} fullWidth>
			<DialogTitle>Reject Upload</DialogTitle>
			<DialogContent>
				<Select
					fullWidth
					displayEmpty
					value={rejectionReason}
					onChange={(e: SelectChangeEvent) => {
						setRejectionReason(e.target.value);
					}}>
					<MenuItem value="" disabled>
						Select a reason
					</MenuItem>
					{rejectionReasonsMemo.map(reason => {
						return <MenuItem value={reason.id}>{reason.description}</MenuItem>;
					})}
					<MenuItem value={rejectionReasons.length + 1}>Custom Reason</MenuItem>
				</Select>

				{isCustomRejection && (
					<Box sx={{ display: 'flex', flexDirection: 'column', paddingTop: '20px' }}>
						<div style={{ color: customRejectionReason.length >= 255 ? '#e74c3c' : undefined, transition: 'color 0.5s' }}>
							Custom rejection reason {customRejectionReason.length} / 255
						</div>
						<TextareaAutosize minRows={5} value={customRejectionReason} onChange={e => setCustomRejectionReason(e.target.value)} />
					</Box>
				)}
			</DialogContent>
			<DialogActions>
				<LoadingButton color="primary" onClick={handleReject} loading={isLoading} disabled={customRejectionReason.length >= 255}>
					Confirm
				</LoadingButton>
				<LoadingButton color="error" onClick={onClose} loading={isLoading}>
					Cancel
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};
