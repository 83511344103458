import React from 'react';
import { Container } from './CrowdsorsaRadioButton.styled';

interface Props {
	inputName: string;
	inputId: string;
	checked?: boolean;
	value: number;
	register: any;
	registerValue: string;
	isChecked?: boolean;
	onClick?: any;
	onChange?: any;
	isDisabled?: boolean;
}

const CrowdsorsaRadioButton = (props: Props) => {
	const { inputName, inputId, value, register, registerValue, isChecked, onClick, onChange, isDisabled } = props;

	return (
		<Container>
			<input
				disabled={isDisabled}
				onClick={onClick}
				onChange={onChange}
				{...register(registerValue)}
				defaultChecked={isChecked}
				value={value}
				type="radio"
				name={inputName}
				id={inputId}
			/>
			<label htmlFor={inputId}>{inputId}</label>
			<br />
		</Container>
	);
};

export default CrowdsorsaRadioButton;
