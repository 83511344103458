import { useQueryClient, useQuery } from 'react-query';

export enum PaginationMainQueryKey {
	usersDataTable = 'users-data-table-pagination',
	projects = 'projects-pagination',
	uploads = 'uploads-pagination',
	users = 'users'
}

type DefaultPageType = number | undefined;

export const usePaginationQueryClient = (defaultPage: DefaultPageType = 1) => {
	const queryClient = useQueryClient();

	const setPaginationData = (mainQueryKey: PaginationMainQueryKey, page: number) => {
		queryClient.setQueryData(mainQueryKey, page);
	};

	const getPaginationData = (mainQueryKey: PaginationMainQueryKey): number => {
		return queryClient.getQueryData(mainQueryKey) || defaultPage;
	};

	return {
		setPaginationData,
		getPaginationData
	};
};

export const usePaginationQuery = (mainQueryKey: PaginationMainQueryKey, page: number, defaultPage: DefaultPageType = 1) => {
	const { data } = useQuery(mainQueryKey, () => page, {
		cacheTime: Infinity
	});

	return { paginationPage: data || defaultPage };
};
