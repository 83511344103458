import authStore from '../stores/authStore';
import axios from 'axios';

export async function uploadPolygon(projectId: number, polygon: File) {
	const form = new FormData();
	form.append('file', polygon);
	const res = await axios.put(`${process.env.REACT_APP_GIS_API}/api/upload/project/${projectId}`, form, {
		headers: {
			authorization: `Bearer ${authStore.token}`,
			// @ts-ignore
			'X-Api-Key': process.env.REACT_APP_API_KEY
		}
	});
	return res;
}

export async function getProjectGameAreaPolygon(projectId: number) {
	const res = await axios.get(`${process.env.REACT_APP_GIS_API}/api/getprojectgamearea/${projectId}`, {
		headers: {
			authorization: `Bearer ${authStore.token}`,
			// @ts-ignore
			'X-Api-Key': process.env.REACT_APP_API_KEY
		}
	});
	return res;
}

export async function getPhotoProjectStyle(projectId: number, satellite: boolean = false) {
	if (satellite) {
		const res = await axios.get(`${process.env.REACT_APP_GIS_API}/api/photosatstyle/${projectId}`, {
			headers: {
				authorization: `Bearer ${authStore.token}`,
				// @ts-ignore
				'X-Api-Key': process.env.REACT_APP_API_KEY
			}
		});
		return res;
	} else {
		const res = await axios.get(`${process.env.REACT_APP_GIS_API}/api/photostyle/${projectId}`, {
			headers: {
				authorization: `Bearer ${authStore.token}`,
				// @ts-ignore
				'X-Api-Key': process.env.REACT_APP_API_KEY
			}
		});
		return res;
	}
}

export async function getVideoProjectStyle(projectId: number, satellite: boolean = false) {
	if (satellite) {
		const res = await axios.get(`${process.env.REACT_APP_GIS_API}/api/projectsatstyle/${projectId}`, {
			headers: {
				authorization: `Bearer ${authStore.token}`,
				// @ts-ignore
				'X-Api-Key': process.env.REACT_APP_API_KEY
			}
		});
		return res;
	} else {
		const res = await axios.get(`${process.env.REACT_APP_GIS_API}/api/projectstyle/${projectId}`, {
			headers: {
				authorization: `Bearer ${authStore.token}`,
				// @ts-ignore
				'X-Api-Key': process.env.REACT_APP_API_KEY
			}
		});
		return res;
	}
}

export async function getCustomProjectStyle(projectId: number, satellite: boolean = false) {
	if (satellite) {
		const res = await axios.get(`${process.env.REACT_APP_GIS_API}/api/customsatstyle/${projectId}`, {
			headers: {
				authorization: `Bearer ${authStore.token}`,
				// @ts-ignore
				'X-Api-Key': process.env.REACT_APP_API_KEY
			}
		});
		return res;
	} else {
		const res = await axios.get(`${process.env.REACT_APP_GIS_API}/api/customstyle/${projectId}`, {
			headers: {
				authorization: `Bearer ${authStore.token}`,
				// @ts-ignore
				'X-Api-Key': process.env.REACT_APP_API_KEY
			}
		});
		return res;
	}
}

export async function getPhotoSatProjectStyle(projectId: number) {
	const res = await axios.get(`${process.env.REACT_APP_GIS_API}/api/photosatstyle/${projectId}`, {
		headers: {
			authorization: `Bearer ${authStore.token}`,
			// @ts-ignore
			'X-Api-Key': process.env.REACT_APP_API_KEY
		}
	});
	return res;
}

export async function getInvasiveProjectStyle(projectId: number) {
	const res = await axios.get(`${process.env.REACT_APP_GIS_API}/api/invasivestyle/${projectId}`, {
		headers: {
			authorization: `Bearer ${authStore.token}`,
			// @ts-ignore
			'X-Api-Key': process.env.REACT_APP_API_KEY
		}
	});
	return res;
}
