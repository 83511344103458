import React, { useEffect, useState } from 'react';

import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import './CustomEditor.scss';

import styles from './ProjectEditor.module.scss';
import { Box, Typography, Tabs, Tab, Backdrop, CircularProgress, Button, Tooltip } from '@mui/material';
import { t } from 'i18next';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import projectEditorStore, { AvailableLanguages } from '../../../stores/projectEditorStore';
import { inject, observer } from 'mobx-react';
import { API } from '../../../api';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EmptyTab } from './EmptyTab';
import { ProjectDetailsEditor } from './ProjectDetailsEditor';
import { ProjectVideoEditor } from './ProjectVideoEditor';
import { ProjectContentEditor } from './ProjectContentEditor';
import { ProjectLocationEditor } from './ProjectLocationEditor';
import { ProjectTypeSelector } from './ProjectTypeEditor';
import { ProjectGameAreaEditor } from './ProjectGameAreaEditor';
import ProjectReferencesEdtior from './ProjectReferencesEditor';
import { MissionType } from '../videos/Uploads';
import { ProjectPhotoEditor } from './ProjectPhotoEditor';
import { toJS } from 'mobx';
import { ROUTES } from '../../../parameters/routes';
import { ProjectInvasiveSpeciesEditor } from './ProjectInvasiveSpeciesEditor';
import { ProjectBudgetEdtitor } from './ProjectBudgetEditor';

// TODO: Validation for mission type
const ProjectEditor = () => {
	const { project, setProject, errors, selectedLanguages, saveProject, setSelectedLanguage, selectedLanguage, clearProject } = projectEditorStore;
	const history = useHistory();
	const [isMissionLoading, setIsMissionLoading] = useState<boolean>(false);

	const handleTabChange = (event: React.SyntheticEvent, value: AvailableLanguages) => {
		setSelectedLanguage(value);
	};

	const loadProject = async (projectId: number) => {
		setIsMissionLoading(true);
		console.group('LOADING PROJECT');
		API.loadProject(projectId)
			.then(res => {
				if (!res) return;

				setProject(res.data.project);
				// Use the first language chosen as the default tab
				const languages = Object.keys(res.data.project.name);
				setSelectedLanguage(languages[0] as AvailableLanguages);

				history.push(ROUTES.editProject(projectId));
				console.log('Project loaded', toJS(res.data.project));
			})
			.catch(error => {
				console.error(error);
				toast.error('Error loading project');
			})
			.finally(() => {
				setIsMissionLoading(false);
				console.groupEnd();
			});
	};

	// Loads the initial project if there is one passed in the url
	const loadInitialProject = () => {
		const url = window.location.pathname;
		const projectId = Number(url.substring(url.lastIndexOf('/') + 1, url.length));

		// Check if a projectId was passed in the URL
		if (projectId > 0) {
			loadProject(projectId);
		} else {
			// There was either no paramater, or the URL was 0
			// In cases where there is no projectId passed in the URL or the projectId is set to a 0
			// we create a fresh project. Just make sure the URL is set to 0 and reset the state of the editor.
			history.push(ROUTES.editProject(0));
			clearProject();
		}
	};

	useEffect(() => {
		loadInitialProject();
	}, []);

	const languageTabHasError = (language: AvailableLanguages): boolean => {
		return project.cards.some((card, index) => {
			const error = errors[`cards[${index}].content.${language}`];

			if (error) return true;
			return false;
		});
	};

	const handleCloneProject = async () => {
		setIsMissionLoading(true);
		project.id = 0;
		history.push(ROUTES.editProject(0));

		// Simulate a 2-second loading time
		await new Promise(resolve => setTimeout(resolve, 2000));

		if (project.id !== 0) {
			toast.error('Error cloning project');
		} else {
			toast.success('Project cloned');
			console.log('Project cloned', toJS(project));
		}

		setIsMissionLoading(false);
	};

	const handlesaveProject = async () => {
		setIsMissionLoading(true);
		console.log(toJS(project));
		saveProject()
			.then(projectId => {
				if (projectId) {
					history.push(ROUTES.editProject(projectId));
					toast.success('Project saved');
					console.log('Saving succeeded. ');
					console.log('Reloading project', projectId);
					loadProject(projectId);
				}
			})
			.catch(error => {
				toast.error('Error saving project');
				console.error('Error saving project', error);
			})
			.finally(() => {
				setIsMissionLoading(false);
				console.groupEnd();
			});
	};

	return (
		<SimpleLayout passedStyles={styles.root}>
			<Backdrop open={isMissionLoading} sx={{ color: '#fff', zIndex: 99999 }}>
				<CircularProgress size="48px" color="inherit" />
			</Backdrop>

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					justifySelf: 'center',
					maxWidth: '900px',
					width: '100%',
					gap: '20px'
				}}>
				<ProjectTypeSelector />
				<ProjectLocationEditor />
				<ProjectDetailsEditor />
				<ProjectBudgetEdtitor />
				<ProjectGameAreaEditor />

				{project.projectTypeId == MissionType.video && <ProjectVideoEditor />}
				{project.projectTypeId == MissionType.photo && <ProjectPhotoEditor />}
				{project.projectTypeId == MissionType.invasiveSpecies && <ProjectInvasiveSpeciesEditor />}

				<Box sx={{ width: '100%' }}>
					<Tabs value={selectedLanguage} onChange={handleTabChange}>
						{selectedLanguages.map((language: any) => (
							<Tab
								value={language}
								icon={
									<span>
										{language}
										{languageTabHasError(language) && <ErrorOutlineIcon color="error" fontSize="small" />}
									</span>
								}
							/>
						))}
						<Tab label="+" value="newLanguage" />
					</Tabs>

					{Object.values(AvailableLanguages).includes(selectedLanguage) ? <ProjectContentEditor language={selectedLanguage} /> : <EmptyTab />}

					<ProjectReferencesEdtior />
				</Box>
				<Box sx={{ paddingTop: '40px', display: 'flex', gap: '10px' }}>
					<Button color="primary" variant="contained" onClick={() => handlesaveProject()}>
						{t('projectEditor.saveChanges')}
					</Button>
					<Tooltip title="This will clone the current project, you must still save the changes!">
						<Button color="warning" variant="text" onClick={() => handleCloneProject()}>
							{t('projectEditor.cloneProject')}
						</Button>
					</Tooltip>
				</Box>
			</Box>
		</SimpleLayout>
	);
};

export default inject('projectEditorStore')(observer(ProjectEditor));
