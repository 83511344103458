import { Tooltip, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const InfoTooltip = (props: { title: string }) => {
	const { title } = props;

	return (
		<Tooltip title={title}>
			<IconButton>
				<InfoOutlinedIcon sx={{ fontSize: 'medium' }} />
			</IconButton>
		</Tooltip>
	);
};
