import { useQuery } from 'react-query';
import { API } from '../../api';
import { PaymentsByUserIdParamsI } from '../../models/general';

enum MainQueryKey {
	PaymentsByUserId = 'payments-by-user-id'
}

export const useGetPaymentsByUserId = (params: PaymentsByUserIdParamsI) => {
	const { data } = useQuery([MainQueryKey.PaymentsByUserId, params], () => API.getPaymentsByUserId(params), {
		staleTime: 600000,
		select: data => {
			return data?.data;
		}
	});

	return { data };
};
