import React from 'react'
import { useModal } from '../utils'
import { Modal } from '../components/UI/modal'


type ContextProps = {
	isModalOpen: boolean
	handleModal: (content?: null) => void
}

let ModalContext: any
let { Provider } = (ModalContext = React.createContext<Partial<ContextProps>>({}))

let ModalProvider = ({ children }: { children: React.ReactNode }) => {
	let { isModalOpen, handleModal, modalContent } = useModal()

	return (
		<Provider value={{ isModalOpen, handleModal }}>
			<Modal isActive={isModalOpen} closeModal={() => handleModal()}>
				{modalContent}
			</Modal>
			{children}
		</Provider>
	)
}

export { ModalContext, ModalProvider }
