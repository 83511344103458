import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import AppProvider from './AppProvider';
import Authorization from './components/UX/system/Authorization';
import AppRouter from './components/UX/system/Router';
import { ModalProvider } from './context/modalContext';
import authStore from './stores/authStore';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			notifyOnChangeProps: 'tracked'
		}
		// queries: {
		// 	refetchOnWindowFocus: false
		// }
	}
});

const theme = createTheme({
	palette: {
		primary: {
			main: '#139074'
		},
		secondary: {
			main: '#FFA41D',
			'100': '#F9F8F2'
		},
		error: {
			main: '#FE5F55'
		}
	},
	components: {
		MuiTypography: {
			styleOverrides: {
				root: {
					color: '#1E2926',
					fontFamily: 'Heebo'
				},

				h1: {
					fontWeight: 700,
					fontSize: '36px',
					fontFamily: 'Source Sans Pro'
				},
				h2: {
					fontWeight: 700,
					fontSize: '24px',
					fontFamily: 'Source Sans Pro'
				},
				h3: {
					fontWeight: 600,
					fontSize: '18px',
					fontFamily: 'Source Sans Pro'
				},
				body2: {
					color: '#494F4D',
					lineHeight: '24px'
				},
				caption: {
					fontWeight: 400,
					fontSize: '14px',
					color: '#A4A4A4'
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				sizeLarge: {
					fontSize: '18px',
					borderRadius: '40px',
					padding: '16px 24px'
				},
				sizeMedium: {
					fontSize: '14px',
					borderRadius: '30px',
					padding: '8px 16px'
				},
				root: {
					textTransform: 'none',
					fontFamily: 'Heebo',
					fontWeight: 500,
					boxShadow: 'none'
				}
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					border: 'none',
					color: '#A4A4A4',
					maxHeight: '46px',
					minWidth: '190px'
				},
				notchedOutline: {
					borderRadius: '8px',
					border: '1px solid #A4A4A4'
				}
			}
		}
	}
});

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<ReactQueryDevtools initialIsOpen={false} />
			<AppProvider>
				<Authorization authStore={authStore}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<ThemeProvider theme={theme}>
							<ModalProvider>
								<AppRouter />
							</ModalProvider>
						</ThemeProvider>
					</LocalizationProvider>
				</Authorization>
			</AppProvider>
			<ToastContainer />
		</QueryClientProvider>
	);
}

export default App;
