import { Box, Button, FormControl, OutlinedInput, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CarchupaDialog from '../../../components/UX/dialog/CarchupaDialog';

interface Props {
	handleSubmit: () => void;
	setOpen: (open: boolean) => void;
	open: boolean;
}

export const PaymentUpdateModal = ({ handleSubmit, setOpen, open }: Props) => {
	const { t } = useTranslation();

	return (
		<CarchupaDialog setOpen={setOpen} open={open}>
			<Box sx={{ padding: '24px' }}>
				<Typography variant="h2">{t('paymentUpdate')}</Typography>
				<form onSubmit={handleSubmit}>
					<FormControl sx={{ padding: '24px 0', width: '100%' }}>
						<label>{t('message')}</label>
						<OutlinedInput
							multiline
							rows={5}
							sx={{ maxHeight: '150px', height: '150px', width: '100%' }}
							defaultValue="Your payment request has been processed. Thank you for participating!"
						/>
					</FormControl>
					<Button onClick={() => setOpen(false)} color="primary" variant="contained" size="large">
						{t('sendNotification')}
					</Button>
				</form>
			</Box>
		</CarchupaDialog>
	);
};
