import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import uploadIcon from '../../../assets/upload-icon.svg';
import { Container } from './Dropzone.style';

interface Props {
	file: any;
	setFile: (file: any) => void;
	multiple?: boolean;
	error?: boolean;
	setIsDropzoneEmpty: (val: boolean) => void;
	fileType: string;
}

const Dropzone = (props: Props) => {
	const { t } = useTranslation();
	const { file, setFile, multiple, error, setIsDropzoneEmpty, fileType } = props;

	const onDrop = useCallback(file => {
		const fileWithCaption = { file, caption: '' };
		if (multiple) {
			setFile((prevState: any) => [...prevState, fileWithCaption]);
		} else setFile(file);
		setIsDropzoneEmpty(false);
	}, []);

	const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: multiple, accept: fileType });

	return (
		<Container error={error} {...getRootProps()}>
			<input {...getInputProps()} />
			<img src={uploadIcon} alt={'file'} />
			<p>{t('fileUploadBox')}</p>
			{!multiple && file && file.map((f: File, index: any) => <span key={index}>{f?.name}</span>)}
		</Container>
	);
};

export default Dropzone;
