import React, { useCallback, useEffect } from 'react';
import styles from './UserPayments.module.scss';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import { useRouteMatch } from 'react-router-dom';
import { API } from '../../../api';
import messageStore from '../../../stores/messageStore';
import { useTranslation } from 'react-i18next';
import { PaymentSingleUser } from '../../../models/general';
import settingsStore from '../../../stores/settingsStore';
import moment, { Moment } from 'moment';
import MaterialTable from 'material-table';
import CarchupaPaper from '../../../components/UI/papper/CarchupaPaper';
import { Header } from '../../../models/tables';
import ProjectSelect from '../../../components/UX/project-select/ProjectSelect';
import YearMonthPicker from '../../../components/UX/system/YearMonthPicker';
import { useHistory } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { LinearProgress } from '@material-ui/core';

interface HistoryState {
  startDate: number;
  endDate: number;
  projectId: number;
}

const UserPayments = () => {
  const { selectedRowCount, selectedProject } = settingsStore;
  const [rows, setRows] = React.useState<PaymentSingleUser[]>([]);
  const history = useHistory<HistoryState | null>();
  const datelimit = new Date();
  datelimit.setDate(31);
  const [selectedStartDate, handleStartDateChange] = React.useState(moment(datelimit));
  const [selectedEndDate, handleEndDateChange] = React.useState(moment(datelimit));
  const [loading, setloading] = React.useState(true);
  const match = useRouteMatch<{ userId: string }>();
  const { t } = useTranslation();

  const headCells: Header[] = [
    {
      field: 'pID',
      title: t('ID'),
      sortable: true,
    },
    {
      field: 'username',
      title: t('username'),
      sortable: true,
    },
    {
      field: 'IBAN',
      title: t('IBAN'),
      sortable: false,
    },
    {
      field: 'taxPercentage',
      title: t('Tax'),
      sortable: false,
    },
    {
      field: 'socialSecurityNumber',
      title: t('SSN'),
      sortable: false,
    },
    {
      field: 'totalEarnings.coinCount',
      title: t('Coins'),
      sortable: true,
    },
    {
      field: 'totalEarnings.blueberryCount',
      title: t('Bluberries'),
      sortable: true,
    },
    {
      field: 'totalEarnings.cherryCount',
      title: t('Cherries'),
      sortable: true,
    },
    {
      field: 'totalEarnings.lemonCount',
      title: t('Lemons'),
      sortable: true,
    },
    {
      field: 'totalEarnings.strawberryCount',
      title: t('Strawberries'),
      sortable: true,
    },
    {
      field: 'totalEarnings.diamondCount',
      title: t('Diamonds'),
      sortable: true,
    },
    {
      field: 'pstartDate',
      title: t('Paid Start'),
      render: (rowData: PaymentSingleUser) => <span>{rowData.pstartDate}</span>,
      sortable: true,
    },
    {
      field: 'pendDate',
      title: t('Paid End'),
      render: (rowData: PaymentSingleUser) => <span>{rowData.pendDate}</span>,
      sortable: true,
    },
    {
      field: 'ppaidAt',
      title: t('Paid Time'),
      sortable: true,
    },
    {
      field: 'pTotalValue',
      title: t('paymentsTableTotalValue'),
      sortable: true,
      render: (rowData: PaymentSingleUser) => <span>{Math.round(Number(rowData.pTotalValue) * 100) / 100}</span>,
    },
  ];

  const loadUsersPayments = useCallback(async () => {
    setRows([]);
    setloading(true);
    const response = await API.getSingleUserPayments(
      Number(match.params.userId),
      history.location.state ? history.location.state.startDate : moment(selectedStartDate).startOf('month').unix(),
      history.location.state ? history.location.state.endDate : selectedEndDate.unix(),
      history.location.state ? history.location.state.projectId : selectedProject
    );
    if (typeof response.data !== 'string') {
      setloading(false);
      messageStore.snackbar({
        message: `${'User payments loaded'}`,
        type: 'success',
      });
      setRows(response.data);
    } else {
      setloading(false);
      messageStore.snackbar({
        message: t('No user payments data'),
        type: 'success',
      });
    }
    history.replace({ pathname: history.location.pathname, state: null });
  }, [match.params.userId, t, selectedStartDate, selectedEndDate, selectedProject, history]);

  useEffect(() => {
    loadUsersPayments();
  }, [loadUsersPayments]);

  const selectStartDateCallbackFunction = (date: Moment) => {
    handleStartDateChange(date);
  };

  const selectEndDateCallbackFunction = (date: Moment) => {
    handleEndDateChange(date);
  };

  return (
    <SimpleLayout passedStyles={styles.root}>
      <CarchupaPaper type="row" parentStyle={styles.paper}>
        <ProjectSelect></ProjectSelect>
        <YearMonthPicker parentCallback={selectStartDateCallbackFunction} limit={selectedEndDate.toDate() as Date} />
        <YearMonthPicker parentCallback={selectEndDateCallbackFunction} limit={datelimit} />
      </CarchupaPaper>
      {selectedStartDate && rows.length > 0 ? (
        <MaterialTable
          title={t('User Payments')}
          columns={headCells}
          data={rows}
          options={{
            search: true,
            selection: false,
            padding: 'default',
            pageSize: selectedRowCount,
          }}
          components={{
            Container: (props) => <CarchupaPaper {...props} type="column" parentStyle={styles.paper} childStyle={styles.table} />,
          }}
          onChangeRowsPerPage={(rows) => {
            settingsStore.updateSelectedRowCount(rows);
          }}
        />
      ) : loading ? (
        <LinearProgress />
      ) : (
        <CarchupaPaper type="row" parentStyle={styles.paper}>
          <div>{t('noUserPaymentsText')}</div>
        </CarchupaPaper>
      )}
    </SimpleLayout>
  );
};

export default inject('settingsStore')(observer(UserPayments));
