import { Box, Button, Input, InputLabel, TextField, ToggleButton, Typography } from '@mui/material';
import Pagination from '@mui/lab/Pagination';
import { inject, observer } from 'mobx-react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import SimpleLayout from '../../../components/UI/layout/SimpleLayout';
import ProjectItem from '../../../components/UI/projects-item/ProjectsItem';
import Map from '../../../components/UX/map/Map';
import { ROUTES } from '../../../parameters/routes';
import styles from './Projects.module.scss';
import { DateRangePickerInput } from '../../../components/UX/date-range-picker/DateRangePickerInput';
import { MainQueryKey, QueryKey, useSelectListQuery } from '../../../queries/view-queries/useSelectListQuery';
import SelectList from '../../../components/UX/select-list/SelectList';
import { useGetCountriesQuery } from '../../../queries/data-queries/useGetCountriesQuery';
import { useGetProjectListQuery } from '../../../queries/data-queries/useGetProjectListQuery';
import { PaginationMainQueryKey, usePaginationQuery, usePaginationQueryClient } from '../../../queries/view-queries/usePaginationQuery';
import { DatePickerMainQueryKey, DatePickerQueryKey, useDatePickerQuery } from '../../../queries/view-queries/useDatePickerQuery';
import authStore from '../../../stores/authStore';
import { API } from '../../../api';
import CheckIcon from '@mui/icons-material/Check';

interface ProjectsProps extends RouteComponentProps {}

const Projects = (props: ProjectsProps) => {
	const { adminRole } = authStore;
	const { history } = props;

	const [searched, setSearched] = useState('');

	const [hideFinished, setHideFinished] = useState<boolean>(true);
	// const [page, setPage] = useState(1);
	const { t, i18n } = useTranslation();

	const handleUnixTimestamp = (date: Date | null) => {
		return date ? Math.floor(new Date(date).getTime() / 1000) : undefined;
	};

	/////////////////////////////////////////////// REACT-QUERY ////////////////////////////////////////////////////////
	const { getSelectedOption } = useSelectListQuery(MainQueryKey.country, QueryKey.projects);
	const selectedCountry = getSelectedOption(MainQueryKey.country, QueryKey.projects);

	const { setPaginationData, getPaginationData } = usePaginationQueryClient(1);
	const { paginationPage } = usePaginationQuery(PaginationMainQueryKey.projects, getPaginationData(PaginationMainQueryKey.projects), 1);

	const { dates } = useDatePickerQuery(DatePickerMainQueryKey.dateRangePickerInput, DatePickerQueryKey.projects);

	const { data: projectsListData } = useGetProjectListQuery({
		limit: 2,
		page: paginationPage,
		projectName: searched,
		countryId: selectedCountry,
		startDate: handleUnixTimestamp(dates[0]),
		endDate: handleUnixTimestamp(dates[1]),
		hideFinished,
		language: i18n.language
	});

	const { data: countriesData } = useGetCountriesQuery();
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const handleClick = (projectID: number) => {
		history.push(ROUTES.createProjectsInfoUrl(projectID));
	};

	// PAGINATION
	//
	const [currentPage, setCurrentPage] = useState(1);
	const [projectsPerPage, setProjectsPerPage] = useState(2);

	// Logic for displaying current pages
	const indexOfLastProject = currentPage * projectsPerPage;
	const indexOfFirstProject = indexOfLastProject - projectsPerPage;

	const currentPageProjects = projectsListData?.projects?.slice(indexOfFirstProject, indexOfLastProject);

	useEffect(() => {
		// This might not be the best way to do this but I couldn't see any other way
		// If you toggle show finished in the UI and there is no projects to show on your current page because
		// There is too few returned by the query, the ui will sit on page 3 and show no projects.
		if (currentPageProjects && currentPageProjects.length < 1) setPaginationData(PaginationMainQueryKey.projects, 1);
	}, [currentPageProjects]);

	const renderCurrentPageProjects = currentPageProjects?.map(project => {
		return (
			<ProjectItem
				key={project.id}
				projectBudget={project.budget || 0}
				projectId={project.id}
				projectName={project.name}
				projectDescription={project.description}
				missionProgress={project.missionProgress || 0}
				remainingRewards={project.remainingRewards || 0}
				totalPlayerEarnings={project.totalPlayerEarnings || 0}
				parentStyle={styles.projectItem}
				status={project.status}
				startDate={project.dateStart}
				handleClick={() => handleClick(project.id)}
				showSelectProjectButton={true}>
				<Map projects={project} projectID={project.id} />
			</ProjectItem>
		);
	});

	const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
		// setPage(value);
		setPaginationData(PaginationMainQueryKey.projects, value);
	};

	const handleCreateProject = () => {
		history.push(ROUTES.editProject(0));
	};

	const handleMarkerSync = async () => {
		try {
			await API.syncMarkers();
			alert('Success');
		} catch (err) {
			console.error(err);
			alert('Sync failed');
		}
	};

	const handleCollectableSync = async () => {
		try {
			await API.syncAllCollectables();
			alert('Success');
		} catch (err) {
			console.error(err);
			alert('Sync failed');
		}
	};

	const handlePhotoMarkersSync = async () => {
		try {
			await API.syncAllPhotoMarkers();
			alert('Success');
		} catch (err) {
			console.error(err);
			alert('Sync failed');
		}
	};

	return (
		<SimpleLayout passedStyles={styles.root}>
			<Box className={styles.missionsHeader}>
				<Typography variant="h1">{t('missions')}</Typography>
				{adminRole !== 'contractAdmin' && (
					<Button color="primary" variant="contained" type="submit" onClick={handleCreateProject}>
						{t('createNewMission')}
					</Button>
				)}
				<Button color="primary" variant="contained" type="submit" onClick={handleMarkerSync}>
					{t('syncMarkers')}
				</Button>
				<Button color="primary" variant="contained" type="submit" onClick={handleCollectableSync}>
					{t('syncCollectables')}
				</Button>
				<Button color="primary" variant="contained" type="submit" onClick={handlePhotoMarkersSync}>
					{t('syncPhotoMarkers')}
				</Button>
			</Box>
			<Box className={styles.filterContainer}>
				<Box style={{ marginRight: '24px' }}>
					<InputLabel sx={{ color: '#494F4D' }}>{t('projectName')}</InputLabel>
					<TextField onChange={e => setSearched(e.target.value)} value={searched} />
				</Box>
				<SelectList
					mainQueryKey={MainQueryKey.country}
					queryKey={QueryKey.projects}
					data={countriesData?.countries}
					labelId="country"
					id="country-select"
					htmlFor="country"
					labelText={t('country')}
					sx={{ width: '312px', marginRight: '24px' }}
				/>
				<Box>
					<InputLabel sx={{ color: '#494F4D' }}>{t('dateRange')}</InputLabel>
					<DateRangePickerInput dates={dates} queryKey={DatePickerQueryKey.projects} />
				</Box>
				<Box style={{ marginLeft: '5px' }}>
					<InputLabel sx={{ color: '#494F4D' }}>{t('showEnded')}</InputLabel>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<ToggleButton value="check" color="primary" selected={!hideFinished} onChange={() => setHideFinished(!hideFinished)}>
							<CheckIcon />
						</ToggleButton>
					</div>
				</Box>
			</Box>
			<Box className={styles.renderedItems}>{renderCurrentPageProjects}</Box>
			<Pagination
				className={styles.pagination}
				hideNextButton
				hidePrevButton
				count={Math.ceil((projectsListData?.totalItems || 0) / 2)}
				page={paginationPage}
				onChange={handlePageChange}
				shape="rounded"
				showFirstButton={false}
				showLastButton={false}
			/>
		</SimpleLayout>
	);
};

export default inject()(observer(Projects));
