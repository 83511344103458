import { t } from 'i18next';
import { API } from '../../../api';
import { Upload, User } from '../../../models/general';
import { ProjectsData } from '../../../models/projects';
import { UploadsParams } from '../../../models/video';
import messageStore from '../../../stores/messageStore';
import { UserPaymentsTableDataT } from './types';

export const fetchUsers = async (id: number, setUserDetails: React.Dispatch<React.SetStateAction<User | undefined>>) => {
	const res = await API.getUser(id);

	if (res?.code === 200) {
		const { user } = res.data || {};
		setUserDetails(user);
	} else {
		messageStore.snackbar({
			message: t('Failed fetching user'),
			type: 'error'
		});
	}
};

export const fetchUploads = async (
	uploadsParams: UploadsParams,
	setUploads: React.Dispatch<React.SetStateAction<Upload[] | undefined>>,
	setTotalUploads: React.Dispatch<React.SetStateAction<number>>
) => {
	const res = await API.getUploads(uploadsParams);

	if (res?.code === 200) {
		const { uploads, totalItems } = res.data || [];
		setUploads(uploads?.length === 0 ? undefined : uploads);
		setTotalUploads(totalItems);
	} else {
		messageStore.snackbar({
			message: t('Failed fetching user'),
			type: 'error'
		});
	}
};

export const fetchLastPendingVideo = async (id: number, setLastPendingVideo: React.Dispatch<any>) => {
	const res = await API.getUploads({ userId: id, status: 'pending', limit: 1 });

	if (res.code === 200) {
		const { uploads } = res.data;
		setLastPendingVideo(uploads);
	}
};

export const fetchProjectLis = async (setUploads: React.Dispatch<React.SetStateAction<ProjectsData[] | undefined>>) => {
	const res = await API.getProjectList();

	if (res?.code === 200) {
		const { projects } = res.data || {};
		setUploads(projects);
	} else {
		messageStore.snackbar({
			message: t('Failed fetching user'),
			type: 'error'
		});
	}
};

export const fetchUploadSummary = async (id: number, setUploadSummary: React.Dispatch<React.SetStateAction<any>>) => {
	const response = await API.getUploadSummary(id);

	if (response?.code === 200) {
		const { summary } = response.data || {};
		setUploadSummary(summary);
	} else {
		messageStore.snackbar({
			message: t('Failed fetching upload summary'),
			type: 'error'
		});
	}
};

export interface LastUploadI {
	ID: number;
	createdAt: number;
	projectName: string;
	projectType: string;
	projectTypeID: number;
	url: string;
}

export const fetchLastUploadedVideo = async (id: number, setLastUploadedVideo: React.Dispatch<React.SetStateAction<Upload | undefined>>) => {
	const response = await API.getLastUploaded(id);
	if (response.code === 200) {
		const upload = response.data.upload;
		setLastUploadedVideo(upload);
	} else {
		messageStore.snackbar({
			message: t('Failed fetching last uploaded video summary'),
			type: 'error'
		});
	}
};

export const fetchLastPendingUpload = async (id: number, setLastPendingVideo: React.Dispatch<React.SetStateAction<Upload | undefined>>) => {
	const response = await API.getLastPendingUpload(id);
	if (response.code === 200) {
		const upload = response.data.upload;
		setLastPendingVideo(upload);
	} else {
		messageStore.snackbar({
			message: t('Failed fetching last uploaded video summary'),
			type: 'error'
		});
	}
};

export const formatDateToUnixDate = (dateProp: Date): number => {
	const date = new Date(dateProp);
	const unixTimestamp = Math.floor(date.getTime() / 1000);
	return unixTimestamp;
};

export const fetchPaymentsByUserId = async (
	id: string,
	setPaymentsTableData: React.Dispatch<React.SetStateAction<UserPaymentsTableDataT>>,
	page?: number,
	limit?: string,
	projectId?: number,
	startDate?: number,
	endDate?: number
) => {
	const response = await API.getPaymentsByUserId({ userId: id, page, limit, projectId, startDate, endDate });

	if (response?.code === 200) {
		const { payments } = response.data || {};
		setPaymentsTableData(payments);
	} else {
		messageStore.snackbar({
			message: t('Failed fetching upload summary'),
			type: 'error'
		});
	}
};
