import { Button, TableCell } from '@mui/material';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API } from '../../../api';
import moneyIcon from '../../../assets/money.svg';
import { DataTable } from '../../../components/UI/table/DataTable';
import { HeadCell } from '../../../components/UI/table/DataTableHead';
import { ModalContext } from '../../../context/modalContext';
import { PaymentData, PaymentsParams } from '../../../models/payment';
import paymentsStore from '../../../stores/paymentsStore';
import settingsStore from '../../../stores/settingsStore';
import { UserPaymentsModal } from './UserPaymentsModal';

interface Props {
	dates: any;
	mission: any;
	handleDownloadPaymentCSV: (paymentIds: number[]) => void;
}

export const PaymentsTable = (props: Props) => {
	const { dates, mission } = props;

	const { t } = useTranslation();

	const [totalPayments, setTotalPayments] = useState(5);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [payments, setPayments] = useState<PaymentData[]>([]);

	const handleUnixTimestamp = (date: Date | null) => {
		return date ? Math.floor(new Date(date).getTime() / 1000) : undefined;
	};

	const loadPaymentsList = async () => {
		settingsStore.updateGlobalLoading(true);
		try {
			const paymentsParams: PaymentsParams = {
				limit: rowsPerPage,
				startDate: handleUnixTimestamp(dates[0]),
				endDate: handleUnixTimestamp(dates[1]),
				page,
				projectId: mission === 'all' ? undefined : mission
			};
			const response = await API.getPayments(paymentsParams);
			setTotalPayments(response.data.totalItems);
			setPayments(response.data.payments);
			paymentsStore.updatePayments(response.data.payments);
			settingsStore.updateGlobalLoading(false);
		} catch (err: any) {
			err.data.message ? toast.error(err.data.message) : toast.error('Something went wrong');
		}
		settingsStore.updateGlobalLoading(false);
	};

	const fetchMarkAsPaid = async (paymentIds: number[]) => {
		try {
			const response = await API.patchMarkAsPaid(paymentIds);
			if (response) window.location.reload();
		} catch (err: any) {
			err.data.message ? toast.error(err.data.message) : toast.error('Something went wrong');
		}
		settingsStore.updateGlobalLoading(false);
	};

	useEffect(() => {
		loadPaymentsList();
	}, [rowsPerPage, page, dates, mission]);

	const getPage = (page: number) => {
		setPage(page);
	};

	const getRowsPerPage = (rows: number) => {
		setRowsPerPage(rows);
	};

	const headCells: Array<HeadCell<PaymentData>> = [
		{
			id: 'userId',
			numeric: true,
			disablePadding: true,
			label: t('ID')
		},
		{
			id: 'username',
			numeric: false,
			disablePadding: false,
			label: t('username')
		},
		{
			id: 'firstName',
			numeric: false,
			disablePadding: false,
			label: t('firstName')
		},
		{
			id: 'lastName',
			numeric: false,
			disablePadding: false,
			label: t('lastName')
		},
		{
			id: 'bicSwift',
			numeric: false,
			disablePadding: false,
			label: 'BIC/SWIFT'
		},
		{
			id: 'taxRate',
			numeric: true,
			disablePadding: false,
			label: t('taxRate%')
		},
		{
			id: 'iban',
			numeric: false,
			disablePadding: false,
			label: t('iban')
		},
		{
			id: 'socialSecurityNumber',
			numeric: false,
			disablePadding: false,
			label: `${t('SSN')}`
		},
		{
			id: 'amount',
			numeric: true,
			disablePadding: false,
			label: t('amount')
		},
		{
			id: 'createdAt',
			numeric: true,
			disablePadding: false,
			label: t('createdAt')
		},
		{
			id: 'status',
			numeric: false,
			disablePadding: false,
			label: t('Status')
		},
		{
			id: 'streetAddress',
			numeric: false,
			disablePadding: false,
			label: t('Address')
		},
		{
			id: 'city',
			numeric: false,
			disablePadding: false,
			label: t('city')
		},
		{
			id: 'postcode',
			numeric: false,
			disablePadding: false,
			label: t('postcode')
		}
	];
	return (
		<>
			<DataTable
				showMarkAsPaid={true}
				rows={payments}
				headCells={headCells}
				tableCells={TableCells}
				withToolbar
				withPagination
				totalItems={totalPayments}
				getPage={getPage}
				getRowsPerPage={getRowsPerPage}
				fetchMarkAsPaid={fetchMarkAsPaid}
				handleDownloadPaymentCSV={props.handleDownloadPaymentCSV}
			/>
			{/* <UserPaymentsModal handleSubmit={() => {}} open={open} setOpen={setOpen} /> */}
		</>
	);
};

const TableCells = (row: PaymentData) => {
	const { handleModal } = useContext(ModalContext);

	const dialogStylesFromProp = {
		borderRadius: '20px',
		minHeight: '100px',
		padding: '8px 0',
		minWidth: '713px',
		maxHeight: '700px',
		overflow: 'hidden'
	};

	const toDateTime = (secs: number) => {
		const time = new Date(Date.UTC(1970, 0, 1));
		time.setSeconds(secs);
		return time;
	};

	return (
		<>
			<TableCell align="left">
				<Link style={{ textDecoration: 'none' }} to={`${process.env.PUBLIC_URL}/profile/${row.userId}?prev=payments`}>
					<Button
						sx={{
							textDecoration: 'underline'
						}}
						variant="text"
						size="small">
						{row.username}
					</Button>
				</Link>
			</TableCell>
			<TableCell align="left">{row.firstName}</TableCell>
			<TableCell align="left">{row.lastName}</TableCell>
			<TableCell align="left">{row.bicSwift}</TableCell>
			<TableCell align="left">{row.taxRate}</TableCell>
			<TableCell align="left">{row.iban}</TableCell>
			<TableCell align="left">{row.socialSecurityNumber}</TableCell>
			<TableCell align="left">{row.amount}</TableCell>
			<TableCell align="left">{moment(toDateTime(row.createdAt)).format('LL')}</TableCell>
			<TableCell align="left">{row.status}</TableCell>
			<TableCell align="left">{row.streetAddress}</TableCell>
			<TableCell align="left">{row.city}</TableCell>
			<TableCell align="left">{row.postcode}</TableCell>
			<TableCell align="left">
				{/* <Button variant="text" size="small" startIcon={<img src={moneyIcon} alt="payments" />} onClick={() => push(`/profile/${row.userId}`)}>
					{'viewPayments'}
				</Button> */}

				<Button
					variant="text"
					size="small"
					startIcon={<img src={moneyIcon} alt="payments" />}
					onClick={e => {
						e.stopPropagation();
						handleModal(
							<UserPaymentsModal
								dialogStylesFromProp={dialogStylesFromProp}
								userId={row.userId}
								userName={row.username}
								handleSubmit={() => {}}
								open={true}
								setOpen={handleModal}
							/>
						);
					}}>
					{'viewPayments'}
				</Button>
			</TableCell>
		</>
	);
};
