import { Box, Button } from '@mui/material';
import React from 'react';
import ThumbUp from '../../../assets/thumbs-up.svg';
import WhiteThumbUp from '../../../assets/white-thumb-up.svg';
import ThumbDown from '../../../assets/thumbs-down.svg';
import { ApproveRejectBtnI } from './types';

export const ApproveRejectBtn: React.FC<ApproveRejectBtnI> = ({ kind, status, onClick, imageDetail }): JSX.Element => {
	const icon = kind === 'approve' ? (status === 'approved' && imageDetail ? WhiteThumbUp : ThumbUp) : ThumbDown;
	const alt = kind === 'approve' ? 'Thumbs up' : 'Thumbs down';

	const approvedBackgroundColor = status === 'approved' && kind === 'approve' && imageDetail ? '#39A88F' : 'transparent';

	const textStyle = {
		color: kind === 'approve' ? (status === 'approved' && imageDetail ? 'white' : '#139074') : '#fe5f55',
		paddingLeft: '6px'
	};

	return (
		<Box sx={{ padding: '4px, 16px, 4px, 16px', cursor: 'pointer', backgroundColor: approvedBackgroundColor }}>
			<Button disabled={(status !== 'pending' && status !== 'notUploaded') || (status !== 'pending' && kind === 'approve')} onClick={onClick}>
				<Box component="img" src={icon} alt={alt} />
				<Box component="span" sx={textStyle}>
					{`${kind.charAt(0).toUpperCase()}${kind.slice(1)}`}
				</Box>
			</Button>
		</Box>
	);
};
