import { Button, DialogContent, DialogTitle, InputLabel } from '@mui/material';
import React, { FormEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
	handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
	setOpen(valid: boolean): void;
}

const VideoModalApprove = ({ handleSubmit, setOpen }: Props) => {
	const { t } = useTranslation();

	const dialogContentStyles = {
		display: 'flex',
		flexDirection: 'column',
		paddingTop: '0px'
	};

	const buttonStyles = {
		position: 'relative',
		bottom: '26px',
		left: '26px',
		padding: '8px 20px',
		marginTop: '30px',
		marginBottom: '-10px'
	};

	return (
		<form onSubmit={handleSubmit}>
			<DialogTitle sx={{ fontWeight: 700 }} id="form-dialog-title">
				{t('areYouSure')}
			</DialogTitle>
			<DialogContent sx={dialogContentStyles}>
				<InputLabel>{t('areYouSureApprove')}</InputLabel>
			</DialogContent>
			<Button size="large" type="submit" variant="contained" onClick={() => setOpen(false)} color="primary" sx={buttonStyles}>
				{t('yes')}
			</Button>
		</form>
	);
};

export default VideoModalApprove;
