import React, { useEffect, useState, useMemo } from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { useTranslation } from 'react-i18next';

import blueberry from '../../../assets/blueberry.svg';
import grape from '../../../assets/grape.svg';
import strawberry from '../../../assets/strawberry.svg';
import peach from '../../../assets/peach.svg';
import banana from '../../../assets/banana.svg';
import watermelon from '../../../assets/watermelon.svg';
import blueberryGray from '../../../assets/blueberry-gray.svg';
import grapeGray from '../../../assets/grape-gray.svg';
import strawberryGray from '../../../assets/strawberry-gray.svg';
import peachGray from '../../../assets/peach-gray.svg';
import banananGray from '../../../assets/banana-gray.svg';
import watermelonGray from '../../../assets/watermelon-gray.svg';
import lupine from '../../../assets/lupine.svg';
import giant_hogweed from '../../../assets/giant-hogweed.svg';
import himalayan_balsam from '../../../assets/himalayan-balsam.svg';
import japanese_rose from '../../../assets/japanese-rose.svg';
import lupineGray from '../../../assets/lupine-gray.svg';
import giant_hogweedGray from '../../../assets/giant-hogweed-gray.svg';
import himalayan_balsamGray from '../../../assets/himalayan-balsam-gray.svg';
import japanese_roseGray from '../../../assets/japanese-rose-gray.svg';
import japanese_knotweed from '../../../assets/japanese-knotweed.svg';
import japanese_knotweedGray from '../../../assets/japanese-knotweed-gray.svg';
import brokenSoundBeacon from '../../../assets/broken_sound_beacon.svg';
import workingSoundBeacon from '../../../assets/working_sound_beacon.svg';
import pedestrianPath from '../../../assets/pedestrian_path.svg';
import droppedCurb from '../../../assets/dropped_curb.svg';
import lesser_periwinkle from '../../../assets/lesser_periwinkle.svg';
import buddleia from '../../../assets/buddleia.svg';
import parrots_feather from '../../../assets/parrots_feather.svg';
import goats_rue from '../../../assets/goats_rue.svg';
import cherry_laurel from '../../../assets/cherry_laurel.svg';

import canadaGoldenrod from '../../../assets/canada_goldenrod.svg';
import falseSpiraea from '../../../assets/false_spiraea.svg';
import culvert from '../../../assets/culvert.svg';
import spanishSlug from '../../../assets/spanish_slug.svg';

import { useGetPhotoExportMarkers } from '../../../queries/data-queries/useGetPhotoExportMarkersQuery';
import { useGetProjectCollectables, ProjectCollectablesMainQueryKey } from '../../../queries/data-queries/useGetProjectCollectablesQuery';

import distributionCabinet from '../../../assets/distribution-cabinet.svg';
import drainage from '../../../assets/drainage.svg';
import benchObject from '../../../assets/bench-object.svg';
import manholeCover from '../../../assets/manhole-cover.svg';
import roadblock from '../../../assets/roadblock.svg';
import transformer from '../../../assets/transformer.svg';
import trashcan from '../../../assets/trashcan.svg';

import { toast } from 'react-toastify';
import styles from './Map.module.scss';

function MapContainer(props) {
	const { projects, google, projectID, objectData, isPhoto } = props;
	const [initialCenter, setInitialCenter] = useState({ lat: projects?.lat, lng: projects?.lon });

	const { t } = useTranslation();

	const { projectTypeId } = projects || {};

	/////////////////////////////////////////////// REACT-QUERY ////////////////////////////////////////////////////////
	const { markers } = useGetPhotoExportMarkers(
		{
			projectId: projects?.id,
			photoIDs: [projects?.id],
			offset: 1,
			limit: 20
		},
		projectTypeId
	);

	// const { markers } = useGetPhotoExportMarkers({ projectID: 134, photoIDs: [projectID], offset: 1, limit: 20 }); // for testing

	const { collectedCollectablesList, remainingCollectablesList, fetchError } = useGetProjectCollectables(
		ProjectCollectablesMainQueryKey.projectCollectables,
		projectID,
		projectTypeId
	);

	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	if (fetchError) {
		toast.error(t('Failed fetching videos'));
	}

	const renderActiveMarkers = collectedCollectablesList?.map((marker, index) => {
		return (
			<Marker
				key={index}
				position={{ lat: Number(marker.lat), lng: Number(marker.lon) }}
				icon={{
					url:
						(marker.markerID === 1 && blueberryGray) ||
						(marker.markerID === 2 && grapeGray) ||
						(marker.markerID === 3 && strawberryGray) ||
						(marker.markerID === 4 && peachGray) ||
						(marker.markerID === 5 && banananGray) ||
						(marker.markerID === 6 && watermelonGray) ||
						(marker.markerID === 7 && lupineGray) ||
						(marker.markerID === 8 && himalayan_balsamGray) ||
						(marker.markerID === 9 && giant_hogweedGray) ||
						(marker.markerID === 10 && japanese_roseGray) ||
						(marker.markerID === 11 && japanese_knotweedGray) ||
						(marker.markerID === 12 && canadaGoldenrod) ||
						(marker.markerID === 13 && falseSpiraea) ||
						(marker.markerID === 14 && culvert) ||
						(marker.markerID === 15 && spanishSlug) ||
						(marker.markerID === 16 && trashcan) ||
						(marker.markerID === 29 && lesser_periwinkle) ||
						(marker.markerID === 30 && buddleia) ||
						(marker.markerID === 31 && parrots_feather) ||
						(marker.markerID === 32 && goats_rue) ||
						(marker.markerID === 33 && cherry_laurel),
					scaledSize: new google.maps.Size(20, 20)
				}}
			/>
		);
	});

	const renderCollectedMarkers = remainingCollectablesList?.map((marker, index) => {
		return (
			<Marker
				key={index}
				position={{ lat: Number(marker.lat), lng: Number(marker.lon) }}
				icon={{
					url:
						(marker.markerId === 1 && blueberry) ||
						(marker.markerId === 2 && grape) ||
						(marker.markerId === 3 && strawberry) ||
						(marker.markerId === 4 && peach) ||
						(marker.markerId === 5 && banana) ||
						(marker.markerId === 6 && watermelon) ||
						(marker.markerId === 7 && lupine) ||
						(marker.markerId === 8 && himalayan_balsam) ||
						(marker.markerId === 9 && giant_hogweed) ||
						(marker.markerId === 10 && japanese_rose) ||
						(marker.markerId === 11 && japanese_knotweed) ||
						(marker.markerId === 12 && canadaGoldenrod) ||
						(marker.markerId === 13 && falseSpiraea) ||
						(marker.markerId === 14 && culvert) ||
						(marker.markerId === 15 && spanishSlug) ||
						(marker.markerId === 16 && trashcan) ||
						(marker.markerID === 29 && lesser_periwinkle) ||
						(marker.markerID === 30 && buddleia) ||
						(marker.markerID === 31 && parrots_feather) ||
						(marker.markerID === 32 && goats_rue) ||
						(marker.markerID === 33 && cherry_laurel),

					scaledSize: new google.maps.Size(20, 20)
				}} // Warning: on activeCollectable is marker.markerId, while on collectedCollectables is marker.markerID
			/>
		);
	});
	const renderObjectMarkers = markers?.map((marker, index) => {
		return (
			<Marker
				key={index}
				position={{ lat: Number(marker.latitude), lng: Number(marker.longitude) }}
				icon={{
					url:
						(marker.type === 1 && manholeCover) ||
						(marker.type === 2 && benchObject) ||
						(marker.type === 3 && distributionCabinet) ||
						(marker.type === 4 && roadblock) ||
						(marker.type === 5 && trashcan) ||
						(marker.type === 6 && drainage) ||
						(marker.type === 7 && transformer) ||
						(marker.type === 21 && brokenSoundBeacon) ||
						(marker.type === 22 && workingSoundBeacon) ||
						(marker.type === 23 && pedestrianPath) ||
						(marker.type === 27 && culvert) ||
						(marker.type === 28 && culvert) ||
						'',
					scaledSize: new google.maps.Size(40, 40)
				}}
			/>
		);
	});

	useEffect(() => {
		if (!!markers?.length) {
			setInitialCenter({
				lat: !!markers?.length ? markers[0].latitude : !!markers?.length ? markers[0].latitude : '',
				lng: !!markers?.length ? markers[0].longitude : !!markers?.length ? markers[0].longitude : ''
			});
		} else if (collectedCollectablesList?.length > 0 || remainingCollectablesList?.length > 0) {
			setInitialCenter({
				lat: !!remainingCollectablesList?.length
					? remainingCollectablesList[0].lat
					: !!collectedCollectablesList?.length
					? collectedCollectablesList[0].lat
					: '',
				lng: !!remainingCollectablesList?.length
					? remainingCollectablesList[0].lon
					: !!collectedCollectablesList?.length
					? collectedCollectablesList[0].lon
					: ''
			});
		}
	}, [remainingCollectablesList, collectedCollectablesList]);

	function MapComponent() {
		return (
			<Map google={google} style={{ borderRadius: '20px 20px 0 0' }} zoom={14} initialCenter={initialCenter}>
				{renderActiveMarkers}
				{renderCollectedMarkers}
				{renderObjectMarkers}

				{objectData ? (
					<Marker
						position={{ lat: objectData.lat, lng: objectData.lon }}
						icon={{
							url:
								(objectData.objectID === 1 && manholeCover) ||
								(objectData.objectID === 2 && benchObject) ||
								(objectData.objectID === 3 && distributionCabinet) ||
								(objectData.objectID === 4 && roadblock) ||
								(objectData.objectID === 5 && trashcan) ||
								(objectData.objectID === 6 && drainage) ||
								(objectData.objectID === 7 && transformer) ||
								(objectData.objectID === 21 && brokenSoundBeacon) ||
								(objectData.objectID === 22 && workingSoundBeacon) ||
								(objectData.objectID === 23 && pedestrianPath) ||
								(objectData.objectID === 24 && droppedCurb) ||
								(objectData.objectID === 26 && culvert) ||
								(objectData.objectID === 27 && spanishSlug) ||
								'',
							scaledSize: new google.maps.Size(40, 40)
						}}
					/>
				) : null}
			</Map>
		);
	}

	const GoogleMap = useMemo(() => {
		return <MapComponent />;
	}, [initialCenter, markers, remainingCollectablesList, collectedCollectablesList]);

	return (
		<div className={isPhoto ? styles.containerPhoto : styles.container}>
			<div>{GoogleMap}</div>
		</div>
	);
}

export default GoogleApiWrapper({
	apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
})(MapContainer);
