import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { t } from 'i18next';
import { Transaction, TransactionEvent } from '../../../../../legacy/src/types/transactions';
import { API } from '../../../api';
import { Table, TableBody, TableCell, TableHead, TableRow, Collapse, Button, Box, Typography, CircularProgress, LinearProgress } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';

interface UserTransactions extends Transaction {
	transactionEvents?: TransactionEvent[];
}

export const UserTransactionsDialog = (props: { userId: number; isOpen: boolean; onClose: () => void }) => {
	const { userId, isOpen, onClose } = props;
	const [transactions, setTransactions] = useState<UserTransactions[]>([]);
	const [isTransactionsLoading, setIsTransactionsLoading] = useState(true);
	const [isTransactionEventsLoading, setIsTransactionEventsLoading] = useState(false);
	const [expandedId, setExpandedId] = useState<number | null>(null);

	const history = useHistory();

	const fetchTransactions = async () => {
		API.getUserTransactions(userId)
			.then(res => {
				// @ts-ignore
				setTransactions(res.data.transactions.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()));
			})
			.catch(err => {
				console.error(err);
			})
			.finally(() => {
				setIsTransactionsLoading(false);
			});
	};

	const fetchTransactionEvents = async (transactionId: number) => {
		setIsTransactionEventsLoading(true);
		API.getTransactionEvents(transactionId, userId)

			.then(res => {
				const transaction = transactions.find(t => t.id === transactionId);

				if (transaction) {
					transaction.transactionEvents = res.data.transactionEvents;
					setTransactions([...transactions]);
				}
			})
			.catch(err => {
				console.error(err);
			})
			.finally(() => {
				setIsTransactionEventsLoading(false);
			});
	};

	useEffect(() => {
		fetchTransactions();
	}, []);

	const onRowClick = (transactionId: number) => {
		setExpandedId(transactionId);
		fetchTransactionEvents(transactionId);
	};

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			maxWidth="lg"
			fullWidth
			PaperProps={{
				style: { height: '80vh', maxWidth: '80vw' } // Sets max height of the dialog
			}}>
			<DialogTitle>{t('profile.transactionDialog.title')}</DialogTitle>

			<DialogContent>
				{isTransactionsLoading && <CircularProgress />}
				{!isTransactionsLoading && (
					<div>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell sx={{ width: '10%' }}>{t('profile.transactionDialog.transactionId')}</TableCell>
									<TableCell>{t('profile.transactionDialog.sessionUploadId')}</TableCell>
									<TableCell>{t('profile.transactionDialog.createdAt')}</TableCell>
									<TableCell>{t('profile.transactionDialog.status')}</TableCell>
									<TableCell>{t('profile.transactionDialog.amount')}</TableCell>
									<TableCell>{t('profile.transactionDialog.transactionType')}</TableCell>
									<TableCell sx={{ width: '10%' }}>{t('profile.transactionDialog.updatedAt')}</TableCell>
									<TableCell>{t('profile.transactionDialog.paymentId')}</TableCell>
									<TableCell>{t('profile.transactionDialog.updatedBy')}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{transactions.map(transaction => (
									<>
										<TableRow
											key={transaction.id}
											onClick={() => onRowClick(transaction.id)}
											sx={{
												cursor: 'pointer',
												'&:hover': {
													backgroundColor: '#f5f5f5' // Neutral grey for hover
												}
											}}>
											<TableCell>{transaction.id}</TableCell>
											{/* @ts-ignore */}
											<TableCell>{transaction.session_upload_id}</TableCell>
											<TableCell>{new Date(transaction.created_at).toLocaleDateString()}</TableCell>
											<TableCell>{transaction.t_status}</TableCell>
											<TableCell>{transaction.amount}</TableCell>
											<TableCell>{transaction.t_type}</TableCell>
											<TableCell>{new Date(transaction.updated_at).toLocaleDateString()}</TableCell>
											<TableCell>{transaction.payment_id}</TableCell>
											<TableCell>
												<Button
													sx={{
														textDecoration: 'underline'
													}}
													onClick={e => {
														history.push(`/profile/${transaction.modifier_id}`);
														onClose();
													}}
													variant="text"
													size="small">
													{transaction.modifier_id}
												</Button>
											</TableCell>
										</TableRow>
										<TableRow style={{ display: expandedId == transaction.id ? 'table-row' : 'none' }}>
											<TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: '50px' }} colSpan={6}>
												<Collapse in={transaction.transactionEvents && expandedId === transaction.id} timeout="auto" unmountOnExit>
													<Box sx={{ paddingBottom: '10px' }}>
														<Table
															sx={{
																'& .MuiTableRow-root:last-child .MuiTableCell-root': {
																	borderBottom: 'none'
																}
															}}>
															<TableHead>
																<TableRow>
																	<TableCell sx={{ width: '10%' }}>{t('profile.transactionDialog.eventId')}</TableCell>
																	<TableCell sx={{ width: '10%' }}>{t('profile.transactionDialog.createdAt')}</TableCell>
																	<TableCell sx={{ width: '30%' }}>{t('profile.transactionDialog.info')}</TableCell>
																	<TableCell sx={{ width: '10%' }}>{t('profile.transactionDialog.oldAmount')}</TableCell>
																	<TableCell sx={{ width: '10%' }}>{t('profile.transactionDialog.newAmount')}</TableCell>
																	<TableCell sx={{ width: '10%' }}>{t('profile.transactionDialog.performedBy')}</TableCell>
																	<TableCell>{t('profile.transactionDialog.transactionEventType')}</TableCell>
																</TableRow>
															</TableHead>
															{transaction.transactionEvents?.map(event => (
																<TableRow
																	sx={{
																		'&:hover': {
																			backgroundColor: '#f5f5f5' // Neutral grey for hover
																		}
																	}}>
																	<TableCell>{event.id}</TableCell>
																	<TableCell>{new Date(event.created_at).toLocaleDateString()}</TableCell>
																	<TableCell>{event.info}</TableCell>
																	<TableCell>{event.old_amount ? event.old_amount : 0}</TableCell>
																	<TableCell>{event.new_amount ? event.new_amount : 0}</TableCell>
																	<TableCell>
																		<Button
																			sx={{
																				textDecoration: 'underline'
																			}}
																			onClick={e => {
																				history.push(`/profile/${event.performed_by}`);
																				onClose();
																			}}
																			variant="text"
																			size="small">
																			{event.performed_by}
																		</Button>
																	</TableCell>
																	<TableCell>{event.te_type}</TableCell>
																</TableRow>
															))}
														</Table>
													</Box>
												</Collapse>
											</TableCell>
										</TableRow>
									</>
								))}
							</TableBody>
						</Table>
					</div>
				)}
			</DialogContent>
		</Dialog>
	);
};
