import { useQuery } from 'react-query';
import { API } from '../../api';

export enum ProjectCollectablesMainQueryKey {
	projectCollectables = 'project-collectables'
}

export const useGetProjectCollectables = (MainQueryKey: ProjectCollectablesMainQueryKey, projectId: number, projectTypeId: number) => {
	const { data, isLoading, isError } = useQuery([MainQueryKey, projectId], () => API.getProjectCollectables(projectId), {
		enabled: (projectTypeId === 1 || projectTypeId === 3) ? true : false,
		staleTime: 600000,
		select: data => {
			return data?.data;
		}
	});

	return {
		availableCollectablesList: data?.availableCollectablesList,
		collectedCollectablesList: data?.collectedCollectablesList,
		remainingCollectablesList: data?.remainingCollectablesList,
		isLoading,
		fetchError: isError
	};
};
